
import React, {Fragment, useEffect, useRef, useState} from "react";
import * as ReactDOM from 'react-dom';
import {Badge, Card, CardBody, Col, FormGroup, Input, Label} from "reactstrap";
import Select from "react-select";
import JqxTable from "../../../share-components/table/JqxTable";
import {toast} from "react-toastify";
import * as ReactDOMServer from "react-dom/server";

function ShowOrderProductJqx(props) {

    const {setValue, trigger, getValues, orderedProducts} = props;
    let gridRef = useRef(null);

    const datafields = [
        {name: 'variant_id', type: 'number'},
        {name: 'product_id', type: 'number'},
        {name: 'product_name', type: 'string'},
        {name: 'image', type: 'string'},
        {name: 'sku', type: 'string'},
        {name: 'variant_name', type: 'string'},
        {name: 'quantity', type: 'number'},
        {name: 'price', type: 'number'},
        {name: 'total_price', type: 'number'},
        {name: 'stock', type: 'number'},
        {name: 'supplier_sku', type: 'string'},
        {name: 'tax', type: 'number'},
        {name: 'cost', type: 'number'},
        {name: 'total', type: 'number'},
    ];

    const columns = [
        {datafield: 'variant_id', hidden: true},
        {datafield: 'stock', hidden: true},
        {
            text: 'Product',
            datafield: 'product_name',
            editable: false,
            columntype: 'text',
            width: '25%',
            cellsrenderer(row, columnfield, value, defaulthtml, columnproperties) {
                const productDetails = getValues('ordered_products')[row];
                const html = ReactDOMServer.renderToString(
                    <div style={{textAlign: 'center', padding: 5}}>
                        {productDetails && <div className="row">
                            <div className="col-md-3">
                                <img className="img-fluid" src={productDetails.image+'&width=50'} width="40" alt=""/>
                            </div>
                            <div className="col-md-9">
                                <div className="row">
                                    <p className={'font-primary'}>{value}</p>
                                </div>
                                <div className="row">
                                    <p>{productDetails.variant_name}</p>
                                </div>
                                <div className="row">
                                    <p>{productDetails.sku}</p>
                                </div>

                            </div>

                        </div>}
                    </div>
                );
                return html;
            },

        },
        {
            text: 'SKU',
            datafield: 'supplier_sku',
            editable: false,
            columntype: 'text',
            width: '14%',
            cellsalign: 'center',
            align: 'center',
        },
        {
            text: 'Quantity',
            datafield: 'quantity',
            editable: false,
            columntype: 'text',
            width: '14%',
            cellsalign: 'center',
            align: 'center',
            validation: function (cell, value) {
                const stock = gridRef.getcellvalue(cell.row, 'stock');
                if (Number(value) <= 0) {
                    toast.error("Quantity must be greater than zero.", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    return {result: false, message: "Quantity must be greater than zero."}
                } else if (Number(stock) < Number(value)) {
                    toast.error("Only "+stock + " is available", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    return {result: false, message: "Only "+stock + " is available"}
                } else {
                    return true;
                }
            },
        },
        {
            text: 'Cost',
            datafield: 'cost',
            editable: false,
            columntype: 'text',
            width: '14%',
            cellsalign: 'center',
            align: 'center',
            cellsformat: 'c2',
            validation: (cell, value) => {
                if (value === '' || value === null) {
                    return true;
                }
        
                const taxValue = parseFloat(value);
                if (isNaN(taxValue) || taxValue <= 0) {
                    toast.error("Cost cannot be a negative number.", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    return { result: false, message: "Cost cannot be a negative number." };
                }
                return true;
            },
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties) => {
                if (value === undefined || value === null || value === '') {
                    return `<div style="color: grey; display: flex; justify-content: center; align-items: center; height: 100%;">£0.00</div>`;
                }
                return `<div style="color: black; display: flex; justify-content: center; align-items: center; height: 100%;">£${parseFloat(value).toFixed(2)}</div>`;
            },
           
        },
        {
            text: 'Tax',
            datafield: 'tax',
            editable: false,
            columntype: 'text',
            width: '14%',
            cellsalign: 'center',
            align: 'center',
            cellsformat: 'p',
            validation: (cell, value) => {
                if (value === '' || value === null) {
                    return true;
                }
        
                const taxValue = parseFloat(value);
                if (isNaN(taxValue) || taxValue <= 0 || taxValue > 100) {
                    toast.error("Tax must be a valid percentage between 0 and 100.", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    return { result: false, message: "Tax must be a valid percentage between 0 and 100." };
                }
                return true;
            },
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties) => {
                if (value === undefined || value === null || value === '') {
                    return `<div style="color: grey; display: flex; justify-content: center; align-items: center; height: 100%;">0.00%</div>`;
                }
                return `<div style="color: black; display: flex; justify-content: center; align-items: center; height: 100%;">${parseFloat(value)}%</div>`;
            },
        },
        {
            text: 'Total',
            datafield: 'total',
            editable: false,
            columntype: 'text',
            width: '14%',
            cellsalign: 'center',
            align: 'center',
            cellsformat: 'c2',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties) => {
                if (value === undefined || value === null || value === '') {
                    return `<div style="color: grey; display: flex; justify-content: center; align-items: center; height: 100%;">£0.00</div>`;
                }
                return `<div style="color: black; display: flex; justify-content: center; align-items: center; height: 100%;">£${parseFloat(value).toFixed(2)}</div>`;
            },
        },
      
    ];

    const getJqxRef = jqx => {
        if (jqx) {
            gridRef = jqx;

            setTimeout(
                () => {
                    if (gridRef) {
                        const myEle = document.getElementById(gridRef._id);
                        if (myEle) {
                            gridRef.updatebounddata();
                        }
                    }

                    // myGrid.updatebounddata();
                },
                1000
            );
        }
    };

    const onDelete = (data) => {
        setDataToHookForm();
    }

    const cellEdit = event => {
        const rowArgs = event.args;
        const singleQty = gridRef.getcellvalue(rowArgs.rowindex,'quantity');
        const singlePrice = gridRef.getcellvalue(rowArgs.rowindex,'cost');
       
        const updatedQty = parseFloat(rowArgs.value) || 0;


        if(rowArgs.datafield == "quantity"){
            // const amount = parseFloat(rowArgs.value) * parseFloat(singlePrice).toFixed(2);
            // gridRef.setcellvalue(rowArgs.rowindex, "total", amount);

            const singlePrice = parseFloat(gridRef.getcellvalue(rowArgs.rowindex, 'cost')) || 0;
            const taxValue = parseFloat(gridRef.getcellvalue(rowArgs.rowindex, 'tax')) || 0;
        
            const updatedCost = singlePrice * (1 + taxValue / 100);
            const total = (updatedQty * updatedCost).toFixed(2);
        
            gridRef.setcellvalue(rowArgs.rowindex, "total", total);
        }

        if (rowArgs.datafield == "cost") {
            // gridRef.setcellvalue(rowArgs.rowindex, "total", (rowArgs.value * singleQty).toFixed(2));

            const singleQty = parseFloat(gridRef.getcellvalue(rowArgs.rowindex, 'quantity')) || 0;
            const taxValue = parseFloat(gridRef.getcellvalue(rowArgs.rowindex, 'tax')) || 0;
        
            const updatedCost = singleQty * (1 + taxValue / 100);
            const total = (updatedQty * updatedCost).toFixed(2);
        
            gridRef.setcellvalue(rowArgs.rowindex, "total", total);
        }

        if (rowArgs.datafield == "tax") {
            const taxCal = (singleQty * singlePrice * (1 + rowArgs.value/ 100)).toFixed(2);
            gridRef.setcellvalue(rowArgs.rowindex, "total", taxCal);
        }

        gridRef.setcellvalue(rowArgs.rowindex, rowArgs.datafield, rowArgs.value);
        setDataToHookForm();
    };
    
    let rowData = {};

    

    const setDataToHookForm = () => {
        let orderData = [];

        const gridInformation = gridRef.getdatainformation();

        for (let i = 0; i < gridInformation.rowscount; i++) {
            const rowData = gridRef.getrowdata(i);
            orderData.push(rowData);
        }

        setValue('ordered_products', orderData);
        trigger('ordered_products');
    }


  return (
    <Fragment>

            <div style={{width: '100%'}}>
                {getValues('ordered_products').length > 0 && <JqxTable
                    rowsheight={75}
                    datafields={datafields}
                    columns={columns}
                    data={getValues('ordered_products')}
                    myGrid={gridRef}
                    getJqxRef={getJqxRef}
                    isDelete
                    onCellendedit={cellEdit}
                    disableCreateRow
                    // onDelete={onDelete}
                    // isFirstDelete={true}
                />}
            </div>
        </Fragment>

  )
}

export default ShowOrderProductJqx