import React, { Fragment, useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import { Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row, Button } from "reactstrap";
import { toast } from "react-toastify";
import SweetAlert from "sweetalert2";

import {
    mapOrderReturnResponseToTable
} from "../../../../utils/mapper";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "../../../../store/withReducer";

import {
    addReturnItems,
    getOrderSettings,
    setRefundsData,
    setSettings, showReturnList
} from "../../store/orderSlice";
import {useHistory, useParams, Link} from "react-router-dom";

import orderReducer from "../../store";
import ButtonLoader from "../../../../share-components/ui/buttonLoader";
import PreLoader from "../../../../share-components/ui/preLoader";
import ItemsTable from "./ItemsTable";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
    formatter, formatterDecimal, formatterDecimalValue
} from "../../../../utils/common";
import { RefundedItem } from "../../view-order/section/refundedItem";

const ReturnOrder = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    let history = useHistory();

    const currentUser = useSelector(({ authReducer }) => authReducer.data.user);
    const isDirectPermission = currentUser.role.name !== "Seller" && (currentUser.permission).includes('Direct Refund');
    const [loading, isSetLoading] = useState(true);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [orderItems, setOrderItems] = useState([]);

    const [packList, setPackList] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const orderState = useSelector(({ order }) => order.order);
    useEffect(() => {
        dispatch(getOrderSettings()).then(res => {
            if (!res.error) {
                const results = {
                    settings: res.payload,
                    id: null
                }
                dispatch(setSettings(res.payload));
            }
        });
    }, [dispatch]);

    const dataMenu = [
        {
            type: 1,
            text: 'Orders',
            link: '/order'
        },
        {
            type: 0,
            text: "Return Order"
        }
    ];

    useEffect(() => {
        dispatch(showReturnList(id)).then(res => {
            if (!res.error) {
                dispatch(setRefundsData(res.payload));

                const items = mapOrderReturnResponseToTable(res.payload.list);
                setOrderItems(items);

                isSetLoading(false);

                if (isDirectPermission && res.payload.list.length == 0) {
                    setIsDirectRefund(true);
                }
            }
        });
    }, [dispatch]);

    const [isDirectRefund, setIsDirectRefund] = useState(false);
    const [refundAmountDisabled, setRefundAmountDisabled] = useState(false);
    const [shippingRate, setShippingRate] = useState('');
    const [totalRefund, setTotalRefund] = useState(0);
    const [suggestedTotalRefund, setSuggestedTotalRefund] = useState(0);

    const [refundData, setRefundData] = useState({
        currency: 'GBP',
        balance_shipping_rate: 0,
        balance_shipping_rate_format: "0.00",
        balance_refund: 0,
        balance_refund_format: "0.00"
    });

    useEffect(() => {
        if (orderState.refund_balance_details) {
            setRefundData(orderState.refund_balance_details);
        }

    }, [orderState.refund_balance_details]);

    /**
     * Form Validation Schema
     */
    const schema = yup.object().shape({
        shipping_refund_amount: yup.number()
            .transform((value, originalValue) =>
                String(originalValue).trim() === "" ? null : value
            )
            .max(refundData.balance_shipping_rate, 'The amount must be ' + refundData.balance_shipping_rate_format + ' or less')
            .nullable(),
        total_refund_amount: yup.number()
            .transform((value, originalValue) =>
                String(originalValue).trim() === "" ? null : value
            )
            .max(refundData.balance_refund, 'The amount must be ' + refundData.balance_refund_format + ' or less')
            .min(orderItems.length === 0 && isDirectRefund === true && refundData.balance_shipping_rate === 0 ? 0.01 : 0,
                orderItems.length === 0 && isDirectRefund === true ? "The amount must be greater than 0" : "The amount must be at least 0"
            )
            .nullable()
            .required("Refund amount is a required field")
    });

    const defaultValues = {
        shipping_refund_amount: null,
        total_refund_amount: null,
        reason: null,
        is_notify: false,
        is_direct_refund: false
    };

    const { handleSubmit, formState, reset, control, register, setValue, trigger, getValues } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const { errors, isDirty } = formState;

    const [errorShipRate, setErrorShipRate] = useState('');

    const shippingHandleChange = (event) => {
        // Get the value from the input
        let newValue = parseFloat(event.target.value);

        // Round the value to two decimal places
        if (!isNaN(newValue)) {
            newValue = parseFloat(newValue.toFixed(2));
        } else {
            newValue = '';
        }

        setShippingRate(newValue);
        setValue('shipping_refund_amount', newValue);
        trigger('shipping_refund_amount');

        let mgs = '';
        if (newValue > 0 && newValue > refundData.balance_shipping_rate) {
            mgs = `The amount must be ${refundData.balance_shipping_rate_format} or less`;
        }

        setErrorShipRate(mgs);
    };

    const shippingHandleBlur = (event) => {
        // Get the value from the input
        let newValue = parseFloat(event.target.value);

        // Round the value to two decimal places
        if (!isNaN(newValue)) {
            newValue = formatterDecimalValue(newValue);
        } else {
            newValue = '';
        }

        setShippingRate(newValue);
        setValue('shipping_refund_amount', newValue);
        trigger('shipping_refund_amount');
    };

    const refundHandleChange = (event) => {
        // Get the value from the input
        let newValue = parseFloat(event.target.value);

        // Round the value to two decimal places
        if (!isNaN(newValue)) {
            newValue = parseFloat(newValue.toFixed(2));
        } else {
            newValue = '';
        }

        setTotalRefund(newValue);
        setValue('total_refund_amount', newValue);
        trigger('total_refund_amount');
    };

    const refundHandleBlur = (event) => {
        // Get the value from the input
        let newValue = parseFloat(event.target.value);

        // Round the value to two decimal places
        if (!isNaN(newValue)) {
            // newValue = formatterDecimalValue(newValue);
            newValue = parseFloat(newValue.toFixed(2));
        } else {
            newValue = '';
        }

        setTotalRefund(newValue);
        setValue('total_refund_amount', newValue);
        trigger('total_refund_amount');
    };

    const calcTotalRefundAmount = () => {
        let total = 0;
        let countSelectedItem = 0;
        orderItems.forEach((item, i) => {
            if (item.selected === true) {
                countSelectedItem += 1;
                total += Number(item.returnTotal);
            }
        });

        total += Number(shippingRate);

        setTotalRefund(total);
        setSuggestedTotalRefund(total);
        setRefundAmountDisabled(countSelectedItem > 0);
    }

    useEffect(() => {
        calcTotalRefundAmount();
    }, [orderItems, shippingRate]);

    useEffect(() => {
        if (!isDirectRefund) {
            const updatedItems = orderItems.map(item => ({ ...item, selected: false, quantity: 0, returnTotal: 0, returnTotalFormat: formatterDecimal.format(0), reason: '', reason_id: null, isRestock: false }));
            setOrderItems(updatedItems);
        }
    }, [isDirectRefund]);

    const submitData = () => {
        let isError = false;
        let itemErrors = 0;
        let returnItems = [];
        let errorMessage = '';

        if (isDirectRefund) {
            if (shippingRate > 0 && shippingRate > refundData.balance_shipping_rate) {
                errorMessage = `The amount must be ${refundData.balance_shipping_rate_format} or less`;
                isError = true;
            }
        }

        if (isError) {
            setErrorShipRate(errorMessage);
            toast.error(errorMessage, {
                position: toast.POSITION.TOP_RIGHT,
            });

            return;
        }

        orderItems.forEach((item, i) => {
            if (item.selected === true) {
                if (item.reason_id === undefined || item.reason_id === null || (parseFloat(item.quantity) === 0 && !item.isPack)) {
                    itemErrors++;
                }

                returnItems.push({
                    line_item_id: item.id,
                    quantity: item.quantity,
                    return_total: item.returnTotal,
                    restock: item.isRestock ? item.isRestock : false,
                    reason_id: item.reason_id
                });
            }
        });

        // Validation
        if (!isDirectRefund && returnItems.length == 0) {
            toast.error("Please select at least one item", {
                position: toast.POSITION.TOP_RIGHT,
            });

            return;

        } else if (isDirectRefund && totalRefund <= 0) {
            toast.error("The refund amount must be greater than 0", {
                position: toast.POSITION.TOP_RIGHT,
            });

            return;

        } else if (itemErrors > 0) {
            toast.error("Invalid submission, please check all required fields", {
                position: toast.POSITION.TOP_RIGHT,
            });

            return;

        }


        returnOrRefund(returnItems);
    }

    const returnOrRefund = (items) => {
        SweetAlert.fire({
            title: 'Are you sure want to return this product?',
            text: "Once confirmed, the return request will be sent to the system",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Proceed',
            cancelButtonText: 'No, cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                setButtonDisabled(true);
                let data = {
                    'order_id': id,
                    'is_direct_refund': isDirectRefund,
                    'shipping_refund_amount': shippingRate,
                    'total_refund_amount': totalRefund,
                    'is_notify': getValues('is_notify'),
                    'reason': getValues('reason'),
                    'items': items
                };
                dispatch(addReturnItems(data)).then(res => {
                    if (!res.error) {
                        setButtonDisabled(false);
                        history.push(`/order/${id}/view`);
                    }
                });
            }
        });
    }


    return (
        <Fragment>
            {loading ? <>
                <PreLoader></PreLoader>
            </> : <>
                <Breadcrumb title="Select Order Items for Returns" data={dataMenu} />
                <Container fluid={true}>
                    {(orderItems.length > 0 || (isDirectPermission && (refundData.balance_shipping_rate > 0 || refundData.balance_refund > 0))) ? <>
                        <Card>
                            <CardBody>
                                {orderItems.length > 0 && <div className="mb-4">

                                    {isDirectPermission && <>
                                        <div className="checkbox checkbox-dark mb-3">
                                            <input id="is_direct_refund" type="checkbox"
                                                onChange={(e) => {
                                                    setIsDirectRefund(e.target.checked);
                                                    // trigger('is_direct_refund');
                                                }}
                                                defaultChecked={isDirectRefund} />
                                            <Label for="is_direct_refund"><strong>Direct Refund</strong></Label>
                                        </div>
                                    </>}

                                    <h5 className="mb-2">Items from Order {orderState.data ? orderState.data.order_name : ""}</h5>
                                    <ItemsTable
                                        orderItems={orderItems}
                                        orderState={orderState}
                                        isDirectRefund={isDirectRefund}
                                        setOrderItems={setOrderItems}
                                    />
                                </div>}

                                {isDirectRefund && <>
                                    <Row>
                                        <Col md={4}>
                                            {refundData.balance_shipping_rate > 0 && <>
                                                <h6 className="mb-2">Refund shipping</h6>
                                                <p>Shipping rate: Standard (£{refundData.balance_shipping_rate_format})</p>
                                                <FormGroup className="mt-2">
                                                    <Label>Refund amount</Label>
                                                    <input type="number" min={0} step="any"
                                                        placeholder="Enter refund shipping amount"
                                                        className={'form-control ' + (errorShipRate ? 'is-invalid' : '')}
                                                        value={shippingRate}
                                                        onChange={shippingHandleChange}
                                                        onBlur={shippingHandleBlur} />
                                                    {errorShipRate && (
                                                        <div className="invalid-feedback">
                                                            {errorShipRate}
                                                        </div>
                                                    )}
                                                </FormGroup>
                                            </>}

                                            <h6 className="mb-2">Reason for refund</h6>
                                            <FormGroup>
                                                <input type="text" className="form-control" placeholder="Enter reason"
                                                    {...register('reason')} />
                                                <small className="form-text text-muted">Only you and other staff can see this reason.</small>
                                            </FormGroup>
                                        </Col>

                                        <Col md={4}></Col>

                                        <Col md={4}>
                                            <h6 className="mb-2">Refund amount</h6>

                                            <FormGroup>
                                                <Label>Shopify Payments</Label>
                                                <input type="number" min={0} step="any"
                                                    className={'form-control ' + (errors.total_refund_amount ? 'is-invalid' : '')}
                                                    value={totalRefund}
                                                    disabled={refundAmountDisabled}
                                                    onChange={refundHandleChange}
                                                    onBlur={refundHandleBlur} 
                                                />
                                                <small id="emailHelp" className="form-text text-muted">{formatter.format(refundData.balance_refund)} available for refund</small>
                                                {errors.total_refund_amount && (
                                                    <div className="invalid-feedback">
                                                        {errors.total_refund_amount?.message}
                                                    </div>
                                                )}
                                            </FormGroup>

                                            {(suggestedTotalRefund > 0 && suggestedTotalRefund != totalRefund) && (
                                                <div className="alert alert-warning" role="alert">
                                                    <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                                    The amount you’re refunding is different from the suggested refund total.
                                                </div>
                                            )}

                                            <div className="checkbox checkbox-dark">
                                                <input id="is_notify" type="checkbox"
                                                    onChange={(e) => {
                                                        setValue('is_notify', e.target.checked)
                                                    }}
                                                    defaultChecked={getValues('is_notify')} />
                                                <Label for="is_notify">Send a notification to the customer</Label>
                                            </div>

                                            <hr />

                                            <ButtonLoader disabled={buttonDisabled} onClick={submitData} btntext={"Refund " + formatter.format(totalRefund)} className="btn btn-primary btn-block" />
                                        </Col>
                                    </Row>
                                </>}

                                {!isDirectRefund && <>
                                    <div className="mt-2 d-flex justify-content-end">
                                        <div className="p-2">
                                            <ButtonLoader disabled={buttonDisabled} onClick={submitData} btntext="Return" className="btn btn-primary btn-block" />
                                        </div>
                                    </div>
                                </>}
                            </CardBody>
                        </Card>
                    </> : <>
                    {((currentUser.role.name !== "Seller" && orderState.refunds.length == 0) || currentUser.role.name === "Seller") && (
                        <Card>
                            <CardBody>
                                <p>Already refunded or permission denied.</p>
                            </CardBody>
                        </Card>
                    )}
                    </>}


                    {(currentUser.role.name !== "Seller") && <RefundedItem orderState={orderState} />}
                </Container>
            </>}
        </Fragment>
    );
}

export default withReducer([{ order: orderReducer }])(ReturnOrder);