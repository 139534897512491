import API from "../../utils/api";


export const getProductsApi = async (data) => {
    const response = await API.get(`product`,{
        params: data,
    });
    return { response };
};
export const getProductSetting = async (data) => {
    const response = await API.get(`product/get-product-settings`,{
        params: data,
    });
    return { response };
};

export const getProductCreateSetting = async (data) => {
    const response = await API.get(`product/get-product-create-settings`,{
        params: data,
    });
    return { response };
};

export const addProductApi = async data => {
    const response = await API.post('product', data);
    return { response };
};

export const getProductApi = async (id) => {
    const response = await API.get(`product/`+id+'/edit');
    return { response };
};
export const getProductViewApi = async (id) => {
    const response = await API.get(`product/`+id);
    return { response };
};
export const updateProductApi = async (data, id) => {
    const response = await API.put(`product/${id}`, data);
    return { response };
};

export const getSubCategoryByCategoryId = async (id) => {
    const response = await API.get(`product/get-sub-category/`+id);
    return { response };
};
export const addProductImageApi = async (id,data) => {
    const response = await API.post('product/upload-image/'+id, data,{'content-type': 'multipart/form-data'});
    return { response };
};

export const deleteProductApi = async ( id) => {
    const response = await API.delete(`product/delete-image/${id}`);
    return { response };
};

export const sortProductImageApi = async (id,data) => {
    const response = await API.post('product/sort-image/'+id, data);
    return { response };
};

export const bulkProductApi = async (data) => {
    const response = await API.post('product/bulk-product-action', data);
    return { response };
};
export const createDuplicateProductApi = async (data) => {
    const response = await API.post('product/create-duplicate-product', data);
    return { response };
};

export const bulkSalesOfferApi = async (data) => {
    const response = await API.post('product/bulk-sales-offer-update', data);
    return { response };
};

export const updateProductImageAltTextApi = async (data) => {
    const response = await API.post('product/update-image-alt-text', data);
    return { response };
};
export const sellerAutoSuggestionApi = async (text) => {
        const response = await API.get('product/seller-auto-suggestion', {
        params: text,
    });
    return { response };
};

export const sellerBrandSuggestionApi = async (text) => {
    const response = await API.get('product/brand-auto-suggestion', {
        params: text,
    });
    return { response };
};
export const taxonomySuggestionApi = async (text) => {
    const response = await API.get('product/taxonomy-auto-suggestion', {
        params: text,
    });
    return { response };
};

export const addProductLookBookApi = async data => {
    const response = await API.post('product/look-book/store-product-look-book', data);    
    return { response };
};

export const downloadLookBookApi = async (id) => {
    const response = await API.get(`product/look-book/product-look-book/${id}`, {'content-type': 'application/pdf','responseType': 'blob'});
    return { response };
};

export const checkLookBookTitleApi = async (data) => {
    const response = await API.get('product/look-book/check-look-book_title', {
        params: data,
    });
    return { response };
};

export const getLookBookApi = async (data) => {
    const response = await API.get(`product/look-book/look-book-view`,{
        params: data,
    });
    return { response };
};

export const getCurrencyRateApi = async (price, code) => {
    const response = await API.get(`product/look-book/currency-convert/${price}/${code}`);
    return { response };
};

export const getExportApi = async (data) => {
    const response = await API.get(`product/export-stockprice`, {
        params: data,
    }, {'content-type': 'application/vnd.ms-excel','responseType': 'blob'});
    return { response };
};

export const updateInventoryBulkApi = async data => {
    const response = await API.post('product/import-stockprice', data, {'content-type': 'multipart/form-data'});    
    return { response };
};
export const updateInventoryBulkBySkuApi = async (data) => {
    const response = await API.post('product/inventory/import-by-sku',data,{'content-type': 'multipart/form-data','responseType': 'json'});
    return { response };
};

export const addProductHolidayOfferApi = async data => {
    const response = await API.post('product/holiday-mode', data);    
    return { response };
};

export const downloadSampleCsvApi = async () => {
  const response = await API.get('product/download/sample-csv',{'content-type': 'application/vnd.ms-excel','responseType': 'blob'});
    return { response };
};

export const importProductCsvApi = async data => {
    const response = await API.post(`product/import-products`, data);
    return { response };
}

export const importProductsTagsApi = async data => {
    const response = await API.post(`product/import-products-tags`, data, {'content-type': 'multipart/form-data'});
    return { response };
}

export const importProductsGradeTagsApi = async data => {
    const response = await API.post(`product/import-grade-tags`, data, {'content-type': 'multipart/form-data'});
    return { response };
}

export const createSentImageApi = async (data) => {
    const response = await API.post('product/send-image',data);
    return { response };
};

export const updateAddtionalApi = async (data, id)  => {
    const response = await API.post(`product/additional-details/${id}`, data,{'content-type': 'multipart/form-data'});
    return { response };
};

export const getAdditionalSettingsApi = async id  => {
    const response = await API.get(`product/additional-details/${id}`);
    return { response };
};

export const getProductInfoApi = async id  => {
    const response = await API.get(`product/get-draft-product/${id}`);
    return { response };
};

export const exportProductsApi = async (data) => {
    const response = await API.get('product/export-usa-products', {params:data},{'content-type': 'application/vnd.ms-excel','responseType': 'blob'});
    return { response };
};

export const exportEUProductsApi = async (data) => {
    const response = await API.get('product/export-eu-products', {params:data},{'content-type': 'application/vnd.ms-excel','responseType': 'blob'});
    return { response };
};

export const exportMarketplaceProductsApi = async (data) => {
    const response = await API.get('product/export-marketplace-products', {params:data},{'content-type': 'application/vnd.ms-excel','responseType': 'blob'});
    return { response };
};

export const exportWalmartProductsApi = async (data) => {
    const response = await API.get('product/export-walmart-products', {params:data},{'content-type': 'application/vnd.ms-excel','responseType': 'blob'});
    return { response };
};

export const exportProductsToTBOneApi = async (data) => {
    const response = await API.get('tbone/product-export', {params:data},{'content-type': 'application/vnd.ms-excel','responseType': 'blob'});
    return { response };
};

export const exportUSProductsApi = async (data) => {
    const response = await API.get('product/inventory-export', {params:data},{'content-type': 'application/vnd.ms-excel','responseType': 'blob'});
    return { response };
};

export const inventoryAdjustmentApi = async (data)  => {
    const response = await API.post(`product/inventory-save`, data,{'content-type': 'multipart/form-data'});
    return { response };
};

export const getInventorySettingsApi = async () => {
    const response = await API.get('product/inventory-details');
    return { response };
};

export const inventorySkuApi = async (query) => {
    const response = await API.get('product/sku-auto-suggestion',{
        params: {query: query},
    });
    return { response };
};

export const skuLocationsApi = async (query) => {
    const response = await API.get('product/sku-locations',{
        params: {query: query},
    });
    return { response };
};

export const skuDetailsApi = async (query) => {
    const response = await API.get('product/sku-details',{
        params: query,
    });
    return { response };
};

export const inventoryBinApi = async (text) => {
    const response = await API.get('product/bin-auto-suggestion',{
        params: text,
    });
    return { response };
};

export const getInventoryAdjustApi = async (data)=>{
    const response= await API.get('product/get-all-inventory-data', {params:data});
    return {response}
};

export const exportProductConditionDetailsApi = async (data) => {
    const response = await API.get('product/export-product-condition-report', {params:data});
    return { response };
};

export const exportInventoryDetailsApi = async (data) => {
    const response = await API.get('product/export-inventory-updates-report', {params:data},{'content-type': 'application/vnd.ms-excel','responseType': 'blob'});
    return { response };
};

export const exportRibbonDetailsApi = async (data) => {
    const response = await API.get('product/export-ribbon-missing-report', {params:data},{'content-type': 'application/vnd.ms-excel','responseType': 'blob'});
    return { response };
};

export const exportBarcodeDetailsApi = async (data) => {
    const response = await API.get('product/export-barcode-missing-report', {params:data},{'content-type': 'application/vnd.ms-excel','responseType': 'blob'});
    return { response };
};

export const exportPlasticDetailsApi = async (data) => {
    const response = await API.get('product/export-plastic-bag-damaged-report', {params:data},{'content-type': 'application/vnd.ms-excel','responseType': 'blob'});
    return { response };
};

export const getInventoryAdjustDataApi = async (data)=>{
    const response= await API.get('product/get-all-inventory', {params:data});
    return {response}
};

export const addPlatformImageApi = async (data, id) => {
    const response = await API.post(`product/add-platform/${id}`, data);
    return { response };
};

export const binAutoSuggestionApi = async (text) => {
    const response = await API.get('product/bin-auto-suggestion', {
        params: text,
    });
    return { response };
};

export const exportInventoryAdjustmentDetailsApi = async (data) => {
    const response = await API.get('product/export-inventory-adjustment-report', {params:data},{'content-type': 'application/vnd.ms-excel','responseType': 'blob'});
    return { response };
};

export const inventoryAdjustmentDataApi = async (data)  => {
    const response = await API.post(`product/inventory-adjustment-save`, data,{'content-type': 'multipart/form-data'});
    return { response };
};

export const getAllCountriesApi = async (data) => {
    const response = await API.get('product/get-countries',data);
    return { response };
};

export const addSuppliersDetailsApi = async location => {
    const response = await API.post('product/add-suppliers', location);
    return { response };
};

export const getAllSuppliersApi = async data => {
    const response = await API.get('product/get-suppliers', {params:data});
    return { response };
};

export const deleteSupplierApi = async (id) => {
    const response = await API.delete(`product/delete-suppliers/${id}`);
    return { response };
};

export const getSupplierApi = async id => {
    const response = await API.get(`product/supplier/${id}/edit`);
    return { response };
};

export const updateSuppliersDetailsApi = async (data, id)  => {
    const response = await API.post(`product/supplier/${id}/update`, data,{'content-type': 'multipart/form-data'});
    return { response };
};
export const addPaymentTermsApi = async location => {
    const response = await API.post('product/add-payment-terms', location);
    return { response };
};

export const getAllPaymentTermsApi = async data => {
    const response = await API.get('product/get-payment-terms', {params:data});
    return { response };
};

export const deletePaymentTermApi = async (id) => {
    const response = await API.delete(`product/delete-payment-terms/${id}`);
    return { response };
};

export const getPaymentTermsApi = async id => {
    const response = await API.get(`product/payment-terms/${id}/edit`);
    return { response };
};

export const UpdatePaymentApi = async (data, id)  => {
    const response = await API.post(`product/payment-terms/${id}/update`, data,{'content-type': 'multipart/form-data'});
    return { response };
};

export const getAllPurchaseOrderDataApi = async (data) => {
    const response = await API.get('product/get-all-purchase',data);
    return { response };
};

export const searchProduct = async (data) => {
    const response = await API.get('order/draft/search-product',{params:data});
    return { response };
};

export const addPurchaseOrderDetailsApi = async data => {
    const response = await API.post('product/add-purchase-order', data);
    return { response };
};

export const getAllPurchaseOrderApi = async data => {
    const response = await API.get('product/get-purchase-order', {params:data});
    return { response };
};

export const getPurchaseOrderApi = async id => {
    const response = await API.get(`product/edit-purchase-order/${id}/edit`);
    return { response };
};

export const updatePurchaseOrderDetailsApi = async (data, id) => {
    const response = await API.post(`product/purchase-order/${id}/update`, data,{'content-type': 'multipart/form-data'});
    return { response };
};


export const deletePurchaseOrderApi = async (id) => {
    const response = await API.delete(`product/delete-purchase-order/${id}`);
    return { response };
};

export const getreceivedPurchaseOrderApi = async id => {
    const response = await API.get(`product/received-purchase-order/${id}`);
    return { response };
};

export const addreceiveInventoryDetailsApi = async data => {
    console.log("service",data);
    const response = await API.post('product/store-received-purchase-order', data);
    return { response };
};

export const acceptAllDataApi = async data => {
    const response = await API.post(`product/accept-all`,data);
    return { response };
};

export const rejectAllDataApi = async data => {
    const response = await API.post(`product/reject-all`,data);
    return { response };
};

export const createDuplicatePurchaseOrderApi = async (data) => {
    const response = await API.post('product/duplicate-purchase-order', data);
    return { response };
};

export const downloadPOByIdApi = async ( id)  => {
    const response = await API.get(`product/${id}/download/purchase-order-pdf`,{'content-type': 'application/pdf','responseType': 'blob',});
    return { response };
};

export const generatePOBulkPdfApi = async (data) => {
    const response = await API.get(`product/download-bulk/purchase-order-pdf`, {'content-type': 'application/pdf','responseType': 'blob',params:{start_date:data.start_date,end_date:data.end_date,id_array:data.orderIdArray}});
    return { response };
};

export const removePlatformImageApi = async (data, id) => {
    const response = await API.post(`product/remove-platform/${id}`, data);
    return { response };
};

export const exportLystFeedProductsApi = async (data) => {
    const response = await API.get('product/export-lyst-feed', {params:data});
    return { response };
};

export const exportGroupedProductsApi = async () => {
    const response = await API.get('product/export-group-product-report');
    return { response };
};

export const codeWithColourAutoSuggestion = async (data) => {
    const response = await API.get('product/code-with-colour-auto-suggestion', {params:data});
    return { response };
};

export const syncGTNNumberApi = async (data)  => {
    const response = await API.post(`product/sync-gtn-numbers`, data);
    return { response };
};
