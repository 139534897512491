import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {toast} from "react-toastify";
import {getAllPaymentTermsApi, addPaymentTermsApi, deletePaymentTermApi, getPaymentTermsApi, UpdatePaymentApi} from "../../../services/manage-product/productService";


export const getAllPaymentTerms=createAsyncThunk('product/getAllPaymentTerms',async(data, {dispatch})=>{
    const response=await getAllPaymentTermsApi(data);
    dispatch(setTableRowCount(response.response.count))
    return response.response.data;
});

export const addPaymentTerms = createAsyncThunk('product/addPaymentTerms', async (product, { dispatch, getState }) => {
    try {
        const response = await addPaymentTermsApi(product);
        const data = await response.response;
        toast.success("Payment Method added successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });

        return data;
    } catch (e) {

        return Promise.reject(e);
    }
});

export const deletePaymentTerm = createAsyncThunk('product/deletePaymentTerm', async (id, {dispatch,getStatus}) => {
    try {
        const response = await deletePaymentTermApi(id);
        const data = await response.response;
        toast.success("Deleted Payment Method successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const getPaymentTerms = createAsyncThunk('product/getPaymentTerms', async (id,{dispatch})=>{
    const response = await getPaymentTermsApi(id);
    return response.response;
});

export const UpdatePayment = createAsyncThunk('product/UpdatePayment', async (data,{dispatch}) => {
    const response = await UpdatePaymentApi(data,data.id);
    toast.success(`Payment Method updated successfully`, {
        position: toast.POSITION.TOP_RIGHT,
    });
    return response.response;
});



const paymentTermsAdapter = createEntityAdapter({});

export const {selectAll: selectPaymentTerms, selectById: selectPaymentTermsById} = paymentTermsAdapter.getSelectors(
    state => state.product.paymentTerms
);

const paymentTermsSlice = createSlice({
    name: 'paymentTerms',
    initialState: paymentTermsAdapter.getInitialState({
        paymentTermsTableRow: 0,
    }),
    productCountries: {
        countries:[],
    },
    reducers: {
       
        setTableRowCount: (state, action) => {
            state.paymentTermsTableRow = action.payload;
        },
        
    },
    extraReducers: {
        [getAllPaymentTerms.fulfilled]: paymentTermsAdapter.setAll
    }
});

export const {
     setTableRowCount,
   
} = paymentTermsSlice.actions;

export default paymentTermsSlice.reducer;