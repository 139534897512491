
import React, {useState} from 'react';
import {Button, Card, CardBody, Col, FormGroup, Input, Label, Popover, PopoverBody, PopoverHeader} from "reactstrap";
import {Row} from "react-bootstrap";
import {paymentCalculation} from "./setting/calculation";
import OrderDiscount from "./orderDiscount";
import {
    setOrderDiscountDialog,
    setSearchProductDialog,
    setShippingDiscountDialog, setTaxDialog, setCostAdjustmentDialog
} from "../store/purchaseOrderSlice";

import {useDispatch} from "react-redux";

const PopoverBasicItem = (props) => {
    const {id, btntext, Popoverbody} = props;
    const [popover, setPopover] = useState(false)
    const Toggle = () => setPopover(!popover);
    return (
        <>
            <a className="a-text-color" id="tax">Tax</a>
            <Popover
                placement="right"
                isOpen={popover}
                target="tax"
                toggle={Toggle}
                trigger="click"
            >
                <PopoverBody>
                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <Label className="col-form-label">taxs are Automatically Calculated</Label>
                                <Col md="12">
                                    <div className="checkbox checkbox-dark">
                                        <Input id="is_tax" type="checkbox"  onChange={(e)=>{

                                        }} defaultChecked={false}/>
                                        <Label for="is_tax">Charge Taxes</Label>
                                    </div>
                                </Col>

                            </FormGroup>

                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Button color="default"  className="btn btn-default btn-sm" onClick={Toggle}>Cancel</Button>
                        </Col>
                        <Col md={6}>
                            <Button  color="primary" className="btn btn-primary btn-sm">Apply</Button>
                        </Col>
                    </Row>
                </PopoverBody>

            </Popover>
        </>
    );
};

function ShowOrderPayment(props) {

    const dispatch = useDispatch();
    const {setValue, trigger, getValues} = props;
    
    const payment = paymentCalculation(getValues);
    console.log("payment",payment);

  return (
     <Card>
            <CardBody>
                <Row>
                    <Col md="12">
                        <h6>Payment</h6>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <table className="table table-borderless">
                            <tbody>
                            <tr>
                                <td>Sub Total</td>
                                <td></td>
                                <td align="right">{payment.subTotal}</td>
                            </tr>
                            <tr>
                                <td>Tax</td>
                                <td></td>
                                <td align="right">{payment.taxPrice}</td>
                            </tr>
                            <tr>
                                <td>Discount</td>
                                <td>{getValues('discount') ? (getValues('discount').reason ? getValues('discount').reason : "----") : "----"}</td>
                                <td align="right">{payment.discountPrice}</td>
                            </tr>
                            <tr>
                                <td>Shipment</td>
                                <td>{getValues('shipping') ? (getValues('shipping').rate_name ? getValues('shipping').rate_name : "----") : "----"}</td>
                                <td align="right">{payment.shippingPrice}</td>
                            </tr>
                           
                            <tr>
                                <td>
                                    <h6>Cost adjustments</h6>
                                </td>
                            </tr>
                            
                            <tr>
                                <td>
                                   Cost
                                </td>
                               
                                {getValues('cost') && getValues('cost.costAdjustment') ? (
                                    getValues('cost.costAdjustment').map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.name}</td>
                                        </tr>
                                    ))
                                    ) : (
                                    <tr>
                                        <td colSpan="1">----</td>
                                    </tr>
                                )}
                               
                              <td align="right">
                              {getValues('cost') && getValues('cost.costAdjustment') ? (
                                    getValues('cost.costAdjustment').map((item, index) => (
                                        console.log("item",item),
                                    <tr key={index}>
                                        <td align="right" style={{ padding: '0px 0 24px 0' }}>
                                        {item.costAmount !== undefined && !isNaN(item.costAmount)
                                            ? (item.costAmount).toFixed(2)
                                            : ''
                                        }
                                       
                                        </td>
                                    </tr>
                                    ))
                                ) : (
                                    <></>
                                )
                                }
                            </td>
                            </tr>
                            <tr>
                                <td>Total</td>
                                <td colSpan="2" align="right">
                                    {payment.total}
                                   
                                    </td>
                            </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>

            </CardBody>
        </Card>
  )
}

export default ShowOrderPayment