import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {toast} from "react-toastify";
import {getAllCountriesApi,addSuppliersDetailsApi, getAllSuppliersApi, deleteSupplierApi, getSupplierApi, updateSuppliersDetailsApi} from "../../../services/manage-product/productService";


export const getAllSuppliers=createAsyncThunk('product/getSuppliers',async(data, {dispatch})=>{
    const response=await getAllSuppliersApi(data);
    dispatch(setTableRowCount(response.response.count))
    return response.response.data;
});

export const getAllCountries = createAsyncThunk('product/getAllCountries', async (data) => {
    const response = await getAllCountriesApi(data);
    return response.response;
});

export const addSuppliersDetails = createAsyncThunk('product/addSuppliersDetails', async (product, { dispatch, getState }) => {
    try {
        const response = await addSuppliersDetailsApi(product);
        const data = await response.response;
        // dispatch(getUsers());
        toast.success("Suppliers Details added successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });

        return data;
    } catch (e) {

        return Promise.reject(e);
    }
});

export const deleteSupplier = createAsyncThunk('product/deleteSupplier', async (id, {dispatch,getStatus}) => {
    try {
        console.log("id",id);
        const response = await deleteSupplierApi(id);
        const data = await response.response;
        toast.success("Deleted Supplier successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const getSupplier = createAsyncThunk('product/editSupplier', async (id,{dispatch})=>{
    const response = await getSupplierApi(id);
    return response.response;
});

export const updateSuppliersDetails = createAsyncThunk('product/updateSuppliersDetails', async (data,{dispatch}) => {
    console.log("slice34",data,data.id);
    const response = await updateSuppliersDetailsApi(data,data.id);
    toast.success(`Supplier updated successfully`, {
        position: toast.POSITION.TOP_RIGHT,
    });
    return response.response;
});



const suppliersAdapter = createEntityAdapter({});

export const {selectAll: selectsuppliers, selectById: selectSuppliersById} = suppliersAdapter.getSelectors(
    state => state.product.suppliers
);

const suppliersSlice = createSlice({
    name: 'suppliers',
    initialState: suppliersAdapter.getInitialState({
        supplierTableRow: 0,
    }),
    productCountries: {
        countries:[],
    },
    reducers: {
        setCountries: (state, action) => {
            state.productCountries = action.payload;
            console.log("test1",);
        },
        setTableRowCount: (state, action) => {
            state.supplierTableRow = action.payload;
        },
        
    },
    extraReducers: {
        [getAllSuppliers.fulfilled]: suppliersAdapter.setAll
    }
});

export const {
    setCountries, setTableRowCount,
   
} = suppliersSlice.actions;

export default suppliersSlice.reducer;