import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {toast} from "react-toastify";
import {getAllPurchaseOrderDataApi,searchProduct, addPurchaseOrderDetailsApi, getAllPurchaseOrderApi, getPurchaseOrderApi, updatePurchaseOrderDetailsApi,
    deletePurchaseOrderApi, getreceivedPurchaseOrderApi, addreceiveInventoryDetailsApi, acceptAllDataApi,rejectAllDataApi, createDuplicatePurchaseOrderApi, downloadPOByIdApi, generatePOBulkPdfApi } from "../../../services/manage-product/productService";


export const getAllPurchaseOrder=createAsyncThunk('product/getAllPurchaseOrder',async(data, {dispatch})=>{
    const response=await getAllPurchaseOrderApi(data);
    dispatch(setTableRowCount(response.response.count))
    return response.response.data;
});

export const getAllPurchaseOrderData = createAsyncThunk('product/getAllPurchaseOrderData', async (data) => {
    const response = await getAllPurchaseOrderDataApi(data);
    return response.response;
});

export const productSearch = createAsyncThunk('orders/draft/search-product', async (data, {dispatch,}) => {
    const response = await searchProduct(data);
    return response.response;
});

export const addPurchaseOrderDetails = createAsyncThunk('product/addPurchaseOrderDetails', async (product, { dispatch, getState }) => {
    try {
        const response = await addPurchaseOrderDetailsApi(product);
        const data = await response.response;
        toast.success("Purchase Order Details added successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });

        return data;
    } catch (e) {

        return Promise.reject(e);
    }
});

export const editPurchaseOrderDetails = createAsyncThunk('product/editPurchaseOrderDetails', async (id,{dispatch})=>{
    const response = await getPurchaseOrderApi(id);
    return response.response;
});

export const updatePurchaseOrderDetails = createAsyncThunk('product/updatePurchaseOrderDetails', async (product, { dispatch, getState }) => {
    try {
        const response = await updatePurchaseOrderDetailsApi(product,product.id);
        const data = await response.response;
        toast.success("Purchase Order Details updated successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });

        return data;
    } catch (e) {

        return Promise.reject(e);
    }
});

export const createDuplicatePurchaseOrder = createAsyncThunk('createDuplicatePurchaseOrder', async (product, {dispatch, getState}) => {
    try {
        const response = await createDuplicatePurchaseOrderApi(product);
        const data = await response.response;
        toast.success("Duplicate purchase order successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {

        return Promise.reject(e);
    }
});

export const deletePurchaseOrder = createAsyncThunk('product/deletePurchaseOrder', async (id, {dispatch,getStatus}) => {
    try {
        const response = await deletePurchaseOrderApi(id);
        const data = await response.response;
        toast.success("Deleted Order successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const receivedPurchaseOrderDetails = createAsyncThunk('product/receivedPurchaseOrderDetails', async (id,{dispatch})=>{
    const response = await getreceivedPurchaseOrderApi(id);
    return response.response;
});

export const addreceiveInventoryDetails = createAsyncThunk('product/addreceiveInventoryDetails', async (product, { dispatch, getState }) => {
    try {
        console.log("Sliceproduct",product);
        const response = await addreceiveInventoryDetailsApi(product);
        const data = await response.response;
        toast.success("Receive Inventory Details added successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });

        return data;
    } catch (e) {

        return Promise.reject(e);
    }
});

export const acceptAllData = createAsyncThunk('product/acceptAllData', async (formData,{dispatch})=>{
    const response = await acceptAllDataApi(formData);
    return response.response;
});

export const generatePOPdfData = createAsyncThunk('product/generatePOPdf', async (id, {dispatch}) => {
    const response = await downloadPOByIdApi(id);
    return response.response;
});

export const generatePOBulkPdfData = createAsyncThunk('product/generatePurchaseOrderBulkPdf', async (data, {dispatch}) => {
    const response = await generatePOBulkPdfApi(data);
    return response.response;
});

export const rejectAllData = createAsyncThunk('product/rejectAllData', async (formData,{dispatch})=>{
    const response = await rejectAllDataApi(formData);
    return response.response;
});

const purchaseOrderAdapter = createEntityAdapter({});

export const {selectAll: selectpurchaseOrder, selectById: selectpurchaseOrderById} = purchaseOrderAdapter.getSelectors(
    state => state.product.purchaseOrder
);

const purchaseOrderSlice = createSlice({
    name: 'purchaseOrder',
    initialState: purchaseOrderAdapter.getInitialState({
        purchaseOrderTableRow: 0,
        purchaseOrderData: {
            supplier:[],
            locations:[],
            paymentTerms:[]
        },
        searchProductDialogProps: {
            props: {
                isOpen: false
            },
            data:{searchValue:""}
        },
        products: {
            data:[],
            pagination:{
                count:0,
                next_page:1,
                total:0,
                has_more:true,
            }
        },
        discountDialogProps: {
            props: {
                isOpen: false
            },
            data:{}
        },
        shippingDialogProps: {
            props: {
                isOpen: false
            },
            data:{}
        },
        costDialogProps: {
            props: {
                isOpen: false
            },
            data:{}
        },
        dataList: [],
    }),
   
    reducers: {
        setPurchaseOrderData: (state, action) => {
            state.purchaseOrderData = action.payload;
        },
        setTableRowCount: (state, action) => {
            state.purchaseOrderTableRow = action.payload;
        },
        setSearchProductDialog: (state, action) => {
            state.searchProductDialogProps = action.payload
        },
        setProducts: (state, action) => {
            state.products = action.payload;
        },
        setOrderDiscountDialog: (state, action) => {
            state.discountDialogProps = action.payload;
        },
        setShippingDiscountDialog: (state, action) => {
            state.shippingDialogProps = action.payload;
        },
        setCostAdjustmentDialog: (state, action) => {
            state.costDialogProps = action.payload;
        },
        setReceivedOrderData: (state, action) => {
            state.dataList = action.payload.dataList;
            state.receiveOrder = action.payload.receiveOrder;
        },
        
    },
    extraReducers: {
        [getAllPurchaseOrder.fulfilled]: purchaseOrderAdapter.setAll
    }
});

export const {
     setTableRowCount, setPurchaseOrderData, setSearchProductDialog, setProducts, setOrderDiscountDialog,
     setShippingDiscountDialog, setCostAdjustmentDialog, setReceivedOrderData
   
} = purchaseOrderSlice.actions;

export default purchaseOrderSlice.reducer;