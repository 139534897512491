import React, {Fragment, useEffect, useState} from 'react';
import Breadcrumb from "../../../layout/breadcrumb";
import {
    ButtonDropdown,Card,
    CardBody, CardHeader,Col,
    Container,DropdownItem,DropdownMenu,DropdownToggle, Form,
    FormGroup,Input,Label,Nav,NavItem,NavLink,
    Row,Popover,PopoverBody,PopoverHeader,
} from "reactstrap";
import {Accordion} from "react-bootstrap";

import AsyncSelect from "react-select/async";
// import {mapSearchAutoSuggestion} from "../../../utils/mapper";
// import {sellerAutoSuggestionApi, sellerBrandSuggestionApi} from "../../../services/manage-product/productService";
import {useDispatch, useSelector} from "react-redux";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import withReducer from "../../../store/withReducer";
// import inventoryReducer from "../store";
// import {closeDialog,} from "../store/inventorySlice";
// import {getProducts} from "../store/prodcutSlice";
import PreLoader from "../../../share-components/ui/preLoader";
import Select from "react-select";
import { toast } from 'react-toastify';
import { DateRangePicker } from 'rsuite';
import DatePicker from "../../../share-components/ui/DateRangePicker";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import { Link } from 'react-router-dom';
import AddInventory from './addInventory';
import {  getInventorySettings, setSettings, inventorySku, inventoryBin, selectProduct } from "../store/prodcutSlice";
import {  getInventoryAdjust, selectinventoryAdjustment, exportProductConditionDetails, exportInventoryDetails, exportRibbonDetails,
    exportBarcodeDetails, exportPlasticDetails
 } from "../store/inventoryAdjustmentSlice";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import moment from "moment";
import inventoryAdjustmentReducer from "../store";

const { afterToday } = DateRangePicker;


const dataMenu = [
    {
        type: 0,
        text: 'Stock Count',
    }
];

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

function InventoryAdjustments() {

    const productState = useSelector(({product}) => product.product);
    const inventoryState = useSelector(({product}) => product.inventoryAdjustment);
    const dispatch = useDispatch();
    const [actionDropdownOpen, setActionDropdownOpen] = useState(false);
    const actionToggle = () => setActionDropdownOpen(prevState => !prevState);
    const TodayDate = new Date();
    const sevenDays = new Date().setDate(TodayDate.getDate() - 90);
    const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);
    const [startDate, endDate] = dateRange;
    const [page, setPage] = useState(1);

    const tableColumns = [
    
        {
            name: 'SKU',
            selector: row => row.sku,
            sortable: true,
            center: false,
            width: '20%'
        }, 
        {
            name: 'Bin Number',
            selector: row => row.bin_number
            ,
            sortable: true,
            center: false,
            width: '10%'
        }, 
        {
            name: 'Stock Type',
            selector: row => row.stock_type,
            sortable: true,
            center: false,
            width: '10%'
        },
        // {
        //     name: 'Reason',
        //     selector: row => row.reason_id,
        //     sortable: true,
        //     center: false,
        //     width: '10%'
        // }, 
        {
            name: 'Quantity',
            selector: row => row.warehouse_input_qty,
            sortable: true,
            center: false,
        }, 
        {
            name: 'Ribbon Missing',
            selector: row => row.conditions.includes('Ribbon is missing') ? 'Yes' : '',
            sortable: true,
            center: false,
            width: '10%'
        },
        {
            name: 'Barcode Missing',
            selector: row => row.conditions.includes('Barcode is missing') ? 'Yes' : '',
            sortable: true,
            center: false,
            width: '10%'
        },
        {
            name: 'Plastic Bag Damaged',
            selector: row => row.conditions.includes('Plastic bag is damaged') ? 'Yes' : '',
            sortable: true,
            center: false,
            width: '10%'
        },
        {
            name: 'Created At',
            selector: row => row.created_at,
            sortable: true,
            center: false,
            width: '10%'
        }, 
        {
            name: 'Created By',
            selector: row => {
                const createdBy = row.created_by;
                if (typeof createdBy === 'string' && createdBy.length > 0) {
                    return createdBy.charAt(0).toUpperCase() + createdBy.slice(1);
                }
                return createdBy;
            },
            sortable: true,
            center: false,
            width: '10%'
        }, 
    ];

  useEffect(() => {
    dispatch(getInventorySettings()).then(res => {
        dispatch(setSettings(res.payload))
    });
}, [dispatch]);

    const [isLoadingSku, setIsLoadingSku] = useState(false);
    const [selectedSkuData, setSelectedSkuData] = useState(null);
    const [skus, setSkus] = useState([]);
    const handleSearchForSku = (query) => {
        setIsLoadingSku(true);
        dispatch(inventorySku(query)).then((res) => {
          if (!res.error) {
            setSkus(res.payload);
            setIsLoadingSku(false);
          }
        });
      };

    const handleOnSkuChange = (skuData) => {
        setSelectedSkuData(skuData[0]);
    }
    const filterBy = () => true;

    const [selectedBinId, setSelectedBinId] = useState(null);
    const [isLoadingBin, setIsLoadingBin] = useState(false);
    const [bins, setBins] = useState([]);
    const handleSearchForBin = (query) => {
        setIsLoadingBin(true);
        dispatch(inventoryBin(query)).then((res) => {
          if (!res.error) {
            setBins(res.payload);
            setIsLoadingBin(false);
          }
        });
      };

    const handleOnBinChange = (binData) => {
        const selectedId = binData.length > 0 ? binData[0].id : null;
        setSelectedBinId(selectedId);
    }

    const [tableLoading, isSetTableLoading] = useState(false);
    
    const [pageSize, setPageSize] = useState(10);
    const [stock, setStock] = useState(null);
    // const [reason, setReason] = useState(null);
    const [skuFilter, setSkuFilter] = useState(null);
    const [condition, setCondition] = useState(null);
    const [user, setUser] = useState(null);
    const [binFilter, setBinFilter] = useState(null);
    const inventory = useSelector(selectinventoryAdjustment);

    useEffect(()=> {
        getData();
    },[dispatch, page, pageSize, startDate, endDate, stock, skuFilter, condition, user, binFilter, selectedSkuData, selectedBinId]);

    const getData = () =>{
        
        isSetTableLoading(true);
        const data = {
            page:page,
            pageSize:pageSize,
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            stock:stock,
            // reason:reason,
            skuFilter:selectedSkuData ? selectedSkuData.sku : '',
            condition:condition,
            user:user,
            binFilter:selectedBinId?selectedBinId:'',
        }
        
        dispatch(getInventoryAdjust(data)).then(res=>{
            if(!res.error){
                // isSetLoading(false);
            }
            isSetTableLoading(false);
        });
    
    }

    const [btnDisable, setBtnDisable] = useState(false);
    const [importDdpFile, setImportDdpFile] = useState(false);
    const exportProductCondition = () => {
        setBtnDisable(true);
        setImportDdpFile(false)
        const data = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            stock: stock ? stock : '',
            user: user ? user : '',
            selectedSkuData: selectedSkuData ? selectedSkuData.sku :'',
            selectedBinId : selectedBinId ? selectedBinId :'',
            condition : condition ? condition : '',
        }
        const outputFilename = `product_condition_${(new Date().toJSON().slice(0, 10))}.csv`;
        dispatch(exportProductConditionDetails({...data})).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            setBtnDisable(false);
        });

    }

    const exportInventory = () => {
        setBtnDisable(true);
        setImportDdpFile(false)
        const data = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            stock: stock ? stock : '',
            user: user ? user : '',
            selectedSkuData: selectedSkuData ? selectedSkuData.sku :'',
            selectedBinId : selectedBinId ? selectedBinId :'',
            condition : condition ? condition : '',
            
        }
        const outputFilename = `inventory_${(new Date().toJSON().slice(0, 10))}.csv`;
        dispatch(exportInventoryDetails({...data})).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            setBtnDisable(false);
        });

    }

    const exportRibbon = () => {
        setBtnDisable(true);
        setImportDdpFile(false)
        const data = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            stock: stock ? stock : '',
            user: user ? user : '',
            selectedSkuData: selectedSkuData ? selectedSkuData.sku :'',
            selectedBinId : selectedBinId ? selectedBinId :'',
            condition : condition ? condition : '',
        }
        const outputFilename = `exportRibbon_${(new Date().toJSON().slice(0, 10))}.csv`;
        dispatch(exportRibbonDetails({...data})).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            setBtnDisable(false);
        });

    }

    const exportBarcode = () => {
        setBtnDisable(true);
        setImportDdpFile(false)
        const data = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            stock: stock ? stock : '',
            user: user ? user : '',
            selectedSkuData: selectedSkuData ? selectedSkuData.sku :'',
            selectedBinId : selectedBinId ? selectedBinId :'',
            condition : condition ? condition : '',
        }
        const outputFilename = `exportBarcode_${(new Date().toJSON().slice(0, 10))}.csv`;
        dispatch(exportBarcodeDetails({...data})).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            setBtnDisable(false);
        });

    }

    const exportPlastic = () => {
        setBtnDisable(true);
        setImportDdpFile(false)
        const data = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            stock: stock ? stock : '',
            user: user ? user : '',
            selectedSkuData: selectedSkuData ? selectedSkuData.sku :'',
            selectedBinId : selectedBinId ? selectedBinId :'',
            condition : condition ? condition : '',
        }
        const outputFilename = `exportPlastic_${(new Date().toJSON().slice(0, 10))}.csv`;
        dispatch(exportPlasticDetails({...data})).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            setBtnDisable(false);
        });

    }

  return (
    <Fragment>
    <Breadcrumb title="Stock Count" data={dataMenu}/>

    <Container fluid={true} className="product-wrapper">
       <div className="product-grid">
           <div className="feature-products">

           <Row>
                            <Col sm="12">

                                    <Card>
                                        <Row>
                                            <Col md={3}>
                                                <div style={{marginTop: 7}} className="dashboard-datepicker ml-2">
                                                    <DatePicker 
                                                        dateRange={dateRange} 
                                                        dateRangeChange={(date)=>{
                                                            setDateRange(date)
                                                        }}
                                                        defaultValue={[TodayDate, sevenDays]}
                                                        disabledDate={afterToday()}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={3}></Col>
                                            <Col md={6}>
                                                <>

                                                <div className='float-sm-right p-3'>
                                                    
                                                <Link to="/product/add-stock-count" className="btn btn-sm btn-primary">
                                                    <i className="fa fa-plus"></i> New Stock Count
                                                </Link>
                                                </div>

                                                        <div className='float-sm-right pt-3 pb-3 pr-3'>
                                                            <ButtonDropdown isOpen={actionDropdownOpen}
                                                                            toggle={actionToggle}>
                                                                <DropdownToggle caret color="succuss" className="btn btn-success">
                                                                   Export Reports
                                                                </DropdownToggle>
                                                                <DropdownMenu>
                                                                    <DropdownItem onClick={() => {
                                                                        exportProductCondition()

                                                                    }
                                                                    }>Export Product Condition Report</DropdownItem>
                                                                    <DropdownItem onClick={() => {
                                                                        exportInventory()

                                                                    }
                                                                    }>Export Inventory Updates Report</DropdownItem>
                                                                    <DropdownItem onClick={() => {
                                                                        exportRibbon()

                                                                    }
                                                                    }>Export Ribbon Missing Report</DropdownItem>

                                                                    <DropdownItem onClick={() => {
                                                                        exportBarcode()

                                                                    }
                                                                    }>Export Barcode Missing Report</DropdownItem>
                                                                    <DropdownItem onClick={() => {
                                                                        exportPlastic();

                                                                    }
                                                                    }>Export Plastic Bag Damaged Report</DropdownItem>
                                                                   
                                                                   
                                                                </DropdownMenu>
                                                            </ButtonDropdown>
                                                        </div>

                                                      
                                                       
                                                    </>


                                            </Col>
                                        </Row>
                                    </Card>

                            </Col>
                        </Row>

           <Row>
                   <Col md='4'>
                       <FormGroup>
                           <Select
                                name='stock'
                                placeholder='Select Stock Type'
                                className="select2-filter"
                                defaultValue=''
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                isClearable
                                cacheOptions
                                styles={customStyles}
                                options={productState.settings.stockType}
                                onChange={(e) => {
                                       setStock(e ? e.id : null)
                                }}
                                components={{
                                    IndicatorSeparator: () => null
                                }}

                           />
                       </FormGroup>
                   </Col>
                   {/* <Col md='4'>
                       <FormGroup>
                           <Select
                                name='reason'
                               placeholder='Select Reason'
                               getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                               className="select2-filter"
                               isClearable
                               cacheOptions
                               styles={customStyles}
                               options={productState.settings.reasonType}
                               onChange={(e) => {
                                   setReason(e ? e.id : null)
                               }}
                               components={{
                                   IndicatorSeparator: () => null
                               }}

                           />
                       </FormGroup>
                   </Col> */}
                   <Col md="4">
                        <AsyncTypeahead
                            filterBy={filterBy}
                            id="async-example"
                            isLoading={isLoadingSku}
                            minLength={1}
                            labelKey="sku"
                            onChange={(selected) => {
                                handleOnSkuChange(selected)
                            }}
                            onSearch={handleSearchForSku}
                            options={skus}
                            placeholder="Search for SKU..."
                            renderMenuItemChildren={(option) => (
                            <>
                                <span>{option.sku}</span>
                            </>
                            )}
                            inputProps={{
                                style: { height: '50px' }
                            }}
                        />
                   </Col>
                   <Col md="4">
                   <AsyncTypeahead
                            name="binNumber"
                            // className={`${errors.binNumber ? 'is-invalid' : ''}`}
                            filterBy={filterBy}
                            id="async-example"
                            isLoading={isLoadingBin}
                            minLength={1}
                            labelKey="name"
                            onChange={(selected) => {
                                handleOnBinChange(selected);
                            }}
                            onSearch={handleSearchForBin}
                            options={bins}
                            placeholder="Search for Bin..."
                            renderMenuItemChildren={(option) => (
                            <>
                                <span>{option.name}</span>
                            </>
                            )}
                            inputProps={{
                                style: { height: '50px' }
                            }}
                        />
                   </Col>
               </Row>
               <Row>
               <Col md='4'>
                       <FormGroup>
                           <Select
                                name='condition'
                               placeholder='Select Product Condition'
                               className="select2-filter"
                               isClearable
                               cacheOptions
                               getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                               styles={customStyles}
                               options={productState.settings.productConditionType}
                               onChange={(e) => {
                                   setCondition(e ? e.id : null)
                               }}
                               components={{
                                   IndicatorSeparator: () => null
                               }}

                           />
                       </FormGroup>
                   </Col>
                   <Col md='4'>
                       <FormGroup>
                           <Select
                                name='user'
                               placeholder='Select User'
                               className="select2-filter"
                               isClearable
                               getOptionLabel={(option) => option.name}
                               getOptionValue={(option) => option.id}
                               cacheOptions
                               styles={customStyles}
                            //    options={productState.settings.user}
                                options={productState.settings.user.map(option => ({
                                    ...option,
                                    name: option.name.charAt(0).toUpperCase() + option.name.slice(1)
                                }))}
                               onChange={(e) => {
                                   setUser(e ? e.id : null)
                               }}
                               components={{
                                   IndicatorSeparator: () => null
                               }}

                           />
                       </FormGroup>
                   </Col>
                 
               </Row>
               <Row>
                   <Col sm="12" className="mt-3">
                       <Card>
                           <CardBody>
                               <div className="table-responsive product-table">
                                   <CommonDataTable
                                   headerColumns={tableColumns}
                                   gridData={inventory}
                                   noAction
                                   progressPending={tableLoading}
                                   paginationServer
                                   paginationTotalRows={inventoryState.inventoryAdjustmentTableRow}
                                   paginationPerPage={10}
                                   paginationComponentOptions={{
                                       noRowsPerPage: true
                                   }}
                                   onChangePage={page => setPage(page)}
                                       
                                   />
                               </div>
                           </CardBody>
                       </Card>
                   </Col>
               </Row>
           </div>
       </div>
   </Container>
</Fragment>
  )
}

export default withReducer([{product: inventoryAdjustmentReducer}])(InventoryAdjustments);