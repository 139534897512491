import React from 'react';
import { PopoverBody } from 'reactstrap';
import { Link } from "react-router-dom";

import {
    formatter, formatterDecimal
} from "../../../../utils/common";

import PopoverFormula from './PopoverFormula';

const GrossProfitSummary = (props) => {

    const {
        data,
        loading,
        getPercentage,
        generateLink,
    } = props;

    return (
        <div className="card">
            {loading && (
                <div className="card-loading">
                    <i className="fa fa-spinner fa-lg spinner" />
                </div>
            )}

            <div className="card-body">
                <h5 className="card-title">Gross profit</h5>
                <div className="d-flex justify-content-between mb-2">
                    <div>                        
                        <Link to={generateLink('net-sales')} target="_blank" className="text-decoration-none text-primary">
                            Net Sales
                        </Link>
                        <PopoverFormula className="ml-3" id="grossProfitNetSalesFormula">
                            <PopoverBody>
                                <h6>Net Sales Formula</h6>
                                <p>Net Sales = Gross Sales - Discounts - Returns</p>
                            </PopoverBody>
                        </PopoverFormula>
                    </div>
                    <span>{formatter.format(data.net_sales)}</span>
                </div>
                <div className="d-flex justify-content-between mb-2">
                    <div>                        
                        <Link to={generateLink('product-cost')} target="_blank" className="text-decoration-none text-primary">
                            Cost of goods sold
                        </Link>
                        
                        <span className='text-dark pl-2'>
                            ({getPercentage(data.net_sales, data.total_mp_cost)}%)
                        </span>
                        <PopoverFormula className="ml-3" id="grossProfitCostFormula">
                            <PopoverBody>
                                <h6>Cost Formula</h6>
                                <p>Cost of Goods Sold = Cost price (excl. VAT) of products in net sales orders</p>
                            </PopoverBody>
                        </PopoverFormula>
                    </div>
                    <span>({formatter.format(data.total_mp_cost)})</span>
                </div>

                <div className="d-flex justify-content-between mb-2">
                    <div>
                        <Link to={generateLink('commissions')} target="_blank" className="text-decoration-none text-primary">
                            Commissions
                        </Link>                        
                        <span className='text-dark pl-2'>
                            ({formatterDecimal.format(data.commission_percentage)}%)
                        </span>
                        <PopoverFormula className="ml-3" id="grossProfitCommissionFormla">
                            <PopoverBody>
                                <h6>Commissions Formula</h6>
                                <p>Commissions = Commission Percentage * Net Sales / 100</p>
                            </PopoverBody>
                        </PopoverFormula>
                    </div>
                    <span>({formatter.format(data.commission_amount)})</span>
                </div>

                <div className="d-flex justify-content-between mb-2">
                    <div>
                        <Link to={generateLink('additional-charges')} target="_blank" className="text-decoration-none text-primary">
                            Additional Charges
                        </Link>
                        <span className='text-dark pl-2'>
                            ({formatterDecimal.format(data.additional_commission_percentage)}%)
                        </span>
                        <PopoverFormula className="ml-3" id="grossProfitAdditionalChargesFormla">
                            <PopoverBody>
                                <h6>Additional Charges</h6>
                                <p>Additional Charges = Additional Charges Percentage * Net Sales / 100</p>
                            </PopoverBody>
                        </PopoverFormula>
                    </div>
                    <span>({formatter.format(data.additional_commission_amount)})</span>
                </div>

                <div className="d-flex justify-content-between mb-2">
                    <div>
                        <Link to={generateLink('agent-commission')} target="_blank" className="text-decoration-none text-primary">
                            Agent Commissions
                        </Link>                        
                        <span className='text-dark pl-2'>
                            ({formatterDecimal.format(data.agent_commission_percentage)}%)
                        </span>
                        <PopoverFormula className="ml-3" id="grossProfitAgentCommissionFormula">
                            <PopoverBody>
                                <h6>Agent Commissions Formula</h6>
                                <p>Agent Commissions = (Net Sales - Commissions) * Agent Commission Percentage / 100</p>
                            </PopoverBody>
                        </PopoverFormula>
                    </div>
                    <span>({formatter.format(data.agent_commission_amount)})</span>
                </div>

                <div className="d-flex justify-content-between mb-2">
                    <div>
                        <Link to={generateLink('shipping-income')} target="_blank" className="text-decoration-none text-primary">
                            Shipping Income
                        </Link>
                        <PopoverFormula className="ml-3" id="grossProfitShippingFormla">
                            <PopoverBody>
                                <h6>Shipping Income Formula</h6>
                                <p>Shipping Income = Shipping Charge on the order</p>
                            </PopoverBody>
                        </PopoverFormula>
                    </div>
                    <span>{formatter.format(data.shipping_charges)}</span>
                </div>

                <div className="d-flex justify-content-between mb-2">
                    <div>
                        <Link to={generateLink('shipping-cost-sales')} target="_blank" className="text-decoration-none text-primary">
                            Shipping Cost (Sale)
                        </Link>
                        <PopoverFormula className="ml-3" id="grossProfitShippingCostSaleFormla">
                            <PopoverBody>
                                <h6>Shipping Cost Formula (Sale)</h6>
                                <p>Shipping Cost = Shipping cost for the order</p>
                            </PopoverBody>
                        </PopoverFormula>
                    </div>
                    <span>({formatter.format(data.shipping_rate)})</span>
                </div>

                <div className="d-flex justify-content-between mb-2">
                    <div>
                        <Link to={generateLink('shipping-cost-return')} target="_blank" className="text-decoration-none text-primary">
                            Shipping Cost (Return)
                        </Link>                        
                        <PopoverFormula className="ml-3" id="grossProfitShippingReturnSaleFormla">
                            <PopoverBody>
                                <h6>Shipping Cost Formula (Return)</h6>
                                <p>Shipping Cost = Shipping cost for the return</p>
                            </PopoverBody>
                        </PopoverFormula>
                    </div>
                    <span>({formatter.format(data.shipping_rate_return)})</span>
                </div>

                <hr />
                <div className="d-flex justify-content-between font-weight-bold mb-2">
                    <div>
                        <a href="#" className="text-decoration-none">
                            {data.gross_profit > 0 ? 'Gross profit' : 'Gross loss'}
                        </a>
                        <span className='text-dark pl-2'>({getPercentage(data.net_sales, data.gross_profit)}%)</span>
                        
                        <PopoverFormula className="ml-3" id="grossProfitFormla">
                            <PopoverBody>
                                <h6>Gross Profit Formula</h6>
                                <p>Gross profit = Net Sales + Shipping - Product Cost of goods sold - Shipping Cost (Sale) - Shipping Cost (Return) - Commission - Additional Charges - Agent Commission</p>
                            </PopoverBody>
                        </PopoverFormula>
                    </div>
                    <span>{formatter.format(data.gross_profit)}</span>
                </div>
                <div className="d-flex justify-content-between font-weight-bold mb-2">
                    <div>                        
                        <a href="#" className="text-decoration-none">
                            Gross Margin
                        </a>

                        <PopoverFormula className="ml-3" id="grossMarginFormla">
                            <PopoverBody>
                                <h6>Gross Margin Formula</h6>
                                <p>Gross Margin = 100 * Gross profit / (Net sales + Shipping)</p>
                            </PopoverBody>
                        </PopoverFormula>
                    </div>
                    <span>{formatterDecimal.format(100 * data.gross_profit / (data.net_sales + data.shipping_charges))}%</span>
                </div>                
            </div>
        </div>
    );
};

export default GrossProfitSummary;
