import React, { Fragment, useEffect, useRef, useState } from 'react';
import Breadcrumb from "../../../layout/breadcrumb";
import {
	ButtonDropdown, Card,
	CardBody, CardHeader, Col,
	Container, DropdownItem, DropdownMenu, DropdownToggle, Form,
	FormGroup, Input, Label, Nav, NavItem, NavLink,
	Row, Popover, PopoverBody, PopoverHeader, Button
} from "reactstrap";
import { Accordion } from "react-bootstrap";

import AsyncSelect from "react-select/async";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import withReducer from "../../../store/withReducer";
import PreLoader from "../../../share-components/ui/preLoader";
import Select from "react-select";
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { inventoryAdjustment, getInventorySettings, setSettings, inventorySku, skuLocations, skuDetails, } from "../store/prodcutSlice";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { mapSearchAutoSuggestion, skuSearchAutoSuggestion } from "../../../utils/mapper";
import { inventoryBinApi, skuAutoSuggestionApi } from "../../../services/manage-product/productService";
import { useHistory } from "react-router-dom";
import { inventoryAdjustmentData } from "../store/inventoryAdjustmentsSlice";
import { useDebounce } from "use-debounce";


const dataMenu = [
	{
		type: 1,
		text: 'Inventory Adjustments',
		link: '/product/adjustment-Inventory'
	},
	{
		type: 0,
		text: 'Add Inventory Adjustments',
	}
];

const customStyles = {
	control: base => ({
		...base,
		minHeight: 50,
		border: 'none',
	})
};


export const defaultValues = {
	binNumber: '',
	quantity: '',
	stock: '',
	reason: '',
	location: '',
	conditions: ''
};

export const schema = yup.object().shape({
	binNumber: yup.array().of(
			yup.string().required('Bin Number is required field').typeError('Bin Number is required field'),
	),
	/*quantity: yup.array()
	.when('$selectedStockType', (selectedStockType, schema) =>
		selectedStockType != null && selectedStockType.id === 3 ? 
		yup.array().of(
			yup.number().required('Quantity is required field').typeError('Quantity is required field')
		) : 
		yup.array().of(
			yup.number().required('Quantity is required field').typeError('Quantity is required field').min(1, 'Quantity must be greater than zero.')
		),
    ),*/
	quantity: yup.array()
        .when(['$selectedStockType', 'binNumber'], (selectedStockType, binNumber, schema) => {
            if (binNumber && binNumber.includes && binNumber.includes('DEFAULT')) {
                return yup.array().notRequired(); // Skip quantity validation if binNumber includes 'DEFAULT'
            } else {
                return selectedStockType != null && selectedStockType.id === 3 ?
                    yup.array().of(yup.number().required('Quantity is required field').typeError('Quantity is required field')) :
                    yup.array().of(yup.number().required('Quantity is required field').typeError('Quantity is required field').min(1, 'Quantity must be greater than zero.'));
            }
        }),
	stock: yup.string().required('Stock is required field').typeError('Stock is required field'),
	reason: yup.string().required('Reason is required field').typeError('Reason is required field'),
	location: yup.string().required('Location is required field').typeError('Location is required field'),
});

function AddAdjustmentInventory() {
	const ref = React.createRef();

	const productState = useSelector(({ product }) => product.product);
	const dispatch = useDispatch();
	let history = useHistory();
	const [selectedSkuData, setSelectedSkuData] = useState(null);
	const [binLocations, setBinLocations] = useState([]);
	const [savedBinLocations, setSavedBinLocations] = useState([]);
	const [tableLoading, isSetTableLoading] = useState(false);
	const [defaultSelected, setDefaultSelected] = useState([]);
	const [defaultInputValue, setDefaultInputValue] = useState();

	useEffect(() => {
		dispatch(getInventorySettings()).then(res => {
			dispatch(setSettings(res.payload))
		});

		// Get the current URL
		const currentURL = window.location.href;

		// Use regular expressions to extract the value from the URL
		const match = currentURL.match(/\/product\/add-adjustment-Inventory\/([^/]+)$/);
	
		if (match && match.length >= 1) {
			const extractedValue = match[1];
			setDefaultInputValue(extractedValue);
			handleSearchForSku(extractedValue, true);
		}else {			
			setDefaultInputValue('');
		}
		
	}, [dispatch]);

	useEffect(() => {
		setQtyLimitFn();
	}, [binLocations]);

    const [saveTotalQty, setSaveTotalQty] = useState(0);
    const [currentTotalQty, setCurrentTotalQty] = useState(0);
    const [isLimitQty, setIsLimitQty] = useState(0);
    const [isDisable, setIsDisable] = useState(false);
	const formRef = useRef();
	const [actionBtn, setActionBtn] = useState(0);
	const [isLoadingSku, setIsLoadingSku] = useState(false);
	const [skus, setSkus] = useState([]);
	const [selectedBinId, setSelectedBinId] = useState([]);
	const [isLoadingBin, setIsLoadingBin] = useState(false);
	const [bins, setBins] = useState([]);
	const [selectedStockType, setSelectedStockType] = useState(null);
	const [selectedReasonType, setSelectedReasonType] = useState(null);
	const [stockTypes, setStockTypes] = useState([]);
	const [reasonTypes, setReasonTypes] = useState([]);
	const [skuInput, setSkuInput] = useState("");
	const [debouncedValue] = useDebounce(skuInput, 1000);
	const [locations, setLocations] = useState([]);
	const [selectedLocation, setSelectedLocation] = useState(null);
	const [skuItem, setSkuItem] = useState(null);

	useEffect(() => {
		const item = {...selectedSkuData};
		const binItems = [];

		if (item && item.binLocations) {
			item.binLocations.forEach((bin, index) => {
				reset(bin);
			});
			item.binLocations.map((binData, key) => {
				if(selectedLocation && selectedLocation.id === binData.location_id){
					binItems.push({id:binData.id, name: binData.bin_number, qty: binData.qty });
					setValue(`binNumber[${key}]`, binData.bin_number);
					trigger(`binNumber[${key}]`);
				}
			});
		}

		if (selectedStockType && selectedStockType.id === 2) {
			binItems.push({ id: '', name: '', qty: '', isNew: true});
			setIsDisable(true);

			binItems.forEach((bin, index) => {
				reset(bin);
			});
		}

		setBinLocations(binItems);
		
		let binManage = productState.settings.reasonType.find((element) => element.id == 10);
		if(selectedStockType && selectedStockType.id === 1) {
			const result = productState.settings.reasonType.filter((item) => item.id == 10);
			setReasonTypes(result);
			setSelectedReasonType(binManage);			
			setValue('reason', binManage ? binManage.id : '');
			trigger('reason');
		}else{
			const result = productState.settings.reasonType.filter((item) => item.id !== 10);
			setReasonTypes(result);
			setValue('reason', '');
			setSelectedReasonType(null);
		}
		
	}, [selectedStockType, selectedLocation]);
	
	const { handleSubmit, formState, reset, register, trigger, setValue, getValues } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema),
		context: { selectedStockType: selectedStockType }
	});
	
	const { errors } = formState;
	
	const onSubmit = (data) => {
		setIsDisable(true);
		const formData = {
			"sku": selectedSkuData.sku,
			'binLocations': binLocations,
			"stock": data.stock,
			"reason": data.reason,
			"location": data.location
		};
		dispatch(inventoryAdjustmentData({...formData})).then(res=>{
			setIsDisable(false);
		    if(!res.error){
		        history.push('/product/adjustment-Inventory');
		    }
		});
	};

	const handleSearchForSku = (query) => {
		setSkuInput(query);
	};

	const handleSearch = (skuExistInURL = false) => {
		setIsLoadingSku(true);
		dispatch(inventorySku(debouncedValue)).then((res) => {
			if (!res.error) {
				setSkus(res.payload);
				if(skuExistInURL){
					let selected = res.payload.slice(0, 1);
					setDefaultSelected(selected);
				}
				setIsLoadingSku(false);
				
				if(ref.current) ref.current.blur();
			}
		});
	};

	useEffect(() => {
		if(debouncedValue){
			handleSearch();
		}
	  }, [debouncedValue]);

	const handleOnSkuChange = (skuData) => {
		reset(defaultValues);
		setSelectedStockType(null);
		setSelectedReasonType(null);
		setSelectedLocation(null);
		setLocations([]);
		setSaveTotalQty(0);

		let item = skuData[0];
		
		
		//setSelectedSkuData(item);
		//setReasonTypes(productState.settings.reasonType);
		/*const locationResult = productState.settings.locations;
		setLocations(locationResult);*/
		if(item){
			setSkuItem(item);
			dispatch(skuLocations(item.id)).then((res) => {
				if (!res.error) {
					setLocations(res.payload);
				}
			});
		}
	}

	const handleOnLocationChange = (item) => {
		setValue('location', item ? item.id : '');
		trigger('location');
		setSelectedLocation(item);
		setSaveTotalQty(0);
		setSelectedStockType(null);
		setSelectedReasonType(null);
		setValue('stock', null);

		dispatch(skuDetails({query: skuItem.id, location: item.id})).then((res) => {
			if (!res.error) {
				let skuData = res.payload;
				setSelectedSkuData(skuData);
				setReasonTypes(productState.settings.reasonType);

				let types = productState.settings.stockType;

				let saveQtySum = item ? item.available : 0;	
				if(saveQtySum == 0 && skuData && skuData.binLocations) {
					saveQtySum = skuData.binLocations.reduce(function(prev, current) {
						return prev + Number(current.qty)
					}, 0);
				}

				if(saveQtySum == 0) {
					let totalLocationQty = locations.reduce(function(prev, current) {
						return prev + +current.available
					}, 0);

					if(totalLocationQty == 0) {
						saveQtySum = skuData ? skuData.available : 0;
					}
				}

				if(saveQtySum === 0) {
					types = types.filter((element) => element.id === 2);
				}else if(saveQtySum > 0 && item.available == 0){
					types = types.filter((element) => element.id === 1);
				}
				
				if(!skuData.is_fullfilled){
					types = productState.settings.stockType.filter((element) => element.id === 2);
				}

				setStockTypes(types);				
				setSaveTotalQty(saveQtySum);
			}
		});
	}

	const setQtyLimitFn = ()=>{    
		const binItems = [...binLocations];
		
		let currentQtySum = binItems.reduce(function(prev, current) {
			return prev + +current.qty
		}, 0);

		setCurrentTotalQty(currentQtySum);
		setIsLimitQty(0);
		setIsDisable(false);

        if (getValues('stock') == 1 && saveTotalQty > 0) {
			if(saveTotalQty != currentQtySum){
				setIsLimitQty(1);
				setIsDisable(true);
			}
        }

		if(getValues('stock') === 3 && saveTotalQty < currentQtySum) {
			setIsLimitQty(1);
			setIsDisable(true);
		}
    };

	const typePromiseOption = (inputValue) => {
		return new Promise((resolve) => {
			const  bins = (selectedStockType && selectedStockType.id == 2) ? [] : binLocations.filter(item => item.name !== "").map(item => item.name);
            const data = {
                value: inputValue,
				ignoreBins: bins
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(inventoryBinApi(data)))
            });
		});
	}

	const handleOnBinChange = (binData, index) => {
		const selectedName = binData ? binData.label : '';
		const selectedId = binData ? binData.value : '';
		const binItems = [...binLocations];
		binItems[index]['name'] = selectedName;
		binItems[index]['id'] = selectedId;
		setBinLocations(binItems);
		setValue(`binNumber[${index}]`, selectedName || '');
		trigger(`binNumber[${index}]`);
		if (selectedName && binItems[index]['qty']) {
			setIsDisable(false);
		}
	}
	
	const handleOnQuantityChange = (value, index) => {
		
		const binItems = [...binLocations];
		binItems[index]['qty'] = value > 0 ? Number(value) : 0;
		setBinLocations(binItems);
		setValue(`quantity[${index}]`, binItems[index]['qty']);
		trigger(`quantity[${index}]`);		
		if (value && binItems[index]['id']) {
			setIsDisable(false);
		}
	}

	const filterBy = () => true;

	const handleAddRow = () => {
		setBinLocations([...binLocations, { id: '', name: '', qty: '', isNew: true}]);
		setValue(`binNumber[${binLocations.length}]`, '');
  		setValue(`quantity[${binLocations.length}]`, '');
	};

	const handleDeleteRow = (indexToDelete) => {
		const updatedData = [...binLocations];
		updatedData.splice(indexToDelete, 1);
		// const updatedRows = binLocations.filter((_, index) => index !== indexToDelete);
		setBinLocations(updatedData);

		updatedData.forEach((item, index) => {
			reset(item);
		});
	};

	function binLocationRow() {
        return binLocations.map((bin, index) => (
			<Row className='mb-3' key={index}>
				<Col md="4">
					{(selectedStockType.id != 1 && !bin?.isNew) ? 
					<>
						<input
							value={bin.name}
							name={`binNumber[${index}]`}
							type="text"
							{...register(`binNumber[${index}]`)}
							className={`form-control ${errors.binNumber && errors.binNumber[index] ? 'is-invalid' : ''}`}
							readOnly={true}
							
						/>
					</>
					:
					<>
						<AsyncSelect
							isClearable
							cacheOptions
							defaultOptions
							{...register(`binNumber[${index}]`)}
							value={bin.name ? {value: bin.id, label: bin.name}: null}
							loadOptions={typePromiseOption}
							onChange={(e) => {
								handleOnBinChange(e, index);
							}}
							className={` ${errors.binNumber && errors.binNumber[index] ? 'is-invalid' : ''}`}
							isDisabled={selectedStockType.id != 1 && !bin?.isNew}
						/>
						<div className="invalid-feedback">{errors.binNumber && errors.binNumber[index] ?.message}</div>
					</>}
				</Col>
				<Col md="4">
					<input
						value={bin.qty}
						name={`quantity[${index}]`}
						placeholder="Enter Quantity"
						type="number"
						{...register(`quantity[${index}]`)}
						onChange={(e) => {
							handleOnQuantityChange(e.target.value, index);
							setQtyLimitFn();
						}}
						className={`form-control ${errors.quantity && errors.quantity[index] ? 'is-invalid' : ''}`}
						readOnly={selectedStockType.id == 2 && !bin?.isNew}
						
					/>
					  <div className="invalid-feedback">{errors.quantity && errors.quantity[index]?.message}</div>
				</Col>
				{
					(selectedStockType.id !== 2 || bin?.isNew) && (
						<Col md="1">
						{binLocations.length > 1 && (
							<i
							className="fa fa-minus-circle text-danger"
							style={{ marginTop: '12px' }}
							onClick={() => handleDeleteRow(index)}
							></i>
						)}
						</Col>
					)
				}
			</Row>
		));
    }

	return (
		<Fragment>
			<Breadcrumb title="Add Inventory Adjustments" data={dataMenu} />

			<Container fluid={true} className="product-wrapper">
				<div className="product-grid">
					<div className="feature-products">
						<Row className='mb-5'>
							<Col sm="6">
								<Label>SKU</Label>
								{defaultInputValue != null ?
								<AsyncTypeahead
									filterBy={filterBy}
									id="async-example"
									isLoading={isLoadingSku}
									minLength={2}
									labelKey="sku"
									onChange={(selected) => {
										// setDefaultSelected(selected);
										handleOnSkuChange(selected)
									}}
									onSearch={handleSearchForSku}
									options={skus}
									placeholder="Search for SKU..."
									renderMenuItemChildren={(option) => (
										<>
											<span>{option.sku}</span>
										</>
									)}
									ref={ref}
									autoFocus={true}
									defaultInputValue={defaultInputValue}
									// selected={defaultSelected}

								/>
								:
								null
								}
							</Col>
							<Col sm="6">
								{locations.length > 0 ?
									<>
									<Label>Location</Label>
									<Select
										placeholder="Select Location"
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
										value={selectedLocation}
										{...register("location")}
										onChange={(e) => {
											handleOnLocationChange(e);
											
										}}
										className={`select2-filter ${errors.location ? 'is-invalid' : ''}`}
										options={locations}
									/>
									</>
								:
								null
								}
							</Col>
						</Row>
					</div>
				</div>
				{(selectedSkuData != null && selectedLocation != null)
					?
					<Card>
						{(selectedSkuData.is_fullfilled) ? <></> :
						<CardBody>
							<Row>
								<Label className="col-form-label">This SKU have some Unfulfilled orders as below:-</Label>
							</Row>
							<Row className="mt-2">
									{selectedSkuData.orderLineItems.map((lineItem, index) => 
										<span key={index}>
											{index > 0 && ', '}
											<a className="a-text-color" target="_blank" href={`/order/${lineItem.order_id}/view`}>{lineItem.order_no}</a>
										</span>
									)}
							</Row>
							<Row>
								<Label className="col-form-label text-danger"><strong>Note: </strong>You can only add stock for this SKU until you fulfill the above orders.</Label>
							</Row>
						</CardBody>
						}
						<Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
							<CardBody>
								<Row className='mb-3'>
									<Col md='4'>
										<img width="85" className="img-fluid" src={selectedSkuData.product_image} />
									</Col>
									<Col md='4'>
										<Col style={{ paddingLeft: 0 }}>
											<h6>Product Name</h6>
											<span>{selectedSkuData.product_title}</span>
										</Col>

										<Col style={{ paddingLeft: 0, marginTop: '10px' }}>
											<h6>SKU</h6>
											<span>{selectedSkuData.sku}</span>
										</Col>
										<Col style={{ paddingLeft: 0, marginTop: '10px' }}>
											<h6>Quantity</h6>
											<span>{saveTotalQty}</span>
										</Col>
									</Col>
								</Row>
								<Row>
									<Col md='4'>
										<FormGroup>
											<Label className="form-label">Stock Type</Label>
											<Select
												placeholder="Select Stock Type"
												className={`select2-filter ${errors.stock ? 'is-invalid' : ''}`}
												value={selectedStockType}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
												{...register("stock")}
												onChange={(e) => {
													setValue('stock', e ? e.id : '');
													trigger('stock');
													setQtyLimitFn();
													setSelectedStockType(e);
												}}
												options={stockTypes}
											/>
											<div className="invalid-feedback">{errors.stock?.message}</div>
										</FormGroup>
									</Col>
									<Col md='4'>
										<FormGroup>
											<Label className="form-label">Reason</Label>
											<Select
												placeholder="Select Reason"
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
												value={selectedReasonType}
												{...register("reason")}
												onChange={(e) => {
													setValue('reason', e ? e.id : '');
													trigger('reason');
													setSelectedReasonType(e);
												}}
												className={`select2-filter ${errors.reason ? 'is-invalid' : ''}`}
												options={reasonTypes}
											/>
											<div className="invalid-feedback">{errors.reason?.message}</div>
										</FormGroup>
									</Col>
								</Row>
								{selectedStockType && selectedStockType.details && (
									<Row className='mb-5'>
										<Col md='12' className='text-danger'>
											{selectedStockType.details}
										</Col>
									</Row>
								)}
								

									{selectedStockType && selectedStockType.id && (<div className='mt-3'>
										<Row className='mb-3'>
											<Col md="4">Bin Number</Col>
											<Col md="4">Quantity</Col>
											{selectedStockType.id != 3 && (
											<Col md="1">
												<i className="fa fa-plus-circle" onClick={handleAddRow}></i>
											</Col>
											)}
											
										</Row>

										{isLimitQty != 0 && <Row className='mb-3'>
											<Col md="4"></Col>
											<Col md="4">
												<div className="invalid-feedback" style={{position: 'absolute', marginTop: '-8px', fontSize:'13px',color:'#1886e7',display: 'block'}}>The total of quantities is {currentTotalQty}, it must be {saveTotalQty}</div>
											</Col>
										</Row>}
	
										{binLocationRow()}
									</div>)}

								<div className="mt-2 d-flex justify-content-end">
									<div className="p-2">
										<button onClick={() => { formRef.current.submit() }} type="submit" 
											className="btn btn-primary btn-block"
											disabled={isDisable?'disabled':''}
										>Update Stock</button>
									</div>
								</div>
							</CardBody>
						</Form>
					</Card>
					: ""}


			</Container>
		</Fragment>
	)
}

export default AddAdjustmentInventory