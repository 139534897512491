
import React, {Fragment, useEffect, useRef, useState} from 'react';
import {Col, FormGroup, Label, Row, Container, Card, Form, CardBody, Button} from "reactstrap";
import Switch from "react-switch";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import Breadcrumb from "../../../layout/breadcrumb";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {useDispatch, useSelector} from "react-redux";
// import { setProductSettings,getProductCreateSettings } from "../store/prodcutSlice";
import {getAllPurchaseOrderData, setPurchaseOrderData, setSearchProductDialog, editPurchaseOrderDetails, updatePurchaseOrderDetails} from "../store/purchaseOrderSlice";
import {useHistory, useParams} from "react-router-dom";
import moment from "moment";
import { toast } from 'react-toastify';
import { DateRangePicker } from 'rsuite';
// import DatePicker from "../../../share-components/ui/DateRangePicker";
import DatePicker from "react-datepicker";
import TagsInput from "react-tagsinput";
import SearchProductModal from "./searchProductModal";
import OrderProductJqx from "./orderProductJqx";
import OrderDiscount from "./orderDiscount";
import OrderShipment from "./orderShipment";
import OrderCost from './orderCost';
import {paymentCalculation} from "./setting/calculation";
import ButtonLoader from '../../../share-components/ui/buttonLoader';
import ShowOrderProductJqx from './showOrderProductJqx';
import ShowOrderPayment from './showOrderPayment';


const { afterToday } = DateRangePicker;
const dataMenu = [
    {
        type: 0,
        text: 'Products',
        link: '/product'
    },
    {
        type: 1,
        text: 'Purchase Orders',
        link: '/product/parchase-order'
    },
    {
        type: 0,
        text: 'View Purchase Order',
        link: ''
    }
];

const defaultValues = {
    supplier:'',
    destination:'',
    paymentTerms:'',
    trackingNumber:'',
    shippingCarrier:'',
    estimatedArrival:'',
    supplierCurrency:'',
    referenceNumber:'',
    note:'',
    tags: [],
    ordered_products: [],
    rowCount: '',
    subTotal: '',
    discount: '',
    shipping: '',
    cost: '',
};

export const schema = yup.object().shape({
    // companyName: yup.string().required('Name is required field'),
    // email: yup.string().required('Email is required field'),
    // phone_number: yup.string().required('Phone number is required field').customPhoneValidation('You must enter valid phone number'),
});


function ShowPurchaseOrder() {

    const { id } = useParams();
    const dispatch = useDispatch();
    const purchaseOrderState = useSelector(({product}) => product.purchaseOrder);
    const submitBtnSaveRef = useRef();
    const formRef = useRef();
    let history = useHistory();
    let searchProductRef = useRef();
   

    const {handleSubmit, formState, reset, control, register, setValue, handleChange, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });
    const {errors} = formState;

    useEffect(() => {
        dispatch(getAllPurchaseOrderData()).then(res => {
            dispatch(setPurchaseOrderData(res.payload))
        })
    }, [dispatch]);
    
   

    const defaultValue1 = purchaseOrderState.purchaseOrderData?purchaseOrderState.purchaseOrderData.locations.find((location) => {
        return location.id === getValues('destination');
    }):'';

    const openSearchProductDialog = () => {
        const obj = {props: {isOpen: true}, data: {searchValue: searchProductRef.current.value}}
        dispatch(setSearchProductDialog(obj));
    }

    useEffect(() => {
        if(id){
            dispatch(editPurchaseOrderDetails(id)).then(res => {
                if (!res.error){
                    reset(res.payload);
                    console.log("res",res.payload);
                    setValue('supplier',res.payload.supplier);
                    setValue('destination',res.payload.destination);
                    setValue('paymentTerms',res.payload.paymentTerms);
                    setValue('referenceNumber',res.payload.referenceNumber);
                    setValue('note',res.payload.note);
                    setValue('tags',res.payload.tags);
                    setValue('estimatedArrival',res.payload.estimatedArrival);
                    setValue('shippingCarrier',res.payload.shippingCarrier);
                    setValue('trackingNumber',res.payload.trackingNumber);
                    setValue('discount',res.payload.discount);
                    setValue('cost',res.payload.cost);
                    setValue('shipping',res.payload.shipping);
                    setValue('ordered_products',res.payload.ordered_products);
                    setValue('total',res.payload.total);
                    setValue('supplierCurrency',res.payload.supplierCurrency);
                    setValue('costAllAmounts',res.payload.cost_adjustments_total);
                    setValue('total',res.payload.total);
                };
            });
        }
    },[dispatch, id]);
   

  return (
    <Fragment>
    <Breadcrumb title="View Purchase Order" data={dataMenu}/>
        <Container fluid={true} className="product-wrapper">
           
                <Form >

                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label className="form-label">Supplier</Label>
                                            <Select 
                                                getOptionLabel={(option) => option.companyName}
                                                // defaultValue={getValues('supplier')}
                                                value={getValues('supplier')}
                                                getOptionValue={(option) => option.id} 
                                                {...register("supplier")}
                                                onChange={(e) => {
                                                    setValue('supplier', e);
                                                    trigger('supplier');
                                                }} className={` ${errors.supplier ? 'is-invalid' : ''}`}
                                                options={purchaseOrderState.purchaseOrderData.supplier}
                                                isDisabled={true}
                                                placeholder="Select Supplier"
                                            />
                                            <div className="invalid-feedback">{errors.supplier?.message}</div>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label className="form-label">Destination</Label>
                                        <Select
                                            placeholder="Select Destination"
                                            getOptionLabel={(option) => option.name}
                                            value={getValues('destination')} 
                                            getOptionValue={(option) => option.id}
                                            {...register("destination")}
                                            onChange={(e) => {
                                                setValue('destination', e);
                                                trigger('destination');
                                            }}
                                            className={`${errors.destination ? 'is-invalid' : ''}`}
                                            options={purchaseOrderState.purchaseOrderData.locations}
                                            isDisabled={true}
                                        />
                                        <div className="invalid-feedback">{errors.destination?.message}</div>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label className="form-label">Payment terms</Label>
                                        <Select 
                                            getOptionLabel={(option) => option.name}
                                            value={getValues('paymentTerms')}
                                            getOptionValue={(option) => option.id} {...register("paymentTerms")}
                                            onChange={(e) => {
                                                setValue('paymentTerms', e);
                                                trigger('paymentTerms');
                                            }} className={` ${errors.paymentTerms ? 'is-invalid' : ''}`}
                                            options={purchaseOrderState.purchaseOrderData.paymentTerms}
                                            isDisabled={true}
                                            placeholder="Select payment"
                                        />
                                        <div className="invalid-feedback">{errors.paymentTerms?.message}</div>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label className="form-label">Supplier currency</Label>
                                        <Select getOptionLabel={(option) => option.name}
                                            defaultValue={getValues('supplierCurrency')}
                                            value={getValues('supplierCurrency')} 
                                            getOptionValue={(option) => option.id} {...register("supplierCurrency")}
                                            onChange={(e) => {
                                                setValue('supplierCurrency', e);
                                                trigger('supplierCurrency');
                                            }} className={` ${errors.supplierCurrency ? 'is-invalid' : ''}`}
                                            options={purchaseOrderState.purchaseOrderData.supplierCurrency}
                                            isDisabled={true}
                                            placeholder="Select Currency"
                                        />
                                        <div className="invalid-feedback">{errors.supplierCurrency?.message}</div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Row>
                        <Col sm="8">
                            <Card>
                                <CardBody>
                                <h6 style={{marginBottom: '10px'}}>Add products</h6>
                                    <div className="form-row mb-3 ">
                                        <Col md="12">
                                            <FormGroup>
                                                <Label className="col-form-label">Products</Label>
                                                <Row>
                                                    <Col md="10 input-with-icon pr-1">
                                                        <div>
                                                            <FormGroup className="m-0">
                                                                <i className="fa fa-search"></i>
                                                                <input name="searchProduct" type="text"
                                                                        placeholder="search Products"
                                                                        ref={ref => {
                                                                            searchProductRef.current = ref;
                                                                        }}
                                                                        readOnly
                                                                        onKeyUp={openSearchProductDialog}
                                                                        className={`form-control`}/>
                                                            </FormGroup>
                                                        </div>
                                                    </Col>
                                                    <Col md="2 pl-0">
                                                        <Button 
                                                            color="primary" 
                                                            type="button"
                                                            disabled={true}
                                                            className="btn btn-sm btn-primary btn-block"
                                                            onClick={openSearchProductDialog}
                                                            style={{height: 38}}>Browse
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <ShowOrderProductJqx 
                                                            readOnly={true}
                                                            setValue={setValue}
                                                            getValues={getValues}
                                                            trigger={trigger}
                                                            orderedProducts={getValues('ordered_products')}
                                                            rowCount={getValues('rowCount')}
                                                        ></ShowOrderProductJqx>
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                        </Col>
                                    </div>
                                </CardBody>
                            </Card>

                            {/* {getValues('ordered_products').length > 0 &&  */}
                            <><Row>
                                    <Col sm="12">
                                        <ShowOrderPayment setValue={setValue} getValues={getValues}
                                                      trigger={trigger} total={getValues('total')}></ShowOrderPayment>
                                        {/* <div style={{color:'red',float:"right"}}>{errors.shipping?.message}</div> */}
                                    </Col>
                                </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Card>
                                                <CardBody>
                                                    <div className="float-right" style={{display:"flex"}}>
                                                        <div className="checkbox checkbox-dark mr-5">
                                                            <input id="is_mark_paid" type="checkbox"  onChange={(e)=>{
                                                                      setValue('is_mark_paid',e.target.checked)
                                                            }} 
                                                            disabled={true}
                                                            defaultChecked={getValues('is_mark_paid')}/>
                                                            <Label for="is_mark_paid">Mark As Paid</Label>
                                                        </div>
                                                        {/* <ButtonLoader  color="primary"  value="1"	type="submit"  btntext="Create Draft Order"></ButtonLoader> */}
                                                    </div>

                                                </CardBody>
                                            </Card>

                                        </Col>
                                    </Row>
                                </>
                                {/* } */}

                        </Col>
                        <Col md="4">
                            <Card>
                                <CardBody>
                                    <h6 style={{marginBottom: '10px'}}>Additional details</h6>

                                    <Row>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label className="form-label">Reference number</Label>
                                                <input readOnly name="referenceNumber" type="text" className={`form-control ${errors.referenceNumber ? 'is-invalid' : ''}`} placeholder="Enter Reference No" {...register('referenceNumber')}  />
                                                <div className="invalid-feedback">{errors.referenceNumber?.message}</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label className="form-label">Note to supplier</Label>
                                                <input readOnly name="note" type="text" className={`form-control ${errors.note ? 'is-invalid' : ''}`} placeholder="Enter Note" {...register('note')}  />
                                                <div className="invalid-feedback">{errors.note?.message}</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label className="col-form-label">Tags</Label>
                                                <TagsInput className="form-control tag-input-custom"
                                                    onlyUnique={true} value={getValues('tags') || []}
                                                    disabled={true}
                                                    onChange={(value) => {
                                                        // alert(JSON.stringify(value));
                                                        setValue('tags', value)
                                                        trigger('tags');
                                                        // combineTags();
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            <Card>
                                <CardBody>
                                    <h6 style={{marginBottom: '10px'}}>Shipment details</h6>

                                    <Row>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label className="form-label">Estimated arrival</Label>
                                                    <DatePicker name="estimatedArrival"
                                                        minDate={new Date()}
                                                        selected={getValues('estimatedArrival') ? new Date(getValues('estimatedArrival')) : null}
                                                        className={`form-control digits ${errors.estimatedArrival ? 'is-invalid' : ''}`}
                                                        dateFormat="dd-MM-yyyy" onChange={(date) => {
                                                        setValue('estimatedArrival', date);
                                                        trigger('estimatedArrival')
                                                    }}
                                                    readOnly />
                                                    <div className="invalid-feedback">{errors.estimatedArrival?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label className="form-label">Shipping carrier</Label>
                                                <input readOnly name="shippingCarrier" type="text" className={`form-control ${errors.shippingCarrier ? 'is-invalid' : ''}`} placeholder="Enter shipping Carrier" {...register('shippingCarrier')}  />
                                                <div className="invalid-feedback">{errors.shippingCarrier?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label className="form-label">Tracking number</Label>
                                                <input readOnly name="trackingNumber" type="text" className={`form-control ${errors.trackingNumber ? 'is-invalid' : ''}`} placeholder="Enter Tracking Number" {...register('trackingNumber')}  />
                                                <div className="invalid-feedback">{errors.trackingNumber?.message}</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>

                </Form>

                <SearchProductModal setValue={setValue} getValues={getValues} trigger={trigger}></SearchProductModal>
                <OrderDiscount orderSetValue={setValue} orderTrigger={trigger}
                           orderGetValues={getValues}></OrderDiscount>
                <OrderShipment purchaseOrderState={purchaseOrderState} orderSetValue={setValue} orderTrigger={trigger}
                           orderGetValues={getValues}></OrderShipment>
                <OrderCost  purchaseOrderState={purchaseOrderState} orderSetValue={setValue} orderTrigger={trigger}
                           orderGetValues={getValues} ></OrderCost>


        </Container>
    </Fragment>
  )
}

export default ShowPurchaseOrder