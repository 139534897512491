import * as yup from "yup";
import {formatterDecimal, formatter} from "./common";

export const mapUserRoleResponseToTable = response => {
    return response.map(res => {
        return {
            id: res.id,
            email: res.email,
            name: res.name,
            roleName: res.role?.name,
            role: res.role
        };
    });
};

export const mapRoleResponseToTable = response => {
  return response.map(res => {
      return {
          id: res.id,
          name:res.name,
          status: res.status
      }
  });
};

export const mapUserRolesToTable = response => {
    const results = [];
    results.push({
        text: 'SideMenu',
        datafield: 'sidemenu_title',
        displayfield: 'sidemenu',
        width: '25%',
        type: 'type',
        columntype: 'text',
        editable: false
    });

    const secondArray = response.map(res => {
        return {
            id: res.id,
            text: res.name,
            datafield: res.id,
            type: res.type,
            width: '18.75%',
            columntype: 'checkbox',
            editable:res.name !== "Super Admin"
        };
    });

    return results.concat(secondArray);
};

export const mapDataFieldListToTable = response => {
    const results = [];
    results.push({
        name: 'sidemenu',
        type: 'string'
    });

    results.push({
        name: 'sidemenu_title',
        type: 'string'
    });

    const secondArray = response.map(() => {
        return {
            name: 'title',
            type: 'checkbox',
            value: 'sidemenu'
        };
    });

    return results.concat(secondArray)
};

export const mapAgentResponseToTable = response => {
    return response.map(res => {
        return {
            id: res.id,
            name: res.name,
            email: res.email,
            commission_percentage: res.commission,
            mobile_number: res.mobile_number,
            seller: res.sellers
        }
    });
};

export const mapSearchAutoSuggestion = async response => {

    return response.then(function (result) {
        return result.response.map(res=> {
            return {
                label: res.name,
                value: res.id
            }
        })
    });
};
export const mapPartnerAutoSuggestion = async response => {

    return response.then(function (result) {
        return result.response.map(res=> {
            return {
                label: res.name,
                value: res.id
            }
        })
    });
};

export const partnerSelectAutoSuggestion = async response => {
    return response.then(function (result) {
        return result.response.map(res=> {
            return {
                label: res.name +' ('+ res.email +')',
                value: res.id
            }
        })
    });
};

export const mapIdSearchAutoSuggestion = async response => {

    return response.then(function (result) {
        return result.response.map(res=> {
            return {
                name: res.name,
                id: res.id
            }
        })
    });
};

export const mapSellerRoleResponseToTable = response => {
    return response.map(res => {
        return {
            id: res.id,
            user:res.user,
            brandName: res.brandName,
            brandList: res.brandList,
            shopName: res.shopName,
            sellerName: res.sellerName,
            phone: res.phone,
            email: res.email,
            date: res.date,
            is_active: res.is_active
        };
    });
};


export const mapOrderResponseToTable = (response,data) => {

    return response.map((res,index) => {
        return {
            count:index,
            id: res.id,
            orderName: res.orderName,
            customer: res.customer,
            isFullFilled: res.isFullFilled,
            isCanceled: res.is_canceled,
            orderDate: res.orderDate,
            paymentStatus: res.paymentStatus,
            total: res.total,
            payment: res.payment,
            isPackingSlip:(data.packingSlip).includes(res.id),
            isPackingTicket:(data.pickingTicket).includes(res.id),
            isChecked:(data.orderIds).includes(res.id),
            btnColor:(res.btnStyle).toString(),
            fulfillmentStatus:res.fulfillmentStatus,
            country:res.country,
            seller:res.seller,
            orderType: res.order_type,
            dispatch_date: res.dispatch_date,
            dispatch_status: res.dispatch_status,
            sellerList:res.sellerList,
            error_messages: res.error_messages,
            referenceOrder: res.reference_order,
            referenceOrderId: res.reference_id,
        };
    });
};

export const mapCollabOrderResponseToTable = (response, data) => {
  return response.map((res, index) => {
    return {
      count: index,
      id: res.id,
      orderName: res.orderName,
      orderDate: res.orderDate,
      verificationStatus: res.verificationStatus,
      verifiedBy: res.verifiedBy ? res.verifiedBy : '-',
      collabOrderName: res.collabOrderName ? res.collabOrderName : '-',
      paymentStatus: res.paymentStatus ? res.paymentStatus : '-',
      total: res.total ? res.total : '-',
    };
  });
};

export const mapReturnResponseToTable = (response, data) => {
    return response.map((res,index) => {
        return {
            count:index,
            id: res.id,
            sku: res.sku,
            orderName: res.orderName,
            seller: res.seller,
            date: res.date,
            orderRefundDate: res.orderRefundDate,
            total: res.total,
            reason: res.reason,
            customer_reason: res.customer_reason,
            status: res.status,
            type: res.type,
            status_id: res.status_id,
            is_return: res.is_return,
            is_declined: res.is_declined,
            is_restock: res.is_restock,
            is_admin_declined:res.is_admin_declined,
            ordered_quantity:res.ordered_quantity,
            customer_return_quantity:res.customer_return_quantity,
            approved_return_quantity:res.approved_return_quantity,
            is_penalty: res.is_penalty?res.is_penalty:(data.penatly).includes(res.id),
        };
    });
};

export const mapReturnRestockResponseToTable = (response) => {
  return response.map((res, index) => {
    let result = {
      id: res.custom_id,
      _id: res.id,
      isChecked: false,
      completeRestock: false,
      orderName: res.order_name,
      sku: res.sku,
      count: index,
      custom_id: res.custom_id,
      quantity: res.quantity,
      isRestock: false,
      binLocations: res.bin_locations,
      restock_history: res.restock_history,
    };
    return result;
  });
};
// export const mapRestockReportResponseToTable = (response) => {
//   return response.map((res, index) => {
//     let result = {
//       // id: res.custom_id,
//       start_date: res.startDate,
//       end_date: res.endDate,
//       order: res.order,
//       order_id: res.order_id,
//       sku: res.sku,
//       restockState: res.restockState,
//       custom_id: res.custom_id,
//       restock_by: res.restock_by,
//       orderQuantity: res.orderQuantity,
//       quantity: res.quantity,
//       approvedQuantity: res.approvedQuantity,
//     };
//     return result;
//   });
// };

export const binLocationsResponseToTable = response => {
    return response.map(res => {
        return {
            id: res.id,
            name: res.name,
            location_type: res.location_type,
            bin_location_type_id: res.bin_location_type_id,
            capacity: res.capacity,
        }

    });
};

export const binLocationTypeResponseToTable = response => {
    return response.map(res => {
        return {
            id: res.id,
            title: res.title,
            capacity: res.capacity,
            width: res.width,
            length: res.length,
            height: res.height,
            imageURL: res.image_url
        }

    });
};

export const mapReturnRequestResponseToTable = response => {
    return response.map((res,index) => {
        return {
            count:index,
            id: res.id,
            sku: res.sku,
            orderName: res.orderName,
            customerName: res.customerName,
            date: res.date,
            status: res.status,
            status_id: res.status_id,
            is_return: res.is_return,
            type: res.type,
            reason: res.reason,
            order_id: res.order_id
        };
    });
};

export const mapOrderReturnResponseToTable = response => {
    return response.map((res,index) => {
        return {
            count:index,
            id:res.id,
            productId: res.product_id,
            productVariantId: res.product_variant_id,
            quantity: res.quantity,
            returnTotal: res.return_total,
            isRestock: false,
            isPack: res.is_pack,
            rowExpand: res.is_pack,
            availableQuantity: res.available_quantity,
            name: res.name,
            price: res.price,
            packSinglePrice: res.pack_single_price,
            image: res.image,
            total: res.total_price,
        };
    });
};

export const mapInventoryAdjustmentResponseToTable = response => {
    return response.map((res,index) => {
        return {
            count:index,
            id:res.id,
            sku: res.sku,
            location: res.location,
            stock_type: res.stock_type,
            reason_id: res.reason_id,
            created_at: res.created_at,
            created_by: res.created_by,
            rowExpand: 1,
            inventory_changes: res.inventory_changes,
        };
    });
};

export const mapOrderReasonsResponseToTable = response => {
    return response.map((res,index) => {
        return {
            value:res.id,
            label:res.name,
        };
    });
};

export const mapSellerUpdateForm = (response) => {
    return {
      name: response.name,
      shopName: response.shopName,
      email: response.email,
      number: response.number,
      pdCity: response.pdCity,
      pdCountry: response.pdCountry,
      isPremium: response.isPremium,
      sellerId: response.sellerId,
      userID: response.userID,
      commission: response.commission,
      dial_code: response.dial_code,
      location: Number(response.location),
  
      storeDescriptionHeading: response.storeDescriptionHeading,
      storeDescription: response.storeDescription,
      sellerDescriptionHeading: response.sellerDescriptionHeading,
      sellerDescription: response.sellerDescription,
      deliveryDays: response.deliveryDays,
      deliveryMethodName: response.deliveryMethodName,
      deliveryTime: response.deliveryTime,
      otherInformationHeading: response.otherInformationHeading,
      otherPolicyHeading: response.otherPolicyHeading,
      returnDays: response.returnDays,
      refundDays: response.refundDays,
      sellerSince: response.sellerSince,
  
      minOrder: response.minOrder,
      deliveryInfo: response.deliveryInfo,
      chargeTax: response.chargeTax,
      socialFacebook: response.socialFacebook,
      socialTwitter: response.socialTwitter,
      socialInstagram: response.socialInstagram,
      instagramWidgetCode: response.instagramWidgetCode,
      shippingOption: response.shippingOption.toString(),
  
      returnCancellationPolicyHeading: response.returnCancellationPolicyHeading,
      sellerProfileHeading: response.sellerProfileHeading,
      sellerProfileLink: response.sellerProfileLink,
      sellerShopHeading: response.sellerShopHeading,
      sellerShopLink: response.sellerShopLink,
      brandSizeChartHeading: response.brandSizeChartHeading,
      brandSizeChartLink: response.brandSizeChartLink,
      sizeChartHeading: response.sizeChartHeading,
      sizeChartLink: response.sizeChartLink,
      promotionHeading: response.promotionHeading,
      promotionLink: response.promotionLink,
      tradeShowHeading: response.tradeShowHeading,
      tradeShowLink: response.tradeShowLink,
      designedMadeInHeading: response.designedMadeInHeading,
      designedMadeInLink: response.designedMadeInLink,
      shippingValidityHeading: response.shippingValidityHeading,
      shippingValidityLink: response.shippingValidityLink,
  
      customerFullName: response.customerFullName,
      customerMobileNumber: response.customerMobileNumber,
      customerEmail: response.customerEmail,
  
      returnsFirstAddress: response.returnsFirstAddress,
      returnsCity: response.returnsCity,
      returnsCountry: response.returnsCountry,
      returnsPostcode: response.returnsPostcode,
  
      storeName: response.storeName,
      apiKey: response.apiKey,
      secretKey: response.secretKey,
    };
};

/*export const productTypeResponseToTable = response => {
    return response.map(res => {
        return {
            id: res.id,
            name: res.name,
            tagName: res.tag_name,
            publish_at: res.publish_at!=null?1:0
        }

    });
};*/

export const productTypeResponseToTable = (response) => {
    return response.map((res) => {
      return {
          seller_brands:res.seller_brands,
          sell_brands:res.sell_brands,
          new_style_duration:res.new_style_duration,
          sell_duration:res.sell_duration,
          other_brands:res.other_brands,
          targetAudience:res.target_audience,
          businessModels:res.business_model,
          manufacturingPatterns:res.manufacturing_pattern,
          other_marketplace:res.other_marketplace,
      }
  });
}

export const tagSettingResponseToTable = response => {
    return response.map(res => {
        return {
            id: res.id,
            name: res.name,
            tagName: res.tags_name,
            tooltip_list: res.tags_name_tooltip,
        }

    });
};

export const productSizeResponseToTable = response => {
    return response.map(res => {
        return {
            id:res.id,
            name: res.name,
            // position: res.position,
            publish_at: res.publish_at!=null?1:0
        }
    })
};

export const mapSettingResponseToTable = response => {
    return response.map(res => {
        return {
            id:res.id,
            name:res.name,
            hs_code:res.hs_code,
            publish_at: res.publish_at!=null?1:0
        }
    });
};

export const mapColourSettingToTable = response => {
    return response.map(res => {
        return {
            id:res.id,
            name:res.name,
            netsuite_id:res.netsuite_id,
            color_code:res.color_code,
            publish_at: res.publish_at!=null?1:0,
            priority: res.priority,
            priority_val: res.priority ? "True" : "False",
            is_parent: res.is_parent == "",
            main_color: res.main_color,
            main_color_label:res.main_color_label,
            tags: res.tags
        }
    });
};

export const mapCategoriesResponseToTable = response =>{
    return response.map(res=> {
        return {
            id:res.id,
            name:res.name,
            type:res.type,
            parent_category:res.parent_category,
            is_parent: res.is_parent,
            type_text: res.type_text,
            parent_text: res.is_parent?"Yes":"No",
            default_config:res.dress_configuration
        }
    });
};

export const mapStyleResponseToTable = response =>{
    return response.map(res => {
        return {
            id:res.id,
            name:res.name,
            tag_name:res.tag_name,
            publish_at: res.publish_at!=null?1:0,
            category: res.category,
            category_text:res.category_text,
        }
    });
};

export const mapCompositionResponseToTable = response => {
    return response.map(res=> {
        return {
            id:res.id,
            name:res.name,
            fabric_percentage:res.fabric_percentage,
            composition_group: res.composition_group,
            composition_group_text: res.composition_group_text,
            size_matrix_response: res.size_matrix_response,

        }
    });
};

export const mapSizeMatrixConfigResponseToTable = response => {
    return response.map(res=> {
        return {
            id: res.id,
            name: res.name,
            composition_group: res.composition_group

        }
    });
};

export const mapSellerStaffUserToTable = response => {
    return response.map(res=> {
        return {
            id: res.id,
            name: res.name,
            email: res.email

        }
    });
};

export const mapStaffSeller = response => {
    return {
        name: response.name,
        email: response.email,
        id: response.id,
        permission: []
    }
}

export const mapShippingZoneResponseToTable = response => {
    return response.map(res=> {
        return {
            id: res.id,
            name:res.zone_name,
            country_count: res.country_count,
            seller_name: res.seller_name
        }
    });
}

export const mapShippingRateResponseToTable = response => {
    return response.map(res => {
        return {
            id:res.id,
            rate_name:res.name,
            min:res.min,
            max:res.max,
            shipping_price: res.ship_price
        }
    });
}

export const mapSellerLeadResponseToTable = response => {
    return response.map(res=>{
        return {
            id:res.id,
            name: res.company_name,
            email:res.company_email,
            mobile_number: res.mobile_number
        }
    })
}

export const mapSellerLeadResponseToCompanyProfileForm = res => {
        return {
            id: res.id,
            name: res.name,
            buyer_type: String(res.buyer_type),
            trading_name: res.trading_name,
            is_registered: String(res.is_registered),
            country_id: res.country_id,
            phone_number:res.phone_number,
            registration_number:res.registration_number,
            eori_number:res.eori_number,
            vat_number:res.vat_number,
            company_name:res.company_name,
            email:res.company_email,
            website:res.website,
            line_of_business:res.line_of_business,
            incorporated_date:res.incorporated_date,
            is_vattable:String(res.is_vattable),
            customer_representative:res.customer_representative,
            dial_code:res.dial_code,
        }
}

export const mapSellerLeadResponseToBusinessDetailsForm = res => {
    return {
        address_line_1:res.address_line_1_1,
        address_line_2:res.address_line_1_2,
        town_1:res.town_1,
        state_1:res.state_1,
        postcode_1:res.zip_1,
        address_country_1:res.address_country_1,
        physical_store:String(res.physical_store),
        shop_address_line_1:res.shop_address_line_1,
        shop_address_line_2:res.shop_address_line_2,
        shop_town_1:res.shop_town_1,
        shop_state_1:res.shop_state_1,
        shop_postcode_1:res.shop_postcode_1,
        shop_address_country_1:res.shop_address_country_1,
        shopify_id:res.shopify_address_id
    }
}

export const mapSellerLeadResponseToWearhouseDetailsForm = (res) => {
    return {
      address_line_2_1: res.address_line_2_1,
      address_line_2_2: res.address_line_2_2,
      town_2: res.town_2,
      state_2: res.state_2,
      postcode_2: res.zip_2,
      address_country_2: res.address_country_2,
    };
  };
  

export const mapSellerLeadResponseToContactDetailsForm = res => {
    return {
        contact_name:res.contact_name,
        title:res.title,
        first_name:res.first_name,
        last_name:res.last_name,
        mobile:res.mobile,
        whatssapp:res.whatssapp,
        designations:res.designation,
        contact_email:res.contact_email,
        confirm_email:res.contact_email,
        whatssappSame: ((res.mobile==res.whatssapp) && (res.whatssapp!=null))
    }
}

export const mapSellerLeadResponseToAdditionalDetailsForm = res => {
    return {
        seller_brands:res.seller_brands,
        sell_brands:res.sell_brands,
        new_style_duration:res.new_style_duration,
        sell_duration:res.sell_duration,
        other_brands:res.other_brands,
        targetAudience:res.target_audience,
        businessModels:res.business_model,
        manufacturingPatterns:res.manufacturing_pattern,
        other_marketplace:res.other_marketplace,
    }
}

export const mapSellerLeadResponseToExtraDetailsForm = res => {
    return {
        sources:res.how_know,
        averageSpends:String(res.spend_per_piece),
        averageSales:String(res.monthly_sales),
        womenCategories:res.women_categories,
        menCategories:res.men_categories,
        childCategories:res.children_categories,
        giftCategories:res.gift,
        sourceReason:res.sourceReason,
        agree_tc:res.agree_tc,
    }
}

export const mapBuyerLeadResponseToTable = response => {
    return response.map(res=>{
        return {
            id:res.id,
            name: res.name,
            email:res.email,
            date:res.date,
            mobile_number: res.mobile_number,
            whatsapp_number: res.whatsapp_number,
            buyer_type: res.buyer_type,
            country: res.country,
        }
    })
}

export const mapBackInStockResponseToTable = (response, data) => {
    return response.map(res=> {
        return {
            id: res.id,
            created_date: res.created_date,
            image: res.image,
            product: res.product,
            customer: res.customer,
            qty: res.qty,
            selected: (data.idArray)?.includes(res.id),
            code: res.code,
            customer_id: res.customer_id,
            status: res.status,
        };
    });
};

export const mapLookBookResponseToTable = response => {
    return response.map((res,index) => {
        return {
            title:res.title,
            subTitle: res.sub_title,
            heading: res.heading,
            email: res.email,
            contactName: res.contact_name,
            template: res.template,
            id: res.id,
            created_at:res.created_at
        };
    });
};

export const mapPartnerResponseToTable = response => {
    return response.map(res => {
        return {
            id: res.id,
            partnerName: res.partnerName,
            phone: res.phone_number,
            email: res.email,
            status: res.status,
            isFormReturn: res.is_return_form
        };
    });
};

export const mapPartnerUpdateForm = response => {
    return {
        name: response.name,
        email: response.email,
        number: response.phone_number,
        pdCountry: response.pdCountry,
        format: response.format,
        customer: response.customer,
        is_return_form: response.is_return_form,
        status: response.status,
        dial_code: response.dial_code,
        channelId:response.channelId,
        channelName:response.channelName,
        returnUrl:response.returnUrl,
        logoImage:response.logoImage,
        orderedVia:response.orderedVia,
        howMakeReturnUrl:response.howMakeReturnUrl,
        returnContent:response.returnContent,
        isPartner: response.isPartner,

    };
};

export const binSearchAutoSuggestion = async response => {

    return response.then(function (result) {
        return result.response.map(res=> {
            return {
                label: res.name,
                value: res.name
            }
        })
    });
};

export const mapSaleOfferResponseToTable = response => {
    return response.map((res, index) => {
        return {
            id: res.id,
            sale_title: res.sale_title,
            start_date: res.start_date,
            end_date: res.end_date,
            status: res.status_format,
            no_products: res.no_product,
            created_at: res.created_at_format
        };
    });
};

export const productPlatformResponseToTable = response => {
    return response.map(res => {
        return {
            id: res.id,
            name: res.name,
        }

    });
};

export const mapRestockReportResponseToTable = (response) => {
  return response.map((res, index) => {
    let result = {
      id: res.custom_id,
      return_requested_qty: res.return_requested_qty,
      restock_history: res.restock_history,
      restocked_qty: res.restocked_qty,
      sku: String(res.sku),
      order_number: String(res.order_number),
      restock_status: String(res.restock_status),
      remaining_qty: res.remaining_qty,
      orderNumber: String(res.orderNumber),
      restockedBy: String(res.restockedBy),
      restockStatus: String(res.restockStatus),
      lastRestockAt: res.last_restock_at
    };
    return result;
  });
};

export const mapRestockReportSearchAutoSuggestion = async (response) => {
  return response.then(function (result) {
    return result.response.map((res) => {
      return {
        label: res.label,
        value: res.value,
        // order_number: res.order_number,
        // sku: res.sku,
        // restock_history: res.restock_history,
        // restock_status: res.restock_status,
        // restock_by: res.restock_by,
        // restockedBy: String(res.restockedBy),
      };
    });
  });
};

export const mapInventoryReportResponseToTable = (response) => {
  const formatter = new Intl.NumberFormat('en-UK', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return response.map((res, index) => {
    let result = {
      id: res.id,
      product_title: res.title,
      sku: String(res.sku),
      grade: res.grade,
      brand: res.brand,
      seller: res.seller,
      cost: formatter.format(res.cost),
      price: formatter.format(res.price),
      sale_price: formatter.format(res.sale_price),
      quantity: res.quantity,
      created_at: res.created_at,
      updated_at: res.updated_at,
      inventory_value: formatter.format(res.inventory_value),
      bin_locations: res.bin_locations
    };
    return result;
  });
};

