import React, {Fragment, useEffect, useRef, useState} from 'react';
import {Col, FormGroup, Label, Row, Container, Card, Form, CardBody} from "reactstrap";
import Switch from "react-switch";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import Breadcrumb from "../../../layout/breadcrumb";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import { setProductSettings,getProductCreateSettings } from "../store/prodcutSlice";
import {getAllCountries, getAllSuppliers,setCountries, addSuppliersDetails, getSupplier, updateSuppliersDetails} from "../store/supplierSlice";
import {useHistory, useParams} from "react-router-dom";
import moment from "moment";

const dataMenu = [
    {
        type: 0,
        text: 'Products',
        link: '/product'
    },
    {
        type: 1,
        text: 'Supplier',
        link: '/product/supplier'
    },
    {
        type: 0,
        text: 'Update Supplier',
        link: ''
    }
];

const defaultValues = {
    companyName:'',
    country:'',
    address:'',
    apartment:'',
    city:'',
    postcode:'',
    contactName:'',
    email:'',
    phone_number:''
};

export const schema = yup.object().shape({
    companyName: yup.string().required('Name is required field'),
    country: yup.object().required('Country is required field').typeError('Please select Country'),
    email: yup.string().required('Email is required field'),
    phone_number: yup.string().required('Phone number is required field').customPhoneValidation('You must enter valid phone number'),
});

const phoneRegExp =/^[0-9]{10}$/
yup.addMethod(yup.string, "customPhoneValidation", function (errorMessage) {
    return this.test(`test-card-length`, errorMessage, function (value) {
        if(value){
            return  value.match(phoneRegExp)?true:false;
        }
        return  false;
    });
});
function UpdateSupplier() {

    const { id } = useParams();
    const dispatch = useDispatch();
    const productState = useSelector(({product}) => product.product.productSettings);
    const [dialCode, setDialCode] = useState('');
    const submitBtnSaveRef = useRef();
    const formRef = useRef();
    let history = useHistory();

    const {handleSubmit, formState, reset, control, register, setValue, handleChange, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });
    const {errors} = formState;

    
    useEffect(() => {
        dispatch(getAllCountries()).then(res => {
            dispatch(setProductSettings(res.payload))
        })
    }, [dispatch]);
    
    const onSubmit = (data) => {
        const set={
            "id":id,
            "companyName" : data.companyName,
            "country":data.country.id,
            "address":data.address,
            "apartment":data.apartment,
            "city":data.city,
            "postcode":data.postcode,
            "contactName":data.contactName,
            "email":data.email,
            "phone_number":data.phone_number,
        }
        // submitBtnSaveRef.current.disabled = true;
        dispatch(updateSuppliersDetails({...set})).then(res=>{
            if(!res.error){
                history.push('/product/supplier');
            }
        });
        // submitBtnSaveRef.current.disabled = false;
    };

  
    useEffect(() => {
        if(id){
            dispatch(getSupplier(id)).then(res => {
                if (!res.error){
                    setValue('companyName',res.payload.companyName);
                    setValue('country',res.payload.country);
                    setValue('address',res.payload.address);
                    setValue('apartment',res.payload.apartment);
                    setValue('city',res.payload.city);
                    setValue('postcode',res.payload.postcode);
                    setValue('contactName',res.payload.contactName);
                    setValue('email',res.payload.email);
                    setValue('phone_number',res.payload.phone_number);
                    setDialCode(res.payload.dial_code)

                    
                };
            });
        }
    },[dispatch]);


  return (
    <Fragment>
    <Breadcrumb title="Update Supplier" data={dataMenu}/>
        <Container fluid={true} className="product-wrapper">
            <Card>
                <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                    <CardBody>
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label className="form-label">Company Name<span style={{color:"red"}}>*</span></Label>
                                    <input name="companyName" type="text" className={`form-control ${errors.companyName ? 'is-invalid' : ''}`} placeholder="City Goddess Ltd" {...register('companyName')}  />
                                    <div className="invalid-feedback">{errors.companyName?.message}</div>
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label className="form-label">Country / Region<span style={{color:"red"}}>*</span></Label>
                                    <Select 
                                        getOptionLabel={(option) => option.name}
                                        value={getValues('country')}
                                        classNamePrefix={"my-custom-react-select"}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 100 }) }}
                                        getOptionValue={(option) => option.id} {...register("country")}
                                        onChange={(e) => {
                                            setValue('country', e);
                                            trigger('country');
                                            setDialCode(e.dial_code);
                                        }} className={` ${errors.country ? 'is-invalid' : ''}`}
                                        options={productState.countries}
                                    />
                                    <div className="invalid-feedback">{errors.country?.message}</div>
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label className="form-label">Address</Label>
                                    <input name="address" type="text" className={`form-control ${errors.address ? 'is-invalid' : ''}`} placeholder="Address" {...register('address')}  />
                                    <div className="invalid-feedback">{errors.address?.message}</div>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label className="form-label">Apartment, Suite, etc</Label>
                                    <input name="apartment" type="text" className={`form-control ${errors.apartment ? 'is-invalid' : ''}`} placeholder="Apartment, Suite, etc" {...register('apartment')}  />
                                    <div className="invalid-feedback">{errors.apartment?.message}</div>
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label className="form-label">City</Label>
                                    <input name="city" type="text" className={`form-control ${errors.city ? 'is-invalid' : ''}`} placeholder="City" {...register('city')}  />
                                    <div className="invalid-feedback">{errors.city?.message}</div>
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label className="form-label">Postcode</Label>
                                    <input name="postcode" type="text" className={`form-control ${errors.postcode ? 'is-invalid' : ''}`} placeholder="Postcode" {...register('postcode')}  />
                                    <div className="invalid-feedback">{errors.postcode?.message}</div>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label className="form-label">Contact Name</Label>
                                    <input name="contactName" type="text" className={`form-control ${errors.contactName ? 'is-invalid' : ''}`} placeholder="Contact Name" {...register('contactName')}  />
                                    <div className="invalid-feedback">{errors.contactName?.message}</div>
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label className="form-label">Email<span style={{color:"red"}}>*</span></Label>
                                    <input name="email" type="email" className={`form-control ${errors.email ? 'is-invalid' : ''}`} placeholder="john.doe@citygoddess.co.uk" {...register('email')}  />
                                    <div className="invalid-feedback">{errors.email?.message}</div>
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                            <FormGroup>
                                <Label className="form-label">Phone Number<span style={{color:"red"}}>*</span></Label>
                                <FormGroup className="input-group">
                                    <span className="input-group-text">{dialCode}</span>
                                    <input name="phone_number" type="number" onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                            }
                                                        }}
                                            className={`form-control ${errors.phone_number ? 'is-invalid' : ''}`}
                                            placeholder="Phone Number" {...register('phone_number')}  />
                                    <div className="invalid-feedback">{errors.phone_number?.message}</div>
                                </FormGroup>
                            </FormGroup>
                            </Col>
                        </Row>

                        <div className="mt-2 d-flex justify-content-end">
                            <div className="p-2">
                                <button 
                                ref={submitBtnSaveRef} 
                                onClick={()=>{formRef.current.submit()}}  
                                type="submit" className="btn btn-primary btn-block">Update</button>
                            </div>
                        </div>


                    </CardBody>
                </Form>
            </Card>
        </Container>
    </Fragment>
  
  )
}

export default UpdateSupplier