import React, { Fragment, useEffect, useCallback, useRef, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DateRangePicker } from "rsuite";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Alert, Card, CardBody, Col, Container, Form, FormGroup, Input, Row, Button, Popover, PopoverBody, PopoverHeader } from "reactstrap";
import { toast } from "react-toastify";
import moment from "moment";

import Breadcrumb from "../../../layout/breadcrumb";
import { mapReturnRestockResponseToTable } from "../../../utils/mapper";

import {
    returnRestock, getReturnRestockList, setReturnData, setReturnRestockFilter
} from "../store/orderSlice";
import { autoSuggestionBin } from "../../manage-product/store/binLocationSlice";

import CommonDataTable from "../../../share-components/table/commonDataTable";
import ButtonLoader from "../../../share-components/ui/buttonLoader";
import DatePicker from "../../../share-components/ui/DateRangePicker";
import './style.scss';

const { afterToday } = DateRangePicker;

function ReturnRestoke() {
    const dispatch = useDispatch();

    const skuInputRef = useRef(null); // Create a reference to the input field
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const TodayDate = new Date();
    const sevenDays = new Date().setDate(TodayDate.getDate() - 90);
    const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);
    const [startDate, endDate] = dateRange;
    const [timer, setTimer] = useState(null);
    const orderState = useSelector(({ order }) => order.order);
    const [selectedRows, setSelectedRows] = useState([]);
    const [binErrors, setBinErrors] = useState([]);
    const authData = useSelector(({ authReducer }) => authReducer);
    const [bins, setBins] = useState([]);
    const [retrunRestockDatas, setRetrunRestockDatas] = useState([]);
    const [lastFilterData, setLastFilterData] = useState({});
    const [isAutoSelected, setIsAutoSelected] = useState(false);
    const [isScannerReading, setIsScannerReading] = useState(true);
    const [isGetData, setIsGetData] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            skuInputFocus();
        }, 100); // Small delay to ensure element is rendered before focusing
    }, []);

    // Focus the input field when the component mounts
    const skuInputFocus = () => {
        if (skuInputRef.current) {
            skuInputRef.current.value = '';
            skuInputRef.current.focus();
        }
    }

    const [isValid, setIsValid] = useState(true);
    const [isValidQty, setIsValidQty] = useState(true);
    const [isValidName, setIsValidName] = useState(true);

    // Function to update a specific row's data
    const updateRowData = (id, newData) => {
        const updatedData = retrunRestockDatas.map((row) => {
            return row.id === id ? { ...row, ...newData } : {...row};
        });
        setRetrunRestockDatas(updatedData);

        // Update for seleted data
        const updatedSeletedData = updatedData.filter((row) => {
            return row.isChecked == true;
        });
        setSelectedRows(updatedSeletedData);
    };

    const getData = (loadFilterData) => {
        const restockFilters = loadFilterData !== undefined ? loadFilterData : orderState.restockFilters;
        const filterData = {
            page: restockFilters.currentPage,
            pageSize: restockFilters.pageSize,
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            seller: restockFilters.seller ? restockFilters.seller.value : '',
            order: restockFilters.order,
            sku: restockFilters.sku,
            draw: restockFilters.draw
        };

        if (authData.defaultSeller && JSON.stringify(restockFilters) != JSON.stringify({ ...orderState.restockFilters, seller: authData.defaultSeller })) return;
        
        if (JSON.stringify(filterData) == JSON.stringify(lastFilterData)) return;

        console.log('filterData', filterData);
        setLastFilterData(filterData);

        dispatch(getReturnRestockList(filterData)).then(res => {
            dispatch(setReturnData(res.payload));
            let items = [...mapReturnRestockResponseToTable(res.payload)];
            if (isScannerReading && isAutoSelected && items.length > 0) {
                let item = {...items[0]};
                item.isChecked = true;
                items[0] = item;
                setSelectedRows([item]);
            }else {
                setSelectedRows([]);
            }
            setRetrunRestockDatas(items);
            setIsAutoSelected(false);
        })
    }

    useEffect(() => {
        if (isGetData) {
            getData();
        }
    }, [
        orderState.restockFilters.currentPage, orderState.restockFilters.pageSize, startDate, endDate,
        orderState.restockFilters.order, orderState.restockFilters.sku, orderState.restockFilters.binLocation,
        orderState.restockFilters.seller
    ]);

    useEffect(() => {
        if (authData.defaultSeller?.value != orderState.restockFilters.seller?.value) {
            dispatch(setReturnRestockFilter({
                ...orderState.restockFilters,
                seller: authData.defaultSeller,
                currentPage: 1
            }));
        }
    }, [authData.defaultSeller])

    useEffect(() => {
        checkValidation();

        if (selectedRows.length > 0) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }

    }, [selectedRows]);

    const dataMenu = [
        {
            type: 1,
            text: 'Orders',
            link: '/order'
        },
        {
            type: 0,
            text: "Return Restock"
        }
    ];

    const handleSearchForBin = (query) => {
        dispatch(autoSuggestionBin(query)).then((res) => {
            if (!res.error) {
                setBins(res.payload);
            }
        });
    };

    /*const handleOnBinChange = (binData, rowId, binIndex) => {
        const selectedName = binData.length > 0 ? binData[0].name : '';

        const returnData = [...retrunRestockDatas];
        const dataIndex = returnData.findIndex((row) => row.id === rowId);

        if (dataIndex !== -1) {
            const item = { ...returnData[dataIndex] };
            const binLocations = [...item['binLocations']];
            const binLocation = { ...binLocations[binIndex] };

            binLocation.name = selectedName;
            if (binLocation.qty === undefined) binLocation.qty = 0;
            binLocations[binIndex] = binLocation;
            item.binLocations = binLocations;

            updateRowData(rowId, item);
        }
    }*/

    const filterBy = () => true;

    function binLocationRow(groupBins, row) {
        let isEnable = selectedRows.some((selectedRow) => selectedRow.id === row.id && selectedRow.completeRestock === false);

        return groupBins.items.map((bin, index) => (
            <div className="row mb-2" key={row.id + '_' + groupBins.location_id + "_" + index}>
                <div className="col-md pr-0">
                    <AsyncTypeahead
                        id={`bin-location-${row.id}-${index}`}
                        disabled={!!bin.id || !isEnable}
                        // defaultInputValue={binLocation.name}
                        selected={bin.name ? [{ name: bin.name }] : []} // Set selected prop
                        filterBy={filterBy}
                        classname="mb-2"
                        size="sm"
                        minLength={1}
                        labelKey="name"
                        placeholder="Search for Bin..."
                        onChange={(selected) => {
                            handleOnBinChange(selected, row, groupBins, index);
                        }}
                        onSearch={handleSearchForBin}
                        options={bins}
                        renderMenuItemChildren={(option) => (
                            <>
                                <span>{option.name}</span>
                            </>
                        )}
                    />

                </div>
                <div className="col-md">
                    <input
                        type="number"
                        className="form-control form-control-sm"
                        onChange={(e) => handleBinQtyEdit(e, row, groupBins, index, 'qty')}
                        value={bin.qty}
                        disabled={!isEnable}
                    />
                </div>
                <div className="col-md-1 pl-0 pt-2">
                    {!bin.id && <i className="fa fa-minus-circle text-danger" onClick={() => handleRemoveBinLocation(row, groupBins, index)}></i>}
                </div>
            </div>
        ));
    }

    const handleAddBinLocation = (row) => {
        let binLocations = [...row.binLocations, { 'id': null, 'name': '', 'available': 0, 'qty': 0 }];
        row.binLocations = binLocations;

        updateRowData(row.id, row);
    };

    const handleRemoveBinLocation = (row, groupBins, index) => {
        const returnData = [...retrunRestockDatas];    
        let dataIndex = returnData.findIndex(x => x.id == row.id);
        let item = {...returnData[dataIndex]}; 

        let binLocations = [...item.binLocations];
        let locationIndex = binLocations.findIndex(x => x.location_id == groupBins.location_id);
        let binLocation = {...binLocations[locationIndex]}
        let locationItems = [...(binLocation.items ? binLocation.items : [])];
        locationItems.splice(index, 1);
        binLocation.items = locationItems;
        binLocations[locationIndex] = binLocation;
        item.binLocations = binLocations;

        updateRowData(row.id, item);
    };

    const conditionalRowStyles = [
        {
            when: row => row.restock_history,
            style: row => ({
                backgroundColor: '#fff3cd',
            }),
        },
    ];

    const allCheckBoxChange = (isChecked) => {
        retrunRestockDatas.forEach(function(item) {
            item.isChecked = isChecked;

            updateRowData(item.id, item);
        });
    }

    const tableColumns = [
        {
            name: <input onChange={(e) => {
                allCheckBoxChange(e.target.checked);
            }} type={"checkbox"} />,
            cell: (row) => {
                return (
                    <input onChange={(e) => {
                        row.isChecked = e.target.checked;
                        updateRowData(row.id, row);
                    }} checked={row.isChecked} type={"checkbox"} />
                );
            },
            ignoreRowClick: true,
            allowOverflow: true,
            sortable: false,
            center: true,
            width: '80px'
        },
        {
            name: 'Order Number',
            selector: row => row.orderName,
            cell: (row) => {
                return (row.restock_history && row.restock_history.length > 0)
                    ? <PopoverBasicItem id={row.id} btntext={row.orderName} Popoverbody={row.restock_history} Popoverheader={"Restock History"} />
                    : row.orderName;
            },
            sortable: false,
            center: false,
        },
        {
            name: 'SKU',
            selector: row => row.sku,
            sortable: false,
            center: false,
        },
        {
            name: 'Quantity',
            selector: row => row.quantity,
            sortable: false,
            center: true,
        },
        {
            name: 'Remove Item',
            selector: row => row.completeRestock,
            cell: (row) => {                
                let isEnable = selectedRows.some((selectedRow) => selectedRow.id === row.id);
                return (
                    <input onChange={(e) => {
                        row.completeRestock = e.target.checked;
                        updateRowData(row.id, row);
                    }} checked={row.completeRestock} disabled={!isEnable} type={"checkbox"} />
                );
            },
            sortable: false,
            center: true,
        },
        {
            name: 'Bin Location & Quantity',
            selector: row => row.binLocation,
            cell: (row) => {
                let isEnable = selectedRows.some((selectedRow) => selectedRow.id === row.id && selectedRow.completeRestock === false);
                return (
                    <div>
                        {row.binLocations.map((groupBins) => (     
                            <fieldset key={row.id + '_' + groupBins.location_id} className="bin-restock-fieldset my-2">
                                <legend>
                                    <div className="row">
                                        <div className="col-md-11">                                            
                                            <span className="location">
                                                <span className="title">Location:</span>
                                                <span className="value">{groupBins.name}</span>
                                            </span>
                                            <span className="quantity">
                                                <span className="title">Qty:</span>
                                                <span className="value">{groupBins.quantity}</span>
                                            </span>
                                        </div>
                                    </div>
                                </legend>
                                {binLocationRow(groupBins, row)}
                                <div className="row">
                                    <div className="col-md-11">                                
                                        <button type="button" 
                                            className="btn btn-primary btn-sm btn-block" 
                                            onClick={() => handleAddBinLocation(row, groupBins)}
                                            disabled={!isEnable}>Add More</button>
                                    </div>
                                </div>
                            </fieldset>
                        ))}
                    </div>
                );
            },
            sortable: false,
            center: true,
            width: "30%",
            style: {
                display: "block"
            }
        },
    ];

    const pageChange = (e) => {
        dispatch(setReturnRestockFilter({ ...orderState.restockFilters, currentPage: e }))
    }

    const pagination = (e) => {
        dispatch(setReturnRestockFilter({ ...orderState.restockFilters, currentPage: 1, pageSize: e }))
    }

    const handleRowSelected = (state) => {
        const returnData = [...retrunRestockDatas];
        const items = state.selectedRows.map((row) => {
            let dataIndex = returnData.findIndex(x => x.id == row.id);

            return dataIndex === -1 ? row : returnData[dataIndex];
        });

        setSelectedRows(items);
    };

    const checkValidation = () => {
        let errLists = [];
        let isValid = true;
        let isValidQty = true;
        let isValidName = true;
        let isValidLocationQty = true;

        for(const item of selectedRows) {
            let itemQty = 0;
            let binNameEmpty = false;
            
            if(item.completeRestock) continue;

            item.binLocations.forEach(function(binLocation, i) {
                let binQty = binLocation.items.reduce((accumulator, currentObject) => {
                    return accumulator + currentObject.qty;
                }, 0); // Initialize the accumulator with 0

                itemQty += binQty;

                if(binQty > binLocation.quantity) {
                    isValid = false;
                    isValidQty = false;
                    isValidLocationQty = false;
                }

                const itemBin = binLocation.items.find((bin) => bin.name === undefined || bin.name === null || bin.name === '');
                if(itemBin !== undefined) {
                    isValid = false;
                    isValidName = false;
                    binNameEmpty = true;
                }
            });

            if (itemQty <= 0 || itemQty > item.quantity) {
                isValid = false;
                isValidQty = false;
                errLists.push({ orderName: item.orderName, sku: item.sku, quantity: item.quantity, binQty: itemQty });
            }else if(binNameEmpty) {
                errLists.push({ orderName: item.orderName, sku: item.sku, quantity: item.quantity, binQty: itemQty });
            }else if(isValidLocationQty) {
                errLists.push({ orderName: item.orderName, sku: item.sku, quantity: item.quantity, binQty: itemQty });
            }

        }

        setIsValid(isValid);
        setIsValidQty(isValidQty);
        setIsValidName(isValidName);
        setBinErrors(errLists);

        return { isValid, isValidQty, isValidName };
    }

    const submitData = () => {
        let check = checkValidation();

        if (!check.isValid) {
            let msg = "";
            if (!check.isValidQty) msg = "The quantity you entered, must be greater than zero and less than the requested return quantity. ";
            if (!check.isValidName) msg += "Bin number is required.";
            toast.error(msg, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }

        setButtonDisabled(true);
        setIsGetData(false);

        dispatch(returnRestock(selectedRows)).then(response => {
            let filterData = {
                ...orderState.restockFilters,
                sku: '',
                currentPage: 1,
                draw: orderState.restockFilters.draw + 1
            };

            dispatch(setReturnRestockFilter(filterData));
            skuInputFocus();
            setSelectedRows([]);
            setIsGetData(true);
            setButtonDisabled(false);            
            getData(filterData);
        });
    }

    const handleBinQtyEdit = (e, row, groupBins, binIndex, field) => {
        const returnData = [...retrunRestockDatas];    
        let dataIndex = returnData.findIndex(x => x.id == row.id); 

        if (dataIndex !== -1) {
            let item = {...returnData[dataIndex]};
            let binLocations = [...item.binLocations];
            let locationIndex = binLocations.findIndex(x => x.location_id == groupBins.location_id);
            let binLocation = {...binLocations[locationIndex]}
            let locationItems = [...binLocation.items];
            let bin = {...locationItems[binIndex]};
            
            bin.qty = 0;
            if(field == 'name') bin.name = e.target.value;
            if(field == 'qty') bin.qty = Number(e.target.value);

            locationItems[binIndex] = bin;
            binLocation.items = locationItems;
            binLocations[locationIndex] = binLocation;
            item.binLocations = binLocations;
            
            updateRowData(row.id, item);
        }
    };

	const handleOnBinChange = (binData, row, groupBins, binIndex) => {
		const selectedName = binData.length > 0 ? binData[0].name : '';
        
        const returnData = [...retrunRestockDatas];    
        let dataIndex = returnData.findIndex(x => x.id == row.id); 

        if (dataIndex !== -1) {
            let item = {...returnData[dataIndex]};
            let binLocations = [...item.binLocations];
            let locationIndex = binLocations.findIndex(x => x.location_id == groupBins.location_id);
            let binLocation = {...binLocations[locationIndex]}
            let locationItems = [...binLocation.items];
            let bin = {...locationItems[binIndex]};
            
            bin.name = selectedName;
            if(bin.qty === undefined) bin.qty = 0;            

            locationItems[binIndex] = bin;
            binLocation.items = locationItems;
            binLocations[locationIndex] = binLocation;
            item.binLocations = binLocations;
            
            updateRowData(row.id, item);
        }
	}

    const PopoverBasicItem = (props) => {
        const { id, btntext, Popoverbody, Popoverheader } = props;
        const [popover, setPopover] = useState(false)
        const Toggle = () => setPopover(!popover);
        return (
            <>
                <p style={{ cursor: "pointer" }} className="example-popover" id={"Popover-" + id}>{btntext} <i className="fa fa-info-circle"></i></p>
                <Popover
                    placement="top"
                    isOpen={popover}
                    target={"Popover-" + id}
                    toggle={Toggle}
                    trigger="hover"
                >
                    {(Popoverheader)
                        ? <PopoverHeader className="restock-history-header" tag={"div"}>{Popoverheader}</PopoverHeader>
                        : <></>
                    }
                    <PopoverBody>
                        {renderHistory(Popoverbody)}
                    </PopoverBody>
                </Popover>
            </>
        );
    };

    const renderHistory = (history) => {
        if (!history || history.length === 0) {
            return null; // or any message or placeholder you want to display when the data is empty
        }

        return (
            <table width={450} className="restock-history">
                <thead>
                    <tr>
                        <th>Restocked By</th>
                        <th>Restocked Quantity</th>
                        <th>Restock At</th>
                    </tr>
                </thead>
                <tbody>
                    {history.map((item, index) => (
                        <tr key={index}>
                            <td>{item.restock_by}</td>
                            <td>
                                {Array.isArray(item.restocked_qty) ? (
                                    item.restocked_qty.map((qty, i) => (
                                        <div key={i}>{`${qty.bin_number} (${qty.quantity})`}</div>
                                    ))
                                ) : (
                                    <div>{item.restocked_qty}</div>
                                )}
                            </td>
                            <td>{item.restock_at}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    const handleInputChangeForSKUInput = (e) => {
        clearTimeout(timer);

        const newTimer = setTimeout(() => {
            setIsAutoSelected(!!e.target.value);

            dispatch(setReturnRestockFilter({
                ...orderState.restockFilters,
                sku: e.target.value,
                currentPage: 1,
            }));
        }, 500);

        setTimer(newTimer);
    };

    // const handleKeyPressForSKUInput = (e) => {
    //     if (e.key === 'Enter') {
    //         setIsAutoSelected(true);
    //     }
    // };

    return (
        <Fragment>
            <Breadcrumb title="Returns Restock" data={dataMenu} />

            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <div className="product-grid">
                            <div className="feature-products">
                                <Row>
                                    <Col md=''>
                                        <div style={{ marginTop: 7 }} className="dashboard-datepicker">
                                            <DatePicker
                                                dateRange={dateRange}
                                                dateRangeChange={(date) => {
                                                    setDateRange(date)
                                                }}
                                                defaultValue={[TodayDate, sevenDays]}
                                                disabledDate={afterToday()}
                                            />
                                        </div>
                                    </Col>

                                    <Col md=''>
                                        <Form onSubmit={(e) => {
                                            e.preventDefault();
                                        }}>
                                            <FormGroup style={{ marginTop: 7 }} className="dashboard-datepicker">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={orderState.restockFilters.order}
                                                    onChange={(e) => {
                                                        clearTimeout(timer);

                                                        const newTimer = setTimeout(() => {
                                                            dispatch(setReturnRestockFilter({
                                                                ...orderState.restockFilters,
                                                                order: e.target.value,
                                                                currentPage: 1,
                                                            }));
                                                        }, 500);

                                                        setTimer(newTimer)
                                                    }}
                                                    placeholder="Order Number"
                                                />
                                                <i className="fa fa-search"></i>
                                            </FormGroup>
                                        </Form>
                                    </Col>

                                    <Col md=''>
                                        <Form onSubmit={(e) => {
                                            e.preventDefault();
                                        }}>
                                            <FormGroup style={{ marginTop: 7 }} className="dashboard-datepicker">
                                                <Input
                                                    type="text"
                                                    className={'form-control' + (isScannerReading ? ' sku-input-focus' : '')}
                                                    defaultValue={orderState.restockFilters.sku}
                                                    onChange={handleInputChangeForSKUInput}
                                                    // onKeyDown={handleKeyPressForSKUInput}
                                                    innerRef={skuInputRef} // Use innerRef for reactstrap
                                                    placeholder="Search by SKU / GTN Number"
                                                    id="skuInputFilter"
                                                />
                                                <i className="fa fa-search"></i>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md="">
                                        <h5 className="mb-2">Items from Order </h5>
                                    </Col>
                                    <Col className="text-right">
                                        <Input onChange={(e) => {
                                            setIsScannerReading(e.target.checked);
                                        }} type="checkbox" className="checkbox" checked={isScannerReading} /> SKU Scanner
                                    </Col>
                                </Row>
                                <div>
                                    {isValid === false && <Alert color="danger">
                                        <h6 className="alert-heading mb-1">
                                            {isValidQty === false && <span className="mr-2">The quantity you entered, must be greater than zero and less than the requested return quantity.<br /></span>}
                                            {isValidName === false && <span>Bin number is required.<br /></span>}
                                        </h6>
                                    
                                        { binErrors.length > 0 && <ul className="bin-error-list">
                                            {binErrors.map((error, index) => (
                                                <li key={index} className="pb-1">
                                                    <strong>Order No:</strong> <span>{error.orderName}</span>
                                                    <strong className="pl-2">SKU:</strong> <span>{error.sku}</span>
                                                    <strong className="pl-2">Return Quantity:</strong> <span>{error.quantity}</span>
                                                    <strong className="pl-2">Bin Quantity:</strong> <span>{error.binQty}</span>
                                                </li>
                                            ))}
                                        </ul>}
                                    </Alert>
                                    }

                                    <div className="return-restock-table">
                                        <CommonDataTable
                                            headerColumns={tableColumns}
                                            conditionalRowStyles={conditionalRowStyles}
                                            data={retrunRestockDatas}
                                            noAction
                                            paginationServer
                                            paginationTotalRows={orderState.orderTableRow}
                                            paginationRowsPerPageOptions={[1, 2, 3, 4, 5, 10, 25, 50, 100]}
                                            onChangeRowsPerPage={pagination}
                                            onChangePage={pageChange}
                                            defaultPageSize={orderState.restockFilters.pageSize} // Set default pagination
                                        />
                                    </div>

                                    <div className="mt-2 d-flex justify-content-end">
                                        <div className="p-2">
                                            {(selectedRows.length == 0)
                                                ?
                                                <Button disabled={buttonDisabled} onClick={submitData} className="btn btn-primary btn-block">Save</Button>
                                                :
                                                <ButtonLoader disabled={buttonDisabled} onClick={submitData} btntext="Save" className="btn btn-primary btn-block" />
                                            }

                                        </div>
                                    </div>

                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </Container>
        </Fragment>
    )
}

export default ReturnRestoke