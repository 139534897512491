// import React from 'react'

// function UpdatePurchaseOrder() {
//   return (
//     <h1>UpdatePurchaseOrder</h1>
//   )
// }

// export default UpdatePurchaseOrder


import React, {Fragment, useEffect, useRef, useState} from 'react';
import {Col, FormGroup, Label, Row, Container, Card, Form, CardBody, Button} from "reactstrap";
import Switch from "react-switch";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import Breadcrumb from "../../../layout/breadcrumb";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {useDispatch, useSelector} from "react-redux";
// import { setProductSettings,getProductCreateSettings } from "../store/prodcutSlice";
import {getAllPurchaseOrderData, setPurchaseOrderData, setSearchProductDialog, editPurchaseOrderDetails, updatePurchaseOrderDetails, createDuplicatePurchaseOrder, generatePOPdfData} from "../store/purchaseOrderSlice";
import {useHistory, useParams} from "react-router-dom";
import moment from "moment";
import { toast } from 'react-toastify';
import { DateRangePicker } from 'rsuite';
// import DatePicker from "../../../share-components/ui/DateRangePicker";
import DatePicker from "react-datepicker";
import TagsInput from "react-tagsinput";
import SearchProductModal from "./searchProductModal";
import OrderProductJqx from "./orderProductJqx";
import OrderPayment from "./orderPayment";
import OrderDiscount from "./orderDiscount";
import OrderShipment from "./orderShipment";
import OrderCost from './orderCost';
import {paymentCalculation} from "./setting/calculation";
import ButtonLoader from '../../../share-components/ui/buttonLoader';
import SweetAlert from "sweetalert2";
import PreLoader from '../../../share-components/ui/preLoader';

const { afterToday } = DateRangePicker;
const dataMenu = [
    {
        type: 0,
        text: 'Products',
        link: '/product'
    },
    {
        type: 1,
        text: 'Purchase Orders',
        link: '/product/parchase-order'
    },
    {
        type: 0,
        text: 'Update Purchase Order',
        link: ''
    }
];

const defaultValues = {
    supplier:'',
    destination:'',
    paymentTerms:'',
    trackingNumber:'',
    shippingCarrier:'',
    estimatedArrival:'',
    supplierCurrency:'',
    referenceNumber:'',
    note:'',
    tags: [],
    ordered_products: [],
    ordered_products_new: [],
    rowCount: '',
    subTotal: '',
    discount: '',
    shipping: '',
    cost: '',
};

export const schema = yup.object().shape({
    // companyName: yup.string().required('Name is required field'),
    // email: yup.string().required('Email is required field'),
    // phone_number: yup.string().required('Phone number is required field').customPhoneValidation('You must enter valid phone number'),
});


function UpdatePurchaseOrder() {

    const { id } = useParams();
    const dispatch = useDispatch();
    const purchaseOrderState = useSelector(({product}) => product.purchaseOrder);
    const submitBtnSaveRef = useRef();
    const formRef = useRef();
    let history = useHistory();
    let searchProductRef = useRef();
    const userState =  useSelector(({authReducer}) => authReducer.data);
    const [btnDisable, setBtnDisable] = useState(false);
    const {handleSubmit, formState, reset, control, register, setValue, handleChange, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });
    const {errors} = formState;
    const [loading, isSetLoading] = useState(false);

    useEffect(() => {
        dispatch(getAllPurchaseOrderData()).then(res => {
            dispatch(setPurchaseOrderData(res.payload))
        })
    }, [dispatch]);
    
   

    const defaultValue1 = purchaseOrderState.purchaseOrderData?purchaseOrderState.purchaseOrderData.locations.find((location) => {
        return location.id === getValues('destination');
    }):'';

    const openSearchProductDialog = () => {
        const obj = {props: {isOpen: true}, data: {searchValue: searchProductRef.current.value}}
        dispatch(setSearchProductDialog(obj));
    }

    useEffect(() => {
        if(id){
            isSetLoading(true);
            dispatch(editPurchaseOrderDetails(id)).then(res => {
                if (!res.error){
                    reset(res.payload);
                    setValue('supplier',res.payload.supplier);
                    setValue('destination',res.payload.destination);
                    setValue('paymentTerms',res.payload.paymentTerms);
                    setValue('referenceNumber',res.payload.referenceNumber);
                    setValue('note',res.payload.note);
                    setValue('tags',res.payload.tags);
                    setValue('estimatedArrival',res.payload.estimatedArrival);
                    setValue('shippingCarrier',res.payload.shippingCarrier);
                    setValue('trackingNumber',res.payload.trackingNumber);
                    setValue('discount',res.payload.discount);
                    setValue('cost',res.payload.cost);
                    setValue('shipping',res.payload.shipping);
                    setValue('ordered_products',res.payload.ordered_products);
                    setValue('total',res.payload.total);
                    setValue('supplierCurrency',res.payload.supplierCurrency);
                    setValue('costAllAmounts',res.payload.cost_adjustments_total);
                    setValue('total',res.payload.total);
                    isSetLoading(false);
                };
            });
        }
    },[dispatch, id]);

    const onSubmit = (data) => {
        const payment = paymentCalculation(getValues);

        const costAdjustmentJson = data.cost && data.cost.costAdjustment
        ? JSON.stringify(data.cost.costAdjustment.map(item => ({
            typeId: item.typeId,
            name: item.name,
            costAmount: item.costAmount,
            isText: item.isText,
        })))
        : null;

        const formData = {
            id,
            "supplier":data.supplier.id,
            "destination":data.destination.id,
            "paymentTerms":data.paymentTerms.id,
            "supplierCurrency":data.supplierCurrency.id,
            "ordered_products": data.ordered_products,
            "referenceNumber":data.referenceNumber,
            "note":data.note,
            "tags":data.tags,
            "trackingNumber":data.trackingNumber,
            "shippingCarrier":data.shippingCarrier,
            "estimatedArrival":data.estimatedArrival ? moment(data.estimatedArrival).format('YYYY-MM-DD') : null,
            "rowCount": data.ordered_products.length,
            "discount": data.discount,
            "shipping": data.shipping,
            "costAdjustment": costAdjustmentJson,
            "subTotal": payment.subTotal,
            "tax": payment.taxPrice,
            "grandTotal": payment.total,
            "costAdjustmentTotal": payment.costAllAmounts,
            "is_mark_paid": data.is_mark_paid
        };
        dispatch(updatePurchaseOrderDetails({...formData})).then(res=>{
            if(!res.error){
                history.push('/product/parchase-order');
            }
        });
    };
   
    const onConfirmDuplicate = (row) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: "Do you want to duplicate purchase order?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Create',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        })
            .then((result) => {
                if (result.value) {
                    dispatch(createDuplicatePurchaseOrder({id})).then(res => {
                        if (!res.error) {
                            // history.push({
                            //     pathname: `/product/parchase-order/${res.payload}/edit`,
                            // })
                            window.open(`/product/parchase-order/${res.payload}/edit`, '_blank', 'noopener,noreferrer');
                        }
                    });
                }
            })
    }

    const onDownloadPdf = () => {

        const data = {
            id: id,
        }
        dispatch(generatePOPdfData(id)).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `po-${id}-${(new Date().toJSON().slice(0, 10))}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
            setBtnDisable(false)
        });

    }

  return (
    <Fragment>
    <Breadcrumb title="Update Purchase Order" data={dataMenu}/>
        <Container fluid={true} className="product-wrapper">
           
                <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>

                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label className="form-label">Supplier</Label>
                                            <Select 
                                                getOptionLabel={(option) => option.companyName}
                                                // defaultValue={getValues('supplier')}
                                                value={getValues('supplier')}
                                                getOptionValue={(option) => option.id} 
                                                {...register("supplier")}
                                                onChange={(e) => {
                                                    setValue('supplier', e);
                                                    trigger('supplier');
                                                }} className={` ${errors.supplier ? 'is-invalid' : ''}`}
                                                options={purchaseOrderState.purchaseOrderData.supplier}
                                            />
                                            <div className="invalid-feedback">{errors.supplier?.message}</div>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label className="form-label">Destination</Label>
                                        <Select
                                            placeholder="destination"
                                            getOptionLabel={(option) => option.name}
                                            value={getValues('destination')} 
                                            getOptionValue={(option) => option.id}
                                            {...register("destination")}
                                            onChange={(e) => {
                                                setValue('destination', e);
                                                trigger('destination');
                                            }}
                                            className={`${errors.destination ? 'is-invalid' : ''}`}
                                            options={purchaseOrderState.purchaseOrderData.locations}
                                        />
                                        <div className="invalid-feedback">{errors.destination?.message}</div>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label className="form-label">Payment terms</Label>
                                        <Select 
                                            getOptionLabel={(option) => option.name}
                                            value={getValues('paymentTerms')}
                                            getOptionValue={(option) => option.id} {...register("paymentTerms")}
                                            onChange={(e) => {
                                                setValue('paymentTerms', e);
                                                trigger('paymentTerms');
                                            }} className={` ${errors.paymentTerms ? 'is-invalid' : ''}`}
                                            options={purchaseOrderState.purchaseOrderData.paymentTerms}
                                        />
                                        <div className="invalid-feedback">{errors.paymentTerms?.message}</div>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label className="form-label">Supplier currency</Label>
                                        <Select getOptionLabel={(option) => option.name}
                                            defaultValue={getValues('supplierCurrency')}
                                            value={getValues('supplierCurrency')} 
                                            getOptionValue={(option) => option.id} {...register("supplierCurrency")}
                                            onChange={(e) => {
                                                setValue('supplierCurrency', e);
                                                trigger('supplierCurrency');
                                            }} className={` ${errors.supplierCurrency ? 'is-invalid' : ''}`}
                                            options={purchaseOrderState.purchaseOrderData.supplierCurrency}
                                        />
                                        <div className="invalid-feedback">{errors.supplierCurrency?.message}</div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Row>
                        <Col sm="8">
                            <Card>
                                <CardBody>
                                <h6 style={{marginBottom: '10px'}}>Add products</h6>
                                    <div className="form-row mb-3 ">
                                        <Col md="12">
                                            <FormGroup>
                                                <Label className="col-form-label">Products</Label>
                                                <Row>
                                                    <Col md="10 input-with-icon pr-1">
                                                        <div>
                                                            <FormGroup className="m-0">
                                                                <i className="fa fa-search"></i>
                                                                <input name="searchProduct" type="text"
                                                                        placeholder="search Products"
                                                                        ref={ref => {
                                                                            searchProductRef.current = ref;
                                                                        }}
                                                                        onKeyUp={openSearchProductDialog}
                                                                        className={`form-control`}/>
                                                            </FormGroup>
                                                        </div>
                                                    </Col>
                                                    <Col md="2 pl-0">
                                                        <Button 
                                                            color="primary" 
                                                            type="button"
                                                            className="btn btn-sm btn-primary btn-block"
                                                            onClick={openSearchProductDialog}
                                                            style={{height: 38}}>Browse
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                    {loading ? <>
                                                        <PreLoader></PreLoader>
                                                    </>
                                                    : <>
                                                        <OrderProductJqx 
                                                            setValue={setValue}
                                                            getValues={getValues}
                                                            trigger={trigger}
                                                            orderedProducts={getValues('ordered_products')}
                                                            rowCount={getValues('rowCount')}
                                                        ></OrderProductJqx>
                                                        </>}
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                        </Col>
                                    </div>
                                </CardBody>
                            </Card>

                            {/* {getValues('ordered_products').length > 0 &&  */}
                            <><Row>
                                    <Col sm="12">
                                        <OrderPayment setValue={setValue} getValues={getValues}
                                                      trigger={trigger} total={getValues('total')}></OrderPayment>
                                        {/* <div style={{color:'red',float:"right"}}>{errors.shipping?.message}</div> */}
                                    </Col>
                                </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Card>
                                                <CardBody>
                                                    <div className="float-right" style={{display:"flex"}}>
                                                        <div className="checkbox checkbox-dark mr-5">
                                                            <input id="is_mark_paid" type="checkbox"  onChange={(e)=>{
                                                                      setValue('is_mark_paid',e.target.checked)
                                                            }} defaultChecked={getValues('is_mark_paid')}/>
                                                            <Label for="is_mark_paid">Mark As Paid</Label>
                                                        </div>
                                                        {/* <ButtonLoader  color="primary"  value="1"	type="submit"  btntext="Create Draft Order"></ButtonLoader> */}
                                                    </div>

                                                </CardBody>
                                            </Card>

                                        </Col>
                                    </Row>
                                </>
                                {/* } */}

                        </Col>
                        <Col md="4">
                            <Card>
                                <CardBody>
                                    <h6 style={{marginBottom: '10px'}}>Additional details</h6>

                                    <Row>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label className="form-label">Reference number</Label>
                                                <input name="referenceNumber" type="text" className={`form-control ${errors.referenceNumber ? 'is-invalid' : ''}`} placeholder="referenceNumber" {...register('referenceNumber')}  />
                                                <div className="invalid-feedback">{errors.referenceNumber?.message}</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label className="form-label">Note to supplier</Label>
                                                <input name="note" type="text" className={`form-control ${errors.note ? 'is-invalid' : ''}`} placeholder="note" {...register('note')}  />
                                                <div className="invalid-feedback">{errors.note?.message}</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label className="col-form-label">Tags</Label>
                                                <TagsInput className="form-control tag-input-custom"
                                                    onlyUnique={true} value={getValues('tags') || []}
                                                    onChange={(value) => {
                                                        // alert(JSON.stringify(value));
                                                        setValue('tags', value)
                                                        trigger('tags');
                                                        // combineTags();
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            <Card>
                                <CardBody>
                                    <h6 style={{marginBottom: '10px'}}>Shipment details</h6>

                                    <Row>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label className="form-label">Estimated arrival</Label>
                                                    <DatePicker name="estimatedArrival"
                                                        minDate={new Date()}
                                                        selected={getValues('estimatedArrival') ? new Date(getValues('estimatedArrival')) : null}
                                                        className={`form-control digits ${errors.estimatedArrival ? 'is-invalid' : ''}`}
                                                        dateFormat="dd-MM-yyyy" onChange={(date) => {
                                                        setValue('estimatedArrival', date);
                                                        trigger('estimatedArrival')
                                                    }}/>
                                                    <div className="invalid-feedback">{errors.estimatedArrival?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label className="form-label">Shipping carrier</Label>
                                                <input name="shippingCarrier" type="text" className={`form-control ${errors.shippingCarrier ? 'is-invalid' : ''}`} placeholder="shippingCarrier" {...register('shippingCarrier')}  />
                                                <div className="invalid-feedback">{errors.shippingCarrier?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label className="form-label">Tracking number</Label>
                                                <input name="trackingNumber" type="text" className={`form-control ${errors.trackingNumber ? 'is-invalid' : ''}`} placeholder="trackingNumber" {...register('trackingNumber')}  />
                                                <div className="invalid-feedback">{errors.trackingNumber?.message}</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                        
                   

                    <div className="mt-2 d-flex justify-content-end">
                        <div className="p-2">
                            <button 
                            onClick={onDownloadPdf} 
                            className="pull-right btn btn-primary btn-block" color="success"
                                    type="button"> {btnDisable ? ( 
                                    <i className="fa fa-spinner fa-spin fa-lg spinner mr-1" />
                                ) : (
                                    'Download PO' 
                                )}
                            </button>
                        </div>
                        <div className="p-2">
                            <button 
                            onClick={onConfirmDuplicate} 
                            className="pull-right btn btn-primary btn-block" color="success"
                                    type="button">Generate Duplicate
                            </button>
                        </div>
                        {(userState.user.role.name == "Super Admin") && <div className="p-2">
                            <button 
                            ref={submitBtnSaveRef} 
                            onClick={()=>{formRef.current.submit()}}  
                            type="submit" className="btn btn-primary btn-block">Save</button>
                        </div>}
                    </div>


                   
                </Form>

                <SearchProductModal setValue={setValue} getValues={getValues} trigger={trigger}></SearchProductModal>
                <OrderDiscount orderSetValue={setValue} orderTrigger={trigger}
                           orderGetValues={getValues}></OrderDiscount>
                <OrderShipment purchaseOrderState={purchaseOrderState} orderSetValue={setValue} orderTrigger={trigger}
                           orderGetValues={getValues}></OrderShipment>
                <OrderCost  purchaseOrderState={purchaseOrderState} orderSetValue={setValue} orderTrigger={trigger}
                           orderGetValues={getValues} ></OrderCost>


        </Container>
    </Fragment>
  )
}

export default UpdatePurchaseOrder