import { combineReducers } from "@reduxjs/toolkit";
import order from "./orderSlice";
import draftOrder from "./draftOrderSlice";
import checkout from "./checkoutSlice";
import groupReturn from './groupReturnSlice';
import pendingOrderList from './pendingOrderSlice';
import cancelOrder from './cancelOrderSlice';
import pickingTicket from './pickingTicketSlice';
import restockOrderList from './restockReportSlice';
import usOrder from './usOrderSlice';
import restockReport from "./restockReportSlice";
import euOrder from "./euOrderSlice";

const orderReducer = combineReducers({
    order,
    draftOrder,
    checkout,
    groupReturn,
    pendingOrderList,
    cancelOrder,
    pickingTicket,
    restockOrderList,
    usOrder,
    restockReport,
    euOrder
});

export default orderReducer;
