import * as yup from "yup";
import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {Card, Col, Container, Form, FormGroup, Nav, Row, CardBody, Input,} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import ColoredLine from "../../../share-components/ui/coloredLine";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import {mapColourSettingToTable, mapSearchAutoSuggestion, mapSettingResponseToTable} from "../../../utils/mapper";
import ModalFormSubmit from "../../../share-components/form/modalFormSubmit";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {selectPaymentTerms, getAllPaymentTerms, deletePaymentTerm} from "../store/paymentTermsSlice";
import { setProductSettings,getProductCreateSettings } from "../store/prodcutSlice";
import withReducer from "../../../store/withReducer";
import productReducer from "../store";
import PreLoader from "../../../share-components/ui/preLoader";
import {useHistory, Link} from "react-router-dom";
import BackInStockTable from "../../backInStock/content/backInStockTable";
import moment from "moment";
import Select from "react-select";
import { toast } from 'react-toastify';
import { DateRangePicker } from 'rsuite';
import DatePicker from "../../../share-components/ui/DateRangePicker";
import SweetAlert from "sweetalert2";

const { afterToday } = DateRangePicker;
const dataMenu = [
    {
        type: 0,
        text: 'Products',
        link: '/product'
    },
    {
        type: 1,
        text: 'Payment Terms',
        link: ''
    }
];

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

function PaymentTerms() {

    const dispatch = useDispatch();
    let history = useHistory();
    const paymentTermsState = useSelector(({product}) => product.paymentTerms);
    const paymentTerms = useSelector(selectPaymentTerms);
    const [tableLoading, isSetTableLoading] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const TodayDate = new Date();
    const sevenDays = new Date().setDate(TodayDate.getDate() - 90);
    const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);
    const [startDate, endDate] = dateRange;
    const [page, setPage] = useState(1);
    const [timer, setTimer] = useState(null);
    const [name, setName] = useState(null);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);
    const userState =  useSelector(({authReducer}) => authReducer.data);

    useEffect(()=> {
        getData();
    },[dispatch, page, pageSize, name]);

    const getData = () =>{
        
        isSetTableLoading(true);
        const data = {
            page:page,
            pageSize:pageSize,
            name:name,
        }
        
        dispatch(getAllPaymentTerms(data)).then(res=>{
            if(!res.error){
                // isSetLoading(false);
            }
            isSetTableLoading(false);
        });
    
    }

    

    const onEdit = (row) => {
        history.push('payment-terms/'+row.id+'/edit');
    }

    const onDelete = (row) => {
        SweetAlert.fire({
            title: `Do you want to delete - ${row.name}`,
            icon: 'warning',
            showCancelButton: true,
            animation: false,
            confirmButtonText: 'Proceed',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        })
            .then((result) => {
                if(result.isConfirmed){
                    dispatch(deletePaymentTerm(row.id)).then( res=>{
                        if (!res.error) {
                            getData();
                        }
                    });
                }
            });
    }
    
    const tableColumns = [
       
        {
            name: 'Payment Method',
            selector: row => row.name,
            sortable: true,
            center: false,
        },
        {
            name: 'Action',
            selector: row =>
                <div>
                    {
                        userState.user &&
                        (userState.user.permission.indexOf("Update PaymentTerms")?
                            <a 
                            onClick={() => {
                                onEdit(row);
                            }}
                            ><i className="fa fa-pencil"
                                  style={{width: 25, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)'}}></i></a>:
                            "")
                    }
                    {
                        userState.user &&
                        (userState.user.permission.indexOf("Delete PaymentTerms")?
                            <a 
                            onClick={() => {
                                onDelete(row);
                            }}
                            ><i className="fa fa-trash" style={{width: 25, fontSize: 16, padding: 11, color: '#e4566e'}}></i></a>:
                            "")
                    }

                </div>,
            sortable: false,
            center: true,
        }
        
    ];

  return (
    <Fragment>

    <Container fluid={true} className="product-wrapper">
        {/* {loading ? <><PreLoader></PreLoader></>:<> */}
        <Breadcrumb data={dataMenu} title="Payment Terms"/>
        
        <div className="product-grid">
            <div className="feature-products">
            
                <Row>
                    <Col sm="12">
                    {(currentUser.permission).includes('Create Product Types') ?
                    <Card>
                                <Row>
                                    <Col md={3}>
                                        <div style={{marginTop: 7}} className="dashboard-datepicker ml-2">
                                            {/* <DatePicker 
                                                dateRange={dateRange} 
                                                dateRangeChange={(date)=>{
                                                    setDateRange(date)
                                                }}
                                                defaultValue={[TodayDate, sevenDays]}
                                                disabledDate={afterToday()}
                                            /> */}
                                        </div>
                                    </Col>
                                    <Col md={3}></Col>
                                    <Col md={6}>


                                    <div className='float-sm-right p-3'>
                                    <Link  className="btn btn-sm btn-primary" style={{cursor: "pointer"}} to="/product/add-payment-term">
                                        <i className="fa fa-plus"></i> Add Payment Term
                                    </Link>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                        :''}
                    </Col>
                </Row>
              

                <Row>
                    <Col md=''>
                        <Form  onSubmit={(e)=>{
                            e.preventDefault();
                        }}>
                            <FormGroup className="m-0">
                                <Input
                                    type="text"
                                    className="form-control"
                                    onChange={(e)=>{
                                        clearTimeout(timer);
                                        
                                        const newTimer = setTimeout(()=>{
                                            setName(e.target.value);
                                        },500);
                                        setTimer(newTimer)
                                    }}
                                    placeholder="Payment Term Name"
                                />
                                <i className="fa fa-search"></i>
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>

                <Row>
                    <Col sm="12" className="mt-3">
                        <Card>
                            <CardBody>
                                <div className="table-responsive product-table">
                                <CommonDataTable
                                    headerColumns={tableColumns}
                                    gridData={paymentTerms}
                                    noAction
                                    progressPending={tableLoading}
                                    paginationServer
                                    paginationTotalRows={paymentTermsState.paymentTermsTableRow}
                                    paginationPerPage={10}
                                    paginationComponentOptions={{
                                        noRowsPerPage: true
                                    }}
                                    onChangePage={page => setPage(page)}
                                       onEdit={(currentUser.permission).includes('Update PaymentTerms') ? onEdit:''}
                                       onDelete={(currentUser.permission).includes('Delete PaymentTerms') ? onDelete:''}
                                    />
                            
                               

                                

                                                
                            </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                

            </div>
        </div> 
       
        {/* </>
        } */}
    
    </Container>
   
</Fragment>
  )
}

export default PaymentTerms