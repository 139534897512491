import React, {Fragment, useEffect, useRef, useState} from "react";
import * as ReactDOM from 'react-dom';
import {Badge, Card, CardBody, Col, FormGroup, Input, Label} from "reactstrap";
import Select from "react-select";
import JqxTable from "../../../../../share-components/table/JqxTable";
import {toast} from "react-toastify";
import * as ReactDOMServer from "react-dom/server";
import {jqx} from "../../../../../custom_modules/jqx/jqwidgets-react-tsx/jqxgrid";


const PendingReturnJqx = (props) => {
    const {orderState,getGridJqx}=props;
    let gridRef = useRef(null);
    const reasonSources=(reasonData)=>{
        return  {
            datatype: 'array',
            datafields: [
                { name: 'id', type: 'string' },
                { name: 'name', type: 'string' },
                { name: 'is_restock', type: 'string' }
            ],
            localdata: reasonData,
        }
    }

    const reasonAdapter = new jqx.dataAdapter(reasonSources(orderState.settings.returnReasons), { autoBind: true });
    const customerReasonAdapter = new jqx.dataAdapter(reasonSources(orderState.settings.returnReasonsCustomer), { autoBind: true });

    const dataFields = [
        {name: 'id', type: 'id'},
        {name: 'lable_type', type: 'number'},
        {name: 'totalReturn', type: 'number'},
        {name: 'price', type: 'number'},
        {name: 'orderLineItemID', type: 'string'},
        {name: 'lineItemID', type: 'string'},
        {name: 'productName', type: 'string'},
        {name: 'itemSku', type: 'string'},
        {name: 'brand_name', type: 'string'},
        {name: 'binNUmber', type: 'string'},
        {name: 'colour', type: 'string'},
        {name: 'size', type: 'string'},
        {name: 'chooseReturnQuantity', type: 'number'},
        {name: 'returnRequestQuantity', type: 'number'},
        {name: 'orderQuantity', type: 'number'},
        {name: 'reasonRequest', value: 'customerReasonId', values: { source: customerReasonAdapter.records, value: 'id', name: 'name' } },
        {name: 'status', type: 'string'},
        {name: 'reasonID', type: 'string'},
        {name: 'reason', value: 'reasonID', values: { source: reasonAdapter.records, value: 'id', name: 'name' } },
        {name: 'isRestock', type: 'boolean' },
        {name: 'isDecline', type: 'boolean' },
    ];

    // eslint-disable-next-line no-shadow
    const getJqxRef = jqx => {
        if (jqx) {
            gridRef = jqx;
            getGridJqx(jqx);
            jqx.selectallrows();
            setTimeout(
                () => {
                    if (gridRef) {
                        const myEle = document.getElementById(gridRef._id);
                        if (myEle) {
                            gridRef.updatebounddata();
                        }
                    }

                    // myGrid.updatebounddata();
                },
                1000
            );
        }
    };

    let columns = [
        { datafield: 'id', hidden: true },
        { datafield: 'lable_type', hidden: true },
        { datafield: 'totalReturn', hidden: true },
        { datafield: 'price', hidden: true },
        { datafield: 'orderLineItemID', hidden: true },
        { datafield: 'lineItemID', hidden: true },
        {
            text: 'Product Name',
            datafield: 'productName',
            editable: false,
            align: 'center',
            cellsalign: 'left',
            cellsrenderer(row, columnfield, value, defaulthtml, columnproperties) {
                const productDetails = orderState.pendingReturnData.lists[row];
                const html = ReactDOMServer.renderToString(
                    <div style={{textAlign: 'center', padding: 5}}>
                        {productDetails && <div className="row">
                            <div className="col-md-3">
                                <img className="img-fluid" src={productDetails.image} width="40" alt=""/>
                            </div>
                            <div className="col-md-9 pl-0">
                                <div className="row m-0">
                                    <p className={'font-primary'}>{value}</p>
                                </div>
                                <div className="row m-0">
                                    <p>{productDetails.colour}/{productDetails.size}</p>
                                </div>
                                <div className="row m-0">
                                    <p>{productDetails.itemSku}</p>
                                </div>

                                {(productDetails.lable_type == 2 && orderState.pendingReturnData.customOrder && orderState.pendingReturnData.customOrder.financial_status != 'paid') && (
                                    <div style={{textAlign: 'left'}} className="row m-0">
                                        <p className="text-danger">
                                            The customer has not paid for the return label.
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>}
                    </div>
                );
                return html;
            },
            cellbeginedit(row, datafield, columntype) {},
        },
        {
            text: 'Bin NO',
            datafield: 'binNUmber',
            width: '8%',
            editable: false,
            align: 'center',
            cellsalign: 'left',
            cellbeginedit(row, datafield, columntype) {},
        },
        {
            text: 'Ordered Qty',
            datafield: 'orderQuantity',
            width: '8%',
            editable: false,
            align: 'center',
            cellsalign: 'right',
            cellbeginedit(row, datafield, columntype) {},
            
        },
        {
            text: 'Return Request Quantity',
            datafield: 'returnRequestQuantity',
            width: '10%',
            editable: false,
            align: 'center',
            cellsalign: 'right',
            cellbeginedit(row, datafield, columntype) {},
        },
        {
            text: '<div title="Choose Return Quantity"><span style="color: red">*</span> <strong>Choose Return Quantity</strong></div>',
            datafield: 'chooseReturnQuantity',
            width: '10%',
            editable: true,
            align: 'center',
            cellsalign: 'right',
            cellbeginedit(row, datafield, columntype, val) {
                let found=0;
                gridRef.getselectedrowindexes().forEach(element => element===row?found++:"");
                return (found>0);
            },
            cellendedit(row, datafield, columntype, oldvalue,value) {
                gridRef.setcellvalue(row,'totalReturn',(value*gridRef.getcellvalue(row,'price')));
            },
            validation(cell, value) {
                let found=0;
                gridRef.getselectedrowindexes().forEach(element => element===cell.row?found++:"");
                if(found >0) {
                    if (value > gridRef.getcellvalue(cell.row, 'orderQuantity')) {
                        // toast.error(`Return quantity cannot exceed the ordered quantity - (${gridRef.getcellvalue(cell.row,'returnRequestQuantity')})`, {
                        //     position: toast.POSITION.TOP_RIGHT,
                        // });
                        return false;
                    }
                    if (value <= 0) {
                        // toast.error("Invalid quantity value", {
                        //     position: toast.POSITION.TOP_RIGHT,
                        // });
                        return false;
                    }
                }
                return true;
            }
        },
        {
            text: '<div title="Request Reason"><span style="color: red">*</span><strong>Request Reason</strong></div>',
            datafield: 'customerReasonId',
            displayfield: 'reasonRequest',
            width: '10%',
            align: 'left',
            columntype: 'dropdownlist',
            editable: true,
            cellbeginedit(row, datafield, columntype, val) {
                let found=0;
                gridRef.getselectedrowindexes().forEach(element => element===row?found++:"");
                return (found>0);
            },
            createeditor: (row, value, editor) => {
                editor.jqxDropDownList({ height: 75, source: customerReasonAdapter, displayMember: 'name', valueMember: 'id' });
            },
        },
        {
            text: '<strong>Reason</strong>',
            datafield: 'reasonID',
            displayfield: 'reason',
            width: '10%',
            align: 'left',
            columntype: 'dropdownlist',
            editable: true,
            cellbeginedit(row, datafield, columntype, val) {
                let found=0;
                gridRef.getselectedrowindexes().forEach(element => element===row?found++:"");
                return (found>0);
            },
            createeditor: (row, value, editor) => {
                editor.jqxDropDownList({ height: 75, source: reasonAdapter, displayMember: 'name', valueMember: 'id'});
                // editor.jqxDropDownList({clearSelection:true });
            },
            validation(cell, value) {

                const reasons=orderState.settings.returnReasons
                if(value) {
                    let selectedReason = reasons.find(item => item.id === Number(value.value));
                    gridRef.setcellvalue(cell.row, 'isRestock', selectedReason.is_restock === "Yes");
                    if (orderState.pendingReturnData.data.is_return) {
                        gridRef.setcellvalue(cell.row, 'isDecline', false);
                    }
                }
                return true;
            }
        },
        // {
        //     text: 'Status',
        //     datafield: 'status',
        //     width: '5%',
        //     editable: false,
        //     align: 'center',
        //     cellsalign: 'left',
        //     cellbeginedit(row, datafield, columntype) {},
        // },
        {
            text: 'Restock',
            datafield: 'isRestock',
            editable: true,
            width: '5%',
            align: 'center',
            cellsalign: 'right',
            columntype: 'checkbox',
            cellbeginedit(row, datafield, columntype, val) {
                let found=0;
                gridRef.getselectedrowindexes().forEach(element => element===row?found++:"");
                return (found>0) && (!gridRef.getcellvalue(row, 'isDecline'));
            },
            cellendedit(row, datafield, columntype, oldvalue, value) {

            }
        }
    ];

    if(orderState.pendingReturnData.data.is_return){
        columns=[
            ...columns,
            {
                text: 'Declined',
                datafield: 'isDecline',
                editable: true,
                width: '5%',
                align: 'center',
                cellsalign: 'right',
                columntype: 'checkbox',
                cellbeginedit(row, datafield, columntype, val) {
                    let found=0;
                    gridRef.getselectedrowindexes().forEach(element => element===row?found++:"");
                    return (found>0) && (!gridRef.getcellvalue(row, 'isRestock'))
                },
                cellendedit(row, datafield, columntype, oldvalue, value) {

                }
            }
        ]
    }

    const onSelectRow = event => {
        if(event.args.rowindex && (event.args.rowindex).length>0){
            gridRef.setcellvalue(event.args.rowindex, 'chooseReturnQuantity', '');
        }else{
            const gridInformation = gridRef.getdatainformation();
            for (let i = 0; i < gridInformation.rowscount; i++) {
                gridRef.setcellvalue(i, 'reason', '');
                gridRef.setcellvalue(i, 'reasonID', "");
                gridRef.setcellvalue(i, 'chooseReturnQuantity', '');
            }
        }

        let rows = gridRef.getrows();
        if(rows){
            rows.forEach((item, index) => {
                if (item.lable_type == 2 && orderState.pendingReturnData.customOrder && orderState.pendingReturnData.customOrder.financial_status != 'paid') {
                    gridRef.unselectrow(index);
                }
            });
        }
    };

    const onRowunselect = event => {
        // gridRef.setcellvalue(event.args.rowindex, 'chooseReturnQuantity', '');
        // gridRef.setcellvalue(event.args.rowindex, 'reason', '');
        // gridRef.setcellvalue(event.args.rowindex, 'reason_id', null);
    };


    return (
        <Fragment>

            {orderState.pendingReturnData.lists.length>0 && <JqxTable
                datafields={dataFields}
                autorowheight={true}
                columns={columns}
                data={orderState.pendingReturnData.lists ? orderState.pendingReturnData.lists : null}
                myGrid={gridRef}
                isDelete={false}
                disableCreateRow
                getJqxRef={getJqxRef}
                onRowselect={onSelectRow}
                onRowunselect={onRowunselect}
                selectionmode={'checkbox'}
                selectRow={[0]}
                enableBrowserSelection={true}
            />}
        </Fragment>
    );
}

function areEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps.orderState) == JSON.stringify(nextProps.orderState);
}

export default React.memo(PendingReturnJqx, areEqual);