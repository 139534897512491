import React, {Fragment, useEffect, useRef, useState} from 'react';
import Breadcrumb from "../../../layout/breadcrumb";
import {
    ButtonDropdown,Card,
    CardBody, CardHeader,Col,
    Container,DropdownItem,DropdownMenu,DropdownToggle, Form,
    FormGroup,Input,Label,Nav,NavItem,NavLink,
    Row,Popover,PopoverBody,PopoverHeader,
} from "reactstrap";
import {Accordion} from "react-bootstrap";

import AsyncSelect from "react-select/async";
import {useDispatch, useSelector} from "react-redux";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import withReducer from "../../../store/withReducer";
import PreLoader from "../../../share-components/ui/preLoader";
import Select from "react-select";
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { inventoryAdjustment, getInventorySettings, setSettings, inventorySku, inventoryBin } from "../store/prodcutSlice";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import {skuSearchAutoSuggestion} from "../../../utils/mapper";
import {skuAutoSuggestionApi} from "../../../services/manage-product/productService";
import {useHistory} from "react-router-dom";


const dataMenu = [
    {
        type: 0,
        text: 'Stock Count',
    }
];

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

export const defaultValues = {
    binNumber: '',
    quantity: '',
    stock: '',
    // reason: '',
    conditions: ''
};

export const schema = yup.object().shape({
    binNumber: yup.string().required('Bin Number is required field').typeError('Bin Number is required field'),
    quantity: yup.number().required('Quantity is required field').typeError('Quantity is required field').min(1, 'Quantity must be greater than zero.'),
    stock: yup.string().required('Stock is required field').typeError('Stock is required field'),
    // reason: yup.string().required('Reason is required field').typeError('Reason is required field'),
});

function AddInventory() {
    const productState = useSelector(({product}) => product.product);
    const dispatch = useDispatch();
    let history = useHistory();
    const [selectedSkuData, setSelectedSkuData] = useState(null);
    const [tableLoading, isSetTableLoading] = useState(false);
    useEffect(() => {
        dispatch(getInventorySettings()).then(res => {
            dispatch(setSettings(res.payload))
        });
    }, [dispatch]);

    const [productConditions, setProductConditions] = useState([]);
    const handleProductConditionChange = (e) => {
        const value = parseInt(e.target.value);
        if (e.target.checked) {
          setProductConditions([...productConditions, value]);
        } else {
          setProductConditions(productConditions.filter((condition) => condition !== value));
        }
      };


    const submitBtnSaveRef = useRef();
    const formRef = useRef();
    const [actionBtn,setActionBtn]=useState(0);
    const { handleSubmit, formState, reset, register,trigger,setValue,getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });
    const { errors } = formState;
    const onSubmit = (data) => {
        submitBtnSaveRef.current.disabled = true;
        const formData = {
            "sku": selectedSkuData.sku,
            "bin": selectedBinId,
            "quantity": data.quantity,
            "stock": data.stock,
            // "reason": data.reason,
           "conditions": productConditions,
        };
        dispatch(inventoryAdjustment({...formData})).then(res=>{
            if(!res.error){
                history.push('/product/stock-count');
            }
        });
        // submitBtnSaveRef.current.disabled = false;
    };


    const [isLoadingSku, setIsLoadingSku] = useState(false);
    const [skus, setSkus] = useState([]);
    const handleSearchForSku = (query) => {
        setIsLoadingSku(true);
        dispatch(inventorySku(query)).then((res) => {
          if (!res.error) {
            setSkus(res.payload);
            setIsLoadingSku(false);
          }
        });
      };

    const handleOnSkuChange = (skuData) => {
        setSelectedSkuData(skuData[0]);
    }

    const [selectedBinId, setSelectedBinId] = useState(null);
    const [isLoadingBin, setIsLoadingBin] = useState(false);
    const [bins, setBins] = useState([]);
    const handleSearchForBin = (query) => {
        setIsLoadingBin(true);
        dispatch(inventoryBin(query)).then((res) => {
          if (!res.error) {
            setBins(res.payload);
            setIsLoadingBin(false);
          }
        });
      };

    const handleOnBinChange = (binData) => {
        const selectedId = binData.length > 0 ? binData[0].id : null;
        setSelectedBinId(selectedId);
        setValue('binNumber', selectedId || '');
        trigger('binNumber');
    }
    
    const filterBy = () => true;

  return (
    <Fragment>
    <Breadcrumb title="Stock Count" data={dataMenu}/>

    <Container fluid={true} className="product-wrapper">
        <div className="product-grid">
           <div className="feature-products">
                <Row className='mb-5'>
                    <Col sm="12">
                         <Label>SKU</Label>
                        <AsyncTypeahead
                            filterBy={filterBy}
                            id="async-example"
                            isLoading={isLoadingSku}
                            minLength={1}
                            labelKey="sku"
                            onChange={(selected) => {
                                handleOnSkuChange(selected)
                            }}
                            onSearch={handleSearchForSku}
                            options={skus}
                            placeholder="Search for SKU..."
                            renderMenuItemChildren={(option) => (
                            <>
                                <span>{option.sku}</span>
                            </>
                            )}
                        />
                    </Col>
                </Row> 
            </div>
        </div>
        {(selectedSkuData != null) 
        ? 
        <Card>
            <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                <CardBody>
                    <Row style={{marginBottom: "20px"}}>
                        <Col md='4'>
                            <img width="85" className="img-fluid" src={selectedSkuData.product_image} />
                        </Col>
                        <Col md='4'>
                            <Col style={{paddingLeft: 0}}>
                            <h6>Product Name</h6>
                            <span>{selectedSkuData.product_title}</span>
                            </Col>
                            
                            <Col style={{paddingLeft: 0, marginTop: '10px'}}>
                            <h6>SKU</h6>
                            <span>{selectedSkuData.sku}</span>
                            </Col>
                        </Col>
                        <Col md='4'>
                        <h6>Last Inventory Status</h6>
                        <p>Warehouse Input Quantity <i style={{color: "blueviolet"}} className="fa fa-question-circle" aria-hidden="true"></i>: <span style={{color: 'red'}}>{selectedSkuData.warehouseInputQty}</span></p>
                        <p>Commited <i style={{color: "blueviolet"}} className="fa fa-question-circle" aria-hidden="true"></i>: <span>{selectedSkuData.committed}</span></p>
                        <p>Available <i style={{color: "blueviolet"}} className="fa fa-question-circle" aria-hidden="true"></i>: <span>{selectedSkuData.available}</span></p>
                        <p>On Hold <i style={{color: "blueviolet"}} className="fa fa-question-circle" aria-hidden="true"></i>: <span>{selectedSkuData.onHand}</span></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md='4'>
                           
                        <Label>Bin Number</Label>
                        <AsyncTypeahead
                            name="binNumber"
                            {...register('binNumber')}
                            className={`${errors.binNumber ? 'is-invalid' : ''}`}
                            filterBy={filterBy}
                            id="async-example"
                            isLoading={isLoadingBin}
                            minLength={1}
                            labelKey="name"
                            onChange={(selected) => {
                                handleOnBinChange(selected);
                            }}
                            onSearch={handleSearchForBin}
                            options={bins}
                            placeholder="Search for Bin..."
                            renderMenuItemChildren={(option) => (
                            <>
                                <span>{option.name}</span>
                            </>
                            )}
                        />
                            <div className="invalid-feedback">{errors.binNumber?.message}</div>
                        </Col>
                        <Col md='4'>
                            <FormGroup>
                                <Label className="form-label">Quantity</Label>
                                <input name="quantity" placeholder="Enter New Quantity" type="number" className={`form-control ${errors.quantity ? 'is-invalid' : ''}`} {...register('quantity')} />
                                <div className="invalid-feedback">{errors.quantity?.message}</div>
                            </FormGroup>
                        </Col>
                        <Col md='4'>
                            <FormGroup>
                                <Label className="form-label">Stock Type</Label>
                                <Select
                                    placeholder="Select Stock Type"
                                    className={`select2-filter ${errors.stock ? 'is-invalid' : ''}`}
                                    value={productState.settings.stockType.find(option => option.value === getValues('stock'))}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    {...register("stock")}
                                    onChange={(e) => { 
                                        setValue('stock', e ? e.id : ''); 
                                        trigger('stock');
                                    }}
                                    options={productState.settings.stockType}
                                />
                                <div className="invalid-feedback">{errors.stock?.message}</div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        {/* <Col md='4'>
                            <FormGroup>
                                <Label className="form-label">Reason</Label>
                                <Select
                                    placeholder="Select Reason"
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    value={productState.settings.reasonType.find(option => option.value === getValues('reason'))}
                                    {...register("reason")}
                                    onChange={(e) => {
                                        setValue('reason', e ? e.id : '');
                                        trigger('reason');
                                    }}
                                    className={`select2-filter ${errors.reason ? 'is-invalid' : ''}`}
                                    options={productState.settings.reasonType}
                                />
                                <div className="invalid-feedback">{errors.reason?.message}</div>
                            </FormGroup>
                        </Col> */}
                        <Col md='4'>
                            <Label className="form-label">Product Condition</Label>
                            {productState.settings.productConditionType.map((condition) => (
                            <div className="form-check" key={condition.id}>
                                <input
                                type="checkbox"
                                className="form-check-input"
                                value={condition.id}
                                onChange={handleProductConditionChange}
                                checked={productConditions.includes(condition.id)}
                                />
                                {condition.name}
                            </div>
                            ))}
                        </Col>
                    </Row>

                    <div className="mt-2 d-flex justify-content-end">
                        <div className="p-2">
                            {/* <button onClick={()=>{formRef.current.submit()}} type="submit" className="btn btn-primary btn-block">Update Stock</button> */}
                            <button ref={submitBtnSaveRef} onClick={()=>{formRef.current.submit()}} type="submit" className="btn btn-primary btn-block">Update Stock</button>
                        
                        </div>
                    </div>
                </CardBody>
            </Form>
        </Card>
        : ""}
        
           
   </Container>
</Fragment>
  )
}

export default AddInventory