import React, {Fragment, useEffect, useState} from 'react';
import Breadcrumb from "../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, Form, FormGroup, Input, Row} from "reactstrap";
import {Accordion} from "react-bootstrap";
import "../../../assets/scss/components/accordian.scss";
import AsyncSelect from "react-select/async";
import {mapSearchAutoSuggestion} from "../../../utils/mapper";
import {sellerAutoSuggestionApi, sellerBrandSuggestionApi} from "../../../services/manage-product/productService";
import {useDispatch, useSelector} from "react-redux";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import withReducer from "../../../store/withReducer";
import inventoryReducer from "../store";
import {
    closeDialog,
    getBrands,
    getExportExcel,
    getInventoryProducts,
    openDialog,
    selectInventory,
    updateInventoryBulk,
    updateInventoryBulkBuSku
} from "../store/inventorySlice";
import {getProducts, setProductFilter} from "../store/prodcutSlice";
import VariationJqx from "../product/edit/content/variationJqx";
import VariationInventoryJqx from "./content/variationInventoryJqx";
import ReactPaginate from "react-paginate";
import PreLoader from "../../../share-components/ui/preLoader";
import Select from "react-select";
import ModalFormSubmit from "../../../share-components/form/modalFormSubmit";
import BulkUpload from "./content/bulkUpload";
import { toast } from 'react-toastify';
const dataMenu = [
    {
        type: 0,
        text: 'Inventory',
    }
];
const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

const defaultValues = {
    upload_file: '',
};

function Inventory() {
    const [currentPage, setCurrentPage] = useState(0);
    const [searchBrand, setSearchBrand] = useState('');
    const [searchName, setSearchName] = useState('');
    const [brands, setBrands] = useState([]);
    const [searchSeller, setSearchSeller] = useState('');
    const [sort, setSort] = useState('id-desc');
    const [loading, setLoading] = useState(true);
    const [importErrors, setImportErrors] = useState(null);
    const dispatch = useDispatch();
    const authData =  useSelector(({authReducer}) => authReducer);
    useEffect(()=>{
          setSearchSeller(authData.defaultSeller)
    },[authData.defaultSeller])
    const pageChange = (event) => {
        setCurrentPage(event.selected);
    }
    const sellerPromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(sellerAutoSuggestionApi(data)))
            });
        });
    // const typePromiseOption = (inputValue) =>
    //     new Promise((resolve) => {
    //         const data = {
    //             value: inputValue,
    //             seller_id: searchSeller
    //         }
    //         setTimeout(() => {
    //             resolve(mapSearchAutoSuggestion(sellerBrandSuggestionApi(data)))
    //         });
    //     });

    const  getAllInventoryData  = () => {
        dispatch(getInventoryProducts({
            page_size: 8,
            page: currentPage + 1,
            name: searchName,
            brand: searchBrand,
            seller_id: searchSeller?.value,
            sort: sort,
        })).then(res => {
            if (!res.error)
                   setLoading(false);
        })
    }

    useEffect(() => {
        getAllInventoryData();
    }, [dispatch, currentPage, searchName, searchBrand, searchSeller])

    useEffect(() => { 
        const data = {
            value: '',
            seller_id: searchSeller?.value
        }
        dispatch(getBrands(data)).then(res=>{
            setBrands(res.payload);
        });
    },[dispatch, searchSeller])


    const inventoryState = useSelector(({inventory}) => inventory.inventory);
    const products = useSelector(selectInventory);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);
    const [buttonDisable, setButtonDisable] = useState(false);

    const {
        control,
        handleSubmit: handleSubmitBulkUpload,
        formState: formBulkUpload,
        reset: resetBulkUpload,
        register: registerBulkUpload,
        setValue: setValueBulkUpload,
        getValues: getValuesBulkUpload,
        trigger:bulkUploadTrigger,
    } = useForm({
        mode: 'onChange',
        defaultValues: defaultValues,
        // resolver: yupResolver(schemaLookBook)
    });
    const {errors: errorsBulkUpload} = formBulkUpload;
    const closeBulkDialog = () => {
        resetBulkUpload(defaultValues);
        dispatch(closeDialog());
    }
    const onSubmitBulkUpload = (dialog, data) => {
        setButtonDisable(true);
        if(dialog.option == 1) {
            dispatch(updateInventoryBulk({...data})).then(res => {
                if (res.payload.errors != undefined){
                    setImportErrors(res.payload.errors);
                } else {
                    getAllInventoryData();
                    dispatch(closeDialog());
                }

                setButtonDisable(false);
            });
        }else{
            dispatch(updateInventoryBulkBuSku({...data})).then(res => {
                if (!res.error) {
                    const outputFilename = `products_${(new Date().toJSON().slice(0,10))}.csv`;
                    const url = window.URL.createObjectURL(new Blob([res.payload]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', outputFilename);
                    document.body.appendChild(link);
                    link.click();
                    dispatch(closeDialog());
                }
                setButtonDisable(false);

            });
        }

    }

    const exportFile = event => {
		// dispatch(getExportExcel())
        const outputFilename = `products_${(new Date().toJSON().slice(0,10))}.csv`;

        dispatch(getExportExcel({
            name: searchName,
            brand: searchBrand,
            seller_id: searchSeller?.value,
            sort: sort,
        })).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename); 
            document.body.appendChild(link);
            link.click();
        });
	};
    
    return (
        <Fragment>
            {loading ? <>
                    <PreLoader></PreLoader>
                </>
                : <>
            <Breadcrumb title="Inventory" data={dataMenu}/>
            <Container fluid={true} className="product-wrapper">
                <div className="product-grid">
                    <div className="feature-products">
                        <Card>
                            <Row>
                                <Col sm="12">
                                    
                                    <div className='float-sm-right p-3'>
                                        {(currentUser.modules).includes('sku-bulk-update')&&  <a  onClick={() => {
                                            let obj = {props: {isOpen: true},option:2};
                                            dispatch(openDialog(obj))
                                        }} className="btn btn-sm btn-primary"> <i className="fa fa-upload"></i>Import By Sku</a>}
                                        <a  onClick={() => {
                                                let obj = {props: {isOpen: true},option:1};
                                                dispatch(openDialog(obj))
                                            }} className="btn btn-sm btn-primary ml-2"> <i className="fa fa-upload"></i> Bulk Upload</a>
                                    </div>

                                    <div className='float-sm-right p-3'>
                                        <a onClick={exportFile} className="btn btn-sm"> <i className="fa fa-download"></i> Export</a>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                        <Row>
                            {currentUser.role.id != 5 && <Col xl={3} sm={12}>
                                <AsyncSelect
                                    className="select2-filter"
                                    isClearable={true}
                                    defaultOptions
                                    value={searchSeller}
                                    placeholder="select Seller"
                                    loadOptions={sellerPromiseOption}
                                    styles={customStyles}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    onClear={(e) => {

                                    }}
                                    onChange={(e) => {
                                        setSearchSeller(e);
                                    }}

                                />
                            </Col>}
                            <Col xl="3" sm="12">
                                <Select         
                                    className="select2-filter"
                                    isClearable
                                    cacheOptions
                                    styles={customStyles} placeholder="Select Brand" 
                                    getOptionLabel ={(option)=>option.name}
                                    getOptionValue ={(option)=>option.id} 
                                    onChange={(e)=>{
                                        setSearchBrand(e ? e : null);
                                    }} 
                                    options={brands} 
                                />
                            </Col>
                            <Col xl={currentUser.role.id != 5 ? "6" : "9"} sm="12">
                                <Form>
                                    <FormGroup className="m-0">
                                        <Input
                                            className="form-control"
                                            type="text"
                                            placeholder="search Product Name / Style"
                                            onChange={(e) => {
                                                setSearchName(e.target.value);
                                            }}
                                        />
                                        <i className="fa fa-search"></i>
                                    </FormGroup>
                                </Form>

                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col sm={12}>
                                                <Accordion>
                                                    {products.map((item, i) =>
                                                        <Accordion.Item eventKey={item.id} key={i}>
                                                            <Accordion.Header>
                                                                <div className="image-crop-center" style={{height: 25}}>
                                                                    <img src={item.url} width={25}/>
                                                                </div>
                                                                <div className="ml-1">
                                                                    <span style={{fontWeight: 'lighter', fontSize: 14}}
                                                                          className="mr-5">{item.name} ({item.stock})</span>
                                                                </div>


                                                            </Accordion.Header>
                                                            <Accordion.Body>
                                                                <VariationInventoryJqx productId={item.id}
                                                                                        product={item}
                                                                                       sizes={item.size}
                                                                                       variants={item.variants}></VariationInventoryJqx>
                                                            </Accordion.Body>
                                                        </Accordion.Item>)}
                                                </Accordion>
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col md={12}>
                                                <ReactPaginate
                                                    className="pagination"
                                                    activeClassName="pagination-active"
                                                    breakLabel="..."
                                                    nextLabel="next >"
                                                    onPageChange={pageChange}
                                                    pageRangeDisplayed={5}
                                                    pageCount={inventoryState.pagination.count}
                                                    initialPage={currentPage}
                                                    previousLabel="< previous"
                                                    renderOnZeroPageCount={null}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>


                        </Row>


                    </div>
                </div>
            </Container>
                </>}

                
                <ModalFormSubmit
                        index={2}
                        defaultValues={defaultValues}
                        // schema={schemaLookBook}
                        onSubmitForm={onSubmitBulkUpload}
                        reset={resetBulkUpload}
                        formState={formBulkUpload}
                        handleSubmit={handleSubmitBulkUpload}
                        title='Bulk upload'
                        selector='staff'
                        dialog={({inventory}) => inventory.inventory.addDialog}
                        closeDialog={closeBulkDialog}
                        buttonDisable={buttonDisable}
                    >
                        <BulkUpload control={control} errors={errorsBulkUpload} trigger={bulkUploadTrigger} register={registerBulkUpload}
                                      setValue={setValueBulkUpload}
                                      getValues={getValuesBulkUpload}
                                      importErrors={importErrors}></BulkUpload>
                    </ModalFormSubmit>
        </Fragment>
    );
}

export default withReducer([{inventory: inventoryReducer}])(Inventory);
;