import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, Form, FormGroup, Nav, Row} from "reactstrap";
import * as yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import ModalFormSubmit from "../../../../share-components/form/modalFormSubmit";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import PreLoader from "../../../../share-components/ui/preLoader";
import productReducer from "../../store";
import withReducer from "../../../../store/withReducer";
import {
    selectProductPlatform,
    openNewDialog,closeNewDialog, addProductPlatform, getAllProductPlatform, openEditDialog, updateProductPlatform,
    deleteProductPlatform
} from "../../store/productPlatformSlice";
import ProductPlatformForm from "./productPlatformForm";
import {productPlatformResponseToTable} from "../../../../utils/mapper";

const defaultValues = {
    name: '',
};
const schema = yup.object().shape({
    name: yup.string().required('You must enter a product platform name'),
});

function Platform() {

    const dispatch = useDispatch();
    const [name, setName] = useState([]);
    const [loading, setLoading] = useState(true);
    const productPlatforms = useSelector(selectProductPlatform);
    const [buttonDisable, setButtonDisable] = useState(false);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);

    const dataMenu = [
        {
            type: 0,
            text: 'Products',
        },
        {
            type: 1,
            text: 'Product Setting',
            link: ''
        },
        {
            type: 2,
            text: 'Product Platform',
            link: ''
        }
    ];

    const tableColumns = [
        {
            name: 'Platform Name',
            selector: row => row.name,
            sortable: true,
            left: true,
        },
       
    ]

    const {handleSubmit, formState, reset, control, register, setValue, handleChange, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const {errors} = formState;

    useEffect(() => {
        const data = {
            name
        }
        dispatch(getAllProductPlatform({...data})).then(res=>{
            if (!res.error)
                setLoading(false);
        });

    }, [dispatch, name]);

    const closeDialog = () => {
        dispatch(closeNewDialog());
    };

    const onEdit = (row) => {
        dispatch(openEditDialog(row));
    };

    const onDelete = (row) => {
        dispatch(deleteProductPlatform(row.id))
    }

    const onSubmitForm = (dialog, data) => {
        setButtonDisable(true);
        if (dialog.type === 'new') {
            dispatch(addProductPlatform({...data})).then(res => {
                setButtonDisable(false);
                if (!res.error) closeDialog(dialog.type)
            });
        } else {
            dispatch(updateProductPlatform({...data})).then(res => {
                setButtonDisable(false);
                if (!res.error) closeDialog(dialog.type);
            });
        }
    };

    

  return (
    <Fragment>

            <Container fluid={true} className="product-wrapper">
                {loading ? <><PreLoader></PreLoader></>:<>
                    <Breadcrumb data={dataMenu} title="Product Platform"/>
                    <div className="product-grid">
                        <div className="feature-products">
                            <Row>
                                <Col sm="12">
                                   
                                    <Card>
                                        <Row>
                                            <Col sm="6">
                                                <Nav tabs className="border-tab">

                                                </Nav>
                                            </Col>
                                            <Col sm="6">
                                                <div className='float-sm-right p-3'>
                                                    <a className="btn btn-sm btn-primary"
                                                       onClick={() => {
                                                           dispatch(openNewDialog())
                                                       }}
                                                    > <i className="fa fa-plus"></i> Add Product Platform</a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                        
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <Form>
                                        <FormGroup className="m-0">

                                            <input onChange={(e) => {
                                                setName(e.target.value)
                                            }}
                                                   type="text"
                                                   className="form-control border"
                                                   placeholder="Search By Type Name"/>
                                            <i className="fa fa-search"></i>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                        </div>
                    </div>
                            <Row>
                                <Col sm="12">
                                    <Card>
                                        <CardBody>
                                            <div className="table-responsive product-table mt-4">
                                                <CommonDataTable
                                                    headerColumns={tableColumns}
                                                    gridData={productPlatformResponseToTable(productPlatforms)}
                                                    pagination
                                                    onEdit={(currentUser.permission).includes('Update Product Platforms') ? onEdit:''}
                                                    // onDelete={(currentUser.permission).includes('Delete Product Platforms') ? onDelete:''}
                                                />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                         </>
                }

            </Container>
            <ModalFormSubmit
                defaultValues={defaultValues}
                schema={schema}
                onSubmitForm={onSubmitForm}
                reset={reset}
                formState={formState}
                handleSubmit={handleSubmit}
                title='Product Platform'
                selector='product'
                dialog={({product}) => product.productPlatform.addDialog}
                closeDialog={closeDialog}
                buttonDisable={buttonDisable}
            >
                <ProductPlatformForm control={control} errors={errors} register={register} setValue={setValue}
                                 trigger={trigger} getValues={getValues}
                />
            </ModalFormSubmit>
        </Fragment>
  )
}

// export default Platform
export default withReducer([{Platform: productReducer}])(Platform);