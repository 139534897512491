import React, {Fragment, useEffect, useRef, useState} from "react";
import {
    Alert,
    Badge,
    Card,
    CardBody,
    Col,
    FormGroup,
    Input,
    Label,
    Row,
    Button
} from "reactstrap";
import AsyncSelect from "react-select/async";
import {useDispatch, useSelector} from "react-redux";
import { binSearchAutoSuggestion } from "../../../../utils/mapper";
import { binAutoSuggestionApi } from "../../../../services/manage-product/productService";
import ModalFormSubmit from "../../../../share-components/form/modalFormSubmit";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {useForm, useFieldArray, Controller} from "react-hook-form";
import {closeBinInfoModal, updateBinLocations, setList} from "../../store/orderSlice";
import {binInfoGroupByLocation} from "../../../../utils/common";
import "./binInfoModal.scss";

const schema = yup.object().shape({
    groupsBins: yup.array().of(
        yup.object().shape({
            items: yup.array().of(
                yup.object().shape({
                    quantity: yup.number()
                        .min(0, 'Number must be equal to or greater than 0.')
                        .required('Quantity is required field.')
                        .integer('Quantity must be an integer number.')
                        .test('is-less-than-stock', 'Quantity cannot be greater than available quantity.', function(value) {
                            const stockQuantity = this.parent.stock_quantity; // Access the "stock_quantity" field
                            return value <= stockQuantity;
                        })
                        .typeError('Quantity is required field.'),
                })
            )
        })
    ),
});

const BinInfoModal = (props) => {
    // const {setValue, trigger, getValues, register, errors} = props;
    const dispatch = useDispatch();
    const orderState = useSelector(({order}) => order.order);
    const orderLineItem = orderState.binInfoModal?.data ? orderState.binInfoModal.data : null;
    const binInfo = orderLineItem && orderLineItem.bin_numbers ? orderLineItem.bin_numbers : [];
    const [totalQty, setTotalQty] = useState(0);
    const [isValidQty, setIsValidQty] = useState(true);
    const [buttonDisable, setButtonDisable] = useState(false);
    // const [binsLocations, setBinsLocations] = useState({});

    const initialData = () => {
        var groupsBins = [];
        binInfo.forEach((bin) => {
            let itemTotalQty = bin.items.reduce(function(prev, current) {
                return prev + Number(current.quantity)
            }, 0);

            groupsBins.push({...bin, ordering_qty: itemTotalQty});
        });

        return {
            id: orderLineItem ? orderLineItem.id : '',
            groupsBins: groupsBins
        }
    };

    const {
        control,
        handleSubmit: handleSubmitBinInfo,
        formState: formBinInfo,
        reset,
        register,
        setValue,
        getValues,
        trigger,
        watch
    } = useForm({
        mode: 'onChange',
        defaultValues: initialData(),
        resolver: yupResolver(schema)
    });

    const watchedFormData = watch(); // Watch the entire form
    const {errors: errors} = formBinInfo;
    const { fields: groupFields, append: appendGroup, remove: removeGroup } = useFieldArray({ name: 'groupsBins', control });
    // const { fields: itemFields, append: appendItem, remove: removeItem } = useFieldArray({ name: 'groupsBins.items', control });

    useEffect(() => {   
        // const bins = binInfoGroupByLocation(binInfo);
        // setBinsLocations(bins);
        let formData = initialData();     
        reset(formData);
        setTotalQty(orderLineItem && orderLineItem.quantity ? orderLineItem.quantity : 0);
    }, [orderLineItem]);

    useEffect(() => {
        setQtyLimitFn();
    }, [watchedFormData]);

    // useEffect(() => {
    //     console.log('errors', errors);
    // }, [errors]);

    const setQtyLimitFn = ()=>{
        let currentQtySum = 0;

        groupFields.forEach((groupField) => {
            let itemTotalQty = groupField.items.reduce(function(prev, current) {
                return prev + Number(current.quantity)
            }, 0);

            currentQtySum += itemTotalQty;
            groupField.ordering_qty = itemTotalQty;
        });

        if(totalQty == currentQtySum){
            setIsValidQty(true);
        }else{
            setIsValidQty(false);
        }
    };

    const onSubmitBinInfo = (dialog, data) => {
        if(!isValidQty) return;

        // Check location wise quantities
        let isValidLocationQty = true;        
        groupFields.forEach((groupField) => {
            if(isValidLocationQty) {
                isValidLocationQty = (groupField.ordering_qty == groupField.quantity);
            }
        });

        if(!isValidLocationQty) return;

        const formData = {
			"item_id": data.id,
			'bin_locations': data.groupsBins,
		};

        setButtonDisable(true);
		dispatch(updateBinLocations({...formData})).then(response => {
            const _item = response.payload.data;
            const lists = [...orderState.list];
            const index = lists.findIndex((data) => data.id === _item.id);

            if(index !== -1) {
                lists[index] = _item;
                dispatch(setList(lists));
            }
            setButtonDisable(false);
            clossBinInfoModal();
        });
    }

    const clossBinInfoModal = () => {
        dispatch(closeBinInfoModal())
    }

    const getAvailableQty = (item) => {
        return item.stock_quantity;
        // return parseInt(item.available ? item.available : 0) + parseInt(item.quantity ? item.quantity : 0);
    }
    
    return (
        <Fragment>            
            <ModalFormSubmit
                defaultValues={initialData()}
                onSubmitForm={onSubmitBinInfo}
                reset={reset}
                formState={formBinInfo}
                handleSubmit={handleSubmitBinInfo}
                title='Bin Number'
                selector='order'
                dialog={({order}) => order.order.binInfoModal}
                closeDialog={clossBinInfoModal}
                buttonDisable={buttonDisable}
                schema={schema}
                // size="lg"
            >
                {isValidQty === false && <>
                <Row className='mb-3'>
                    <Col md="">
                        <p className="text-danger">The total quantity across all bin numbers must be {totalQty}</p>
                    </Col>
                </Row>
                </>}


                {groupFields.map((group, groupIndex) => (
                    <fieldset key={group.id} className="bin-info-fieldset">
                        <legend>
                            <span className="location">
                                <span className="title">Location:</span>
                                <span className="value">{group.name}</span>
                            </span>
                            <span className="quantity">
                                <span className="title">Qty:</span>
                                <span className="value">{group.quantity}</span>
                            </span>
                        </legend>

                        {group.ordering_qty != group.quantity && <>
                        <Row className='mb-3'>
                            <Col md="">
                                <p className="text-danger">The total quantity across all bin numbers must be {group.quantity}</p>
                            </Col>
                        </Row></>}
                        
                        {group.items.map((item, itemIndex) => (
                        <Row key={`${group.id}_${itemIndex}`}>
                            <Col md="" className={errors.groupsBins?.[groupIndex]?.items?.[itemIndex]?.quantity ? '' : 'mb-3'}>
                                <input type="text" className="form-control" value={item.bin_number + " (Available: " + getAvailableQty(item) + ")"} disabled />
                            </Col>
                            <Col md="" className={errors.groupsBins?.[groupIndex]?.items?.[itemIndex]?.quantity ? '' : 'mb-3'}>
                                <Controller
                                    name={`groupsBins.[${groupIndex}].items.[${itemIndex}].quantity`}
                                    control={control}
                                    defaultValue={item.quantity}
                                    render={({ field }) => (
                                        <>
                                        <input
                                            type="number"
                                            placeholder="Quantity"
                                            className={`form-control ${errors.groupsBins?.[groupIndex]?.items?.[itemIndex]?.quantity ? 'is-invalid' : ''}`}
                                            {...register(`groupsBins.[${groupIndex}].items[${itemIndex}].quantity`)}
                                            {...field}
                                            // onChange={(e) => {
                                                // field.onChange(e);
                                            // }} 
                                            />
                                        </>
                                    )}
                                />
                            </Col>
                            
                            {errors.groupsBins?.[groupIndex]?.items?.[itemIndex]?.quantity && <>
                            <Col md="12 mb-3">
                                <div className="invalid-feedback d-block text-right">{errors.groupsBins?.[groupIndex]?.items?.[itemIndex]?.quantity?.message}</div>
                            </Col>
                            </>}
                        </Row>
                        ))}
                    </fieldset>
                ))}

            </ModalFormSubmit>
        </Fragment>
    );
}

export default BinInfoModal;