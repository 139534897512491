import { combineReducers } from '@reduxjs/toolkit';
import product from "./prodcutSlice";
import productType from "./productTypeSlice";
import productSize from "./productSizeSlice";
import productSeason from "./productSeasonSlice"
import productColor from "./productColorSlice";
import productTag from "./productTagSlice";
import productCategory from "./productCategorySlice";
import productStyle from "./productStyleSlice";
import compositionGroup from "./compositionGroupSlice";
import composition from "./productCompositionSlice";
import sizeMatrixConfiguration from "./sizeMatrixConfigurationSlice";
import productCommunity from "./productCommunitySlice"
import productFitType from "./productFitTypeSlice";
import productFitSizeCondition from "./productFitSizeConditionSlice";
import inventory from "./inventorySlice";
import lookBook from "./lookBookSlice";
import productTagSetting from "./productTagSettingSlice";
import binLocation from './binLocationSlice';
import binLocationType from "./binLocationTypeSlice";
import productPlatform from './productPlatformSlice';
import inventoryAdjustment from "./inventoryAdjustmentSlice";
import adjustmentInventory from './inventoryAdjustmentsSlice';
import saleOffer from "./saleOfferSlice";
import suppliers from "./supplierSlice";
import paymentTerms from "./paymentTermsSlice";
import purchaseOrder from "./purchaseOrderSlice";


const productReducer = combineReducers({
    product,
    productType,
    productSize,
    productSeason,
    productColor,
    productTag,
    productCategory,
    productStyle,
    compositionGroup,
    composition,
    sizeMatrixConfiguration,
    productCommunity,
    productFitType,
    productFitSizeCondition,
    inventory,
    lookBook,
    productTagSetting,
    binLocation,
    binLocationType,
    productPlatform,
    inventoryAdjustment,
    adjustmentInventory,
    saleOffer,
    suppliers,
    paymentTerms,
    purchaseOrder,
});

export default productReducer;