import React from 'react';
import { PopoverBody } from 'reactstrap';

import {
    formatter, formatterDecimal
} from "../../../../utils/common";

import PopoverFormula from './PopoverFormula';

const CheckWithShopify = (props) => {

    const {
        data,
        testOrderData,
        loading
    } = props;

    const testOrderGrossSales = testOrderData ? testOrderData.gross_sales : 0;
    const testOrderDiscount = testOrderData ? testOrderData.discount : 0;
    const testOrderTotalReturn = testOrderData ? testOrderData.total_return : 0;
    const testOrderTotalCost = testOrderData ? testOrderData.total_mp_cost : 0;

    return (
        <div className="card">
            {loading && (
                <div className="card-loading">
                    <i className="fa fa-spinner fa-lg spinner" />
                </div>
            )}

            <div className="card-body">
                <h5 className="card-title">Difference With Shopify</h5>

                <div className="table-responsive table-summary">
                    <table className="table table-bordered table-hover table-striped">
                        <thead>
                            <tr>
                                <th className="font-weight-bold"></th>
                                <th className="font-weight-bold text-center">Gross Sales</th>
                                <th className="font-weight-bold text-center">Discounts</th>
                                <th className="font-weight-bold text-center">Returns</th>
                                <th className="font-weight-bold text-center">Product Cost</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Marketplace Report</td>
                                <td className='text-right'>{formatter.format(data.gross_sales)}</td>
                                <td className='text-right'>{formatter.format(data.discount)}</td>
                                <td className='text-right'>{formatter.format(data.total_return)}</td>
                                <td className='text-right'>{formatter.format(data.total_mp_cost)}</td>
                            </tr>
                            <tr>
                                <td>VAT on Debenham sales</td>
                                <td className='text-right'>{formatter.format(data.debenham_difference_gross_sales)}</td>
                                <td className='text-right'>{formatter.format(data.debenham_difference_discount)}</td>
                                <td className='text-right'>{formatter.format(data.debenham_difference_total_return)}</td>
                                <td className='text-right'>{formatter.format(data.debenham_difference_total_cost)}</td>
                            </tr>
                            <tr>
                                <td>Int.Taxes & Others</td>
                                <td className='text-right'>{formatter.format(data.difference_gross_sales)}</td>
                                <td className='text-right'>{formatter.format(data.difference_discount)}</td>
                                <td className='text-right'>{formatter.format(data.difference_total_return)}</td>
                                <td className='text-right'>{formatter.format(data.difference_total_cost)}</td>
                            </tr>
                            <tr>
                                <td>Taxes Round Differences</td>
                                <td className='text-right'>{formatter.format(data.difference_vat_round_gross_sales)}</td>
                                <td className='text-right'>{formatter.format(0)}</td>
                                <td className='text-right'>{formatter.format(0)}</td>
                                <td className='text-right'>{formatter.format(0)}</td>
                            </tr>

                            {testOrderData && (
                                <tr>
                                    <td>Test Orders</td>
                                    <td className='text-right'>{formatter.format(testOrderData.gross_sales)}</td>
                                    <td className='text-right'>{formatter.format(testOrderData.discount)}</td>
                                    <td className='text-right'>{formatter.format(testOrderData.total_return)}</td>
                                    <td className='text-right'>{formatter.format(testOrderData.total_mp_cost)}</td>
                                </tr>
                            )}

                            <tr>
                                <td>
                                    Shopify Values
                                    <small className='d-block text-muted font-italic'>(It needs to match with Shopify.)</small>
                                </td>
                                <td className='text-right'>
                                    {formatter.format(data.gross_sales + data.difference_gross_sales + data.debenham_difference_gross_sales + data.difference_vat_round_gross_sales + testOrderGrossSales)}
                                </td>
                                <td className='text-right'>
                                    {formatter.format(data.discount + data.difference_discount + data.debenham_difference_discount + testOrderDiscount)}
                                </td>
                                <td className='text-right'>
                                    {formatter.format(data.total_return + data.difference_total_return + data.debenham_difference_total_return + testOrderTotalReturn)}
                                </td>
                                <td className='text-right'>
                                    {formatter.format(data.total_mp_cost + data.difference_total_cost + data.debenham_difference_total_cost + testOrderTotalCost)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default CheckWithShopify;
