import React, {Fragment, useEffect, useRef, useState} from 'react';
import {Col, FormGroup, Label, Row, Container, Card, Form, CardBody} from "reactstrap";
import Switch from "react-switch";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import Breadcrumb from "../../../layout/breadcrumb";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import { setProductSettings,getProductCreateSettings } from "../store/prodcutSlice";
import {UpdatePayment,getPaymentTerms} from "../store/paymentTermsSlice";
import {useHistory, useParams} from "react-router-dom";
import moment from "moment";

const dataMenu = [
    {
        type: 0,
        text: 'Products',
        link: '/product'
    },
    {
        type: 1,
        text: 'Payment Terms',
        link: '/product/payment-terms'
    },
    {
        type: 0,
        text: 'Update Payment Terms',
        link: ''
    }
];

const defaultValues = {
    name:''
};

export const schema = yup.object().shape({
    name: yup.string().required('Payment Method is required field')
});

function UpdatePaymentTerms() {

    const { id } = useParams();
    const dispatch = useDispatch();
    const submitBtnSaveRef = useRef();
    const formRef = useRef();
    const [actionBtn,setActionBtn]=useState(0);
    let history = useHistory();

    const {handleSubmit, formState, reset, control, register, setValue, handleChange, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });
    const {errors} = formState;
    
    const onSubmit = (data) => {
        const set={
            "id":id,
            "name" : data.name,
        }
        dispatch(UpdatePayment({...set})).then(res=>{
            if(!res.error){
                history.push('/product/payment-terms');
            }
        });
    };

  
    useEffect(() => {
        if(id){
            dispatch(getPaymentTerms(id)).then(res => {
                if (!res.error){
                    setValue('name',res.payload.name);
                };
            });
        }
    },[dispatch]);

  return (
    <Fragment>
    <Breadcrumb title="Update Payment Terms" data={dataMenu}/>
        <Container fluid={true} className="product-wrapper">
            <Card>
                <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                    <CardBody>
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label className="form-label">Payment Type<span style={{color:"red"}}>*</span></Label>
                                    <input name="name" type="text" className={`form-control ${errors.name ? 'is-invalid' : ''}`} placeholder="Type Your Payment Method" {...register('name')}  />
                                    <div className="invalid-feedback">{errors.name?.message}</div>
                                </FormGroup>
                            </Col>
                        </Row>

                        <div className="mt-2 d-flex justify-content-end">
                            <div className="p-2">
                                <button 
                                ref={submitBtnSaveRef} 
                                onClick={()=>{formRef.current.submit()}}  
                                type="submit" className="btn btn-primary btn-block">Save</button>
                            </div>
                        </div>


                    </CardBody>
                </Form>
            </Card>
        </Container>
    </Fragment>
  )
}

export default UpdatePaymentTerms