import * as yup from "yup";
import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {Card, Col, Container, Form, FormGroup, Nav, Row, CardBody, Input, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import ColoredLine from "../../../share-components/ui/coloredLine";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import {mapColourSettingToTable, mapSearchAutoSuggestion, mapSettingResponseToTable} from "../../../utils/mapper";
import ModalFormSubmit from "../../../share-components/form/modalFormSubmit";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {selectpurchaseOrder, getAllPurchaseOrder, getAllPurchaseOrderData, setPurchaseOrderData, deletePurchaseOrder, createDuplicatePurchaseOrder, generatePOBulkPdfData, generatePOPdfData} from "../store/purchaseOrderSlice";
import { setProductSettings,getProductCreateSettings } from "../store/prodcutSlice";
import withReducer from "../../../store/withReducer";
import productReducer from "../store";
import PreLoader from "../../../share-components/ui/preLoader";
import {useHistory, Link} from "react-router-dom";
import BackInStockTable from "../../backInStock/content/backInStockTable";
import moment from "moment";
import Select from "react-select";
import { toast } from 'react-toastify';
import { DateRangePicker } from 'rsuite';
import DatePicker from "../../../share-components/ui/DateRangePicker";
import SweetAlert from "sweetalert2";


const { afterToday } = DateRangePicker;
const dataMenu = [
    {
        type: 0,
        text: 'Products',
        link: '/product'
    },
    {
        type: 1,
        text: 'Purchase Orders',
        link: ''
    }
];

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};


function PurchaseOrder() {

    const dispatch = useDispatch();
    let history = useHistory();
    const supplierState = useSelector(({product}) => product.suppliers);
    const [orderCheckBoxData, setOrderCheckBoxData] = useState({idArray: []});
    const [removed, setRemoved] = useState(false);
    const purchaseOrderSelect = useSelector(selectpurchaseOrder);
    const [tableLoading, isSetTableLoading] = useState(true);
    const [pageSize, setPageSize] = useState(10);

    const TodayDate = new Date();
    const sevenDays = new Date().setDate(TodayDate.getDate() - 90);
    const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);
    const [startDate, endDate] = dateRange;
    const [page, setPage] = useState(1);
    const [timer, setTimer] = useState(null);
    const [purchaseNumber, setPurchaseNumber] = useState(null);
    const [status, setStatus] = useState(null);
    const [tag, setTags] = useState(null);
    const [supplier, setSupplier] = useState(null);
    const [destination, setDestination] = useState(null);
    const [createdBy, setCreatedBy] = useState(null);
    const [country, setCountry] = useState(null);

    const productState = useSelector(({product}) => product.product.productSettings);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);
    const userState =  useSelector(({authReducer}) => authReducer.data);
    const [isChecked, setIsChecked] = useState(null);
    const [itemIdArray, setItemIdArray] = useState([]);
    const [btnDisable, setBtnDisable] = useState(false);
    const [actionDropdownOpen, setActionDropdownOpen] = useState(false);
    const actionToggle = () => setActionDropdownOpen(prevState => !prevState);

    const purchaseOrderState = useSelector(({product}) =>  product.purchaseOrder);

    useEffect(()=> {
        getData();
    },[dispatch, page, pageSize, startDate, endDate, purchaseNumber, status, tag, supplier, destination, createdBy]);

    const getData = () =>{
        
        // isSetTableLoading(true);
        const data = {
            page:page,
            pageSize:pageSize,
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            purchaseNumber:purchaseNumber,
            status:status,
            tag:tag,
            supplier:supplier,
            destination:destination,
            createdBy: createdBy
        }
        
        dispatch(getAllPurchaseOrder(data)).then(res=>{
            if(!res.error){
                isSetTableLoading(false);
            }
        });
    
    }

    const onConfirmDuplicate = (row) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: "Do you want to duplicate purchase order?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Create',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        })
        .then((result) => {
            
            if (result.value) {
                dispatch(createDuplicatePurchaseOrder({id: row.id})).then(res => {
                    if (!res.error) {
                        window.open(`parchase-order/${res.payload}/edit`, '_blank', 'noopener,noreferrer');
                    }
                });
            }
        })
    }
  
    
    const tableColumns = [
       
        {
            name: 'Purchase Order Number',
            selector: row => row.purchase_order_number,
            cell: (row) =><Link className="text-primary" style={{cursor: "pointer"}} to={`${process.env.PUBLIC_URL}/product/parchase-order/${row.id}/view`}>{row.purchase_order_number}</Link>,
            sortable: true,
            center: false,
        },
        {
            name: 'Supplier',
            selector: row => row.supplier,
            sortable: true,
            center: false,
        },
        {
            name: 'Destination',
            selector: row => row.destination,
            sortable: true,
            center: false,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            center: false,
        },
        {
            name: 'Received',
            selector: row => row.received,
            sortable: true,
            center: false,
        },
        {
            name: 'Total',
            selector: row => `£${row.total}`,
            sortable: true,
            center: false,
        },
        {
            name: 'Arrival Date',
            selector: row => row.arrival_date,
            sortable: true,
            center: false,
        },
        {
            name: 'Created By',
            selector: row => row.created_by,
            sortable: true,
            center: false,
        },
        {
            name: 'Action',
            selector: row =>
            <Row style={{width: '170px'}}>
            <div>
            {row.status !== 'draft' ? (
                <a onClick={() => {
                    onReceived(row);
                }}><i className={`fa ${row.status == 'Received'? ' fa-shopping-cart':' fa-eye'} `}
                      style={{width: 5, fontSize: 16, cursor: "pointer", padding: 15, color: 'rgb(208,179,29)'}}></i></a>
            ) : (
                <a onClick={() => {
                    onEdit(row);
                }}>
                    <i className="fa fa-pencil" style={{ cursor: "pointer", width: 5, fontSize: 16, padding: 15, color: 'rgb(40, 167, 69)' }}></i>
                </a>
            )}
            </div>

             <div>
                {(userState.user.role.name == "Super Admin") && <a onClick={() => {
                    onConfirmDuplicate(row);
                }}>
                    <i className="fa fa-clone" title="Duplicate order" style={{ cursor: "pointer", width: 5, fontSize: 16, padding: 15, color: 'rgb(40, 167, 69)' }}></i>
                </a>}
             </div>
             <div>
                <a onClick={() => {
                    onDownloadPdf(row);
                }}>
                    <i className="fa fa-print" title="Download PDF" style={{ cursor: "pointer", width: 5, fontSize: 16, padding: 15, color: 'rgb(40, 167, 69)' }}></i>
                </a>
             </div>
             <div>
             {row.status !== 'draft' ? (
                <span style={{ width: 5, fontSize: 16, padding: 15, color: '#e4566e' }}></span>
                ) : (
                    <a onClick={() => {
                        onDelete(row);
                    }}><i className="fa fa-trash"
                    style={{width: 5, fontSize: 16, padding: 15, cursor: "pointer", color: '#e4566e'}}></i></a>
                )}
             </div>
             </Row>,
            sortable: false,
            center: true,
        }
     
        
    ];

    let idArray = [];
    const handleChange = (state) => {
        if (state.selectedCount>0) {
            setIsChecked(true);
            state.selectedRows.map((item, i) => {
                if (idArray.includes(item.id)!==-1) {
                    idArray.push(item.id);
                    setItemIdArray(idArray);
                    setOrderCheckBoxData(prevState => ({
                        idArray:[...prevState.idArray, ...idArray],

                    }));
                }

            });
        } else {
            setIsChecked(false);
            setItemIdArray([]);
        }
        // idArray = Array.from(new Set(idArray));
        // setItemIdArray(idArray)
    }


    const generatePOBulkPdf = () => {
        setBtnDisable(true);
        const data = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            orderIdArray:itemIdArray
        }
        dispatch(generatePOBulkPdfData(data)).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Purchase Order-${(new Date().toJSON().slice(0, 10))}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
            // isSetLoading(false)
            setBtnDisable(false)
        });

    };

    useEffect(() => {
        dispatch(getAllPurchaseOrderData()).then(res => {
            dispatch(setPurchaseOrderData(res.payload))
        })
    }, [dispatch]);

    const pageChange = (e) => {

        setPage(e)
    }

    const onPageSizeChange = (e) => {
        setPageSize(e)
    }

    const onEdit = (row) => {
        history.push('parchase-order/'+row.id+'/edit');
    }

    const onReceived = (row) => {
        history.push(row.id+'/receive-inventory');
    }

    const onDelete = (row) => {
        SweetAlert.fire({
            title: `Do you want to delete - ${row.purchase_order_number}`,
            icon: 'warning',
            showCancelButton: true,
            animation: false,
            confirmButtonText: 'Proceed',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        })
            .then((result) => {
                if(result.isConfirmed){
                    dispatch(deletePurchaseOrder(row.id)).then( res=>{
                        if (!res.error) {
                            getData();
                        }
                    });
                }
            });
    }

    const onDownloadPdf = (row) => {
        dispatch(generatePOPdfData(row.id)).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `#PO-${row.id}-${(new Date().toJSON().slice(0, 10))}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
            setBtnDisable(false)
        });

    }
    

  return (
    <Fragment>

            <Container fluid={true} className="product-wrapper">
                {tableLoading ? <><PreLoader></PreLoader></>:<>
                <Breadcrumb data={dataMenu} title="Purchase Orders"/>
                <div className="product-grid">
                    <div className="feature-products">
                    
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <Row>
                                        <Col md={3}>
                                            <div style={{marginTop: 7}} className="dashboard-datepicker ml-2">
                                                <DatePicker 
                                                    dateRange={dateRange} 
                                                    dateRangeChange={(date)=>{
                                                        setDateRange(date)
                                                    }}
                                                    defaultValue={[TodayDate, sevenDays]}
                                                    disabledDate={afterToday()}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={3}></Col>
                                        <Col md={6}>

                                        <div className='float-sm-right p-3'>
                                            <ButtonDropdown isOpen={actionDropdownOpen}
                                                            toggle={actionToggle}>
                                                <DropdownToggle caret color="primary"
                                                                className="btn btn-primary btn-square">
                                                    {/* Export */}
                                                    {btnDisable ? ( 
                                                                <i className="fa fa-spinner fa-spin fa-lg spinner mr-1" />
                                                            ) : (
                                                                'Export'
                                                            )}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem onClick={() => {
                                                        generatePOBulkPdf();

                                                    }
                                                    }>Download PDF</DropdownItem>
                                                    
                                                </DropdownMenu>
                                            </ButtonDropdown>
                                        </div>

                                        <div className='float-sm-right p-3'>
                                        <Link  className="btn btn-sm btn-primary" style={{cursor: "pointer"}} to="/product/add-parchase-order">
                                            <i className="fa fa-plus"></i> Add Purchase Order
                                        </Link>
                                        </div>
                                    </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                      

                        <Row>
                        <Col md=''>
                            <Form  onSubmit={(e)=>{
                                e.preventDefault();
                            }}>
                                <FormGroup className="m-0">
                                    <Input
                                        type="text"
                                        className="form-control"
                                        // defaultValue={orderState.filter.name}
                                        onChange={(e)=>{
                                            clearTimeout(timer);
                                            
                                            const newTimer = setTimeout(()=>{
                                                setPurchaseNumber(e.target.value);
                                            },500);
                                            setTimer(newTimer)
                                        }}
                                        placeholder="Purchase Order No"
                                    />
                                    <i className="fa fa-search"></i>
                                </FormGroup>
                            </Form>
                        </Col>

                        <Col md=''>
                            <FormGroup>
                                <Select
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    placeholder="Select Status"
                                    className="select2-filter"
                                    isClearable
                                    cacheOptions
                                    styles={customStyles}
                                    options={purchaseOrderState.purchaseOrderData.statusPurchse}
                                    onChange={(e) => {
                                        setStatus(e ? e.name : null);
                                        
                                    }}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}

                                />
                            </FormGroup>
                        </Col>

                        <Col md=''>
                            <Form  onSubmit={(e)=>{
                                e.preventDefault();
                            }}>
                                <FormGroup className="m-0">
                                    <Input
                                        type="text"
                                        className="form-control"
                                        // defaultValue={orderState.filter.name}
                                        onChange={(e)=>{
                                            clearTimeout(timer);
                                            
                                            const newTimer = setTimeout(()=>{
                                                setTags(e.target.value);
                                            },500);
                                            setTimer(newTimer)
                                        }}
                                        placeholder="Tags"
                                    />
                                    <i className="fa fa-search"></i>
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col md=''>
                            <FormGroup>
                                <Select
                                    getOptionLabel={(option) => option.companyName}
                                    getOptionValue={(option) => option.id}
                                    placeholder="Select Supplier"
                                    className="select2-filter"
                                    isClearable
                                    cacheOptions
                                    styles={customStyles}
                                    options={purchaseOrderState.purchaseOrderData.supplier}
                                    onChange={(e) => {
                                        setSupplier(e ? e.id : null);
                                        
                                    }}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}

                                />
                                
                            </FormGroup>
                        </Col>
                        <Col md=''>
                            <FormGroup>
                                <Select
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    placeholder="Select Destination"
                                    className="select2-filter"
                                    isClearable
                                    cacheOptions
                                    styles={customStyles}
                                    options={purchaseOrderState.purchaseOrderData.locations}
                                    onChange={(e) => {
                                        setDestination(e ? e.id : null);
                                        
                                    }}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}

                                />
                                
                            </FormGroup>
                        </Col>
                        <Col md=''>
                            <FormGroup>
                                <Select
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    placeholder="Created By"
                                    className="select2-filter"
                                    isClearable
                                    cacheOptions
                                    styles={customStyles}
                                    options={purchaseOrderState.purchaseOrderData.user}
                                    onChange={(e) => {
                                        setCreatedBy(e ? e.id : null);
                                        
                                    }}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}

                                />
                                
                            </FormGroup>
                        </Col>
                        </Row>

                        <Row>
                            <Col sm="12" className="mt-3">
                                <Card>
                                    <CardBody>
                                        <div className="table-responsive product-table">
                                        {/* <CommonDataTable
                                            headerColumns={tableColumns}
                                            gridData={purchaseOrderSelect}
                                            paginationServer
                                            noAction
                                            selectableRows
                                            paginationTotalRows={purchaseOrderState.purchaseOrderTableRow}
                                            paginationPerPage={10}
                                            paginationRowsPerPageOptions={[10,25,50,100]}
                                            onChangeRowsPerPage={onPageSizeChange}
                                            paginationComponentOptions={{
                                                noRowsPerPage: true
                                            }}
                                            selectableRowSelected={(row)=>{
                                                return (orderCheckBoxData.idArray).includes(row.id)
                                            }}
                                            onSelectedRowsChange ={handleChange}
                                            pageChange={pageChange}
                                            onChangePage={page => setPage(page)}
                                            //    onEdit={(currentUser.permission).includes('Update Suppliers') ? onEdit:''}
                                            //    onDelete={(currentUser.permission).includes('Delete Suppliers') ? onDelete:''}
                                            /> */}
                                    
                                        <BackInStockTable
                                            paginationTotalRows={purchaseOrderState.purchaseOrderTableRow}
                                            idArray={orderCheckBoxData}
                                            remove={removed}
                                            tableColumns={tableColumns}
                                            backStockData={purchaseOrderSelect}
                                            backInRequestState={purchaseOrderState}
                                            pageChange={pageChange}
                                            // toggleRowSelected={toggleRowSelected}
                                            handleChange={handleChange}
                                            paginationRowsPerPageOptions={[10,25,50,100]}
                                            onPageSizeChange={onPageSizeChange}
                                            // paginationDefaultPage={sellerState.filter.currentPage}
                                            // paginationPerPage={sellerState.filter.pageSize}
                                            paginationPerPage={10}

                                        />

                                        

                                                        
                                    </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        

                    </div>
                </div> 
               
                </>
                }
            
            </Container>
           
        </Fragment>
  )
}

export default PurchaseOrder