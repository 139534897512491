import API from "../utils/api";

export const getEuOrderApi = async (data)=>{
    const response= await API.get('order/get-all-eu-order', {params:data});
    return {response}
};

export const reCreateEUOrderApi = async (data)=>{
    const response= await API.get('order/create-eu-order-manually/'+data);
    return {response}
};