import * as yup from "yup";
import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {Card, Col, Container, Form, FormGroup, Nav, Row, CardBody, Input,} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import ColoredLine from "../../../share-components/ui/coloredLine";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import {mapColourSettingToTable, mapSearchAutoSuggestion, mapSettingResponseToTable} from "../../../utils/mapper";
import ModalFormSubmit from "../../../share-components/form/modalFormSubmit";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {selectsuppliers, getAllSuppliers, getAllCountries, deleteSupplier} from "../store/supplierSlice";
import { setProductSettings,getProductCreateSettings } from "../store/prodcutSlice";
import withReducer from "../../../store/withReducer";
import productReducer from "../store";
import PreLoader from "../../../share-components/ui/preLoader";
import {useHistory, Link} from "react-router-dom";
import BackInStockTable from "../../backInStock/content/backInStockTable";
import moment from "moment";
import Select from "react-select";
import { toast } from 'react-toastify';
import { DateRangePicker } from 'rsuite';
import DatePicker from "../../../share-components/ui/DateRangePicker";
import SweetAlert from "sweetalert2";


const { afterToday } = DateRangePicker;
const dataMenu = [
    {
        type: 0,
        text: 'Products',
        link: '/product'
    },
    {
        type: 1,
        text: 'Supplier',
        link: ''
    }
];

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

const Supplier = () => {
    const dispatch = useDispatch();
    let history = useHistory();
    const supplierState = useSelector(({product}) => product.suppliers);
    const [orderCheckBoxData, setOrderCheckBoxData] = useState({idArray: []});
    const [removed, setRemoved] = useState(false);
    const suppliers = useSelector(selectsuppliers);
    const [tableLoading, isSetTableLoading] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const TodayDate = new Date();
    const sevenDays = new Date().setDate(TodayDate.getDate() - 90);
    const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);
    const [startDate, endDate] = dateRange;
    const [page, setPage] = useState(1);
    const [timer, setTimer] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    const [email, setEmail] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [country, setCountry] = useState(null);
    const productState = useSelector(({product}) => product.product.productSettings);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);
    const userState =  useSelector(({authReducer}) => authReducer.data);

    useEffect(()=> {
        getData();
    },[dispatch, page, pageSize, startDate, endDate, companyName, email, phoneNumber, country]);

    const getData = () =>{
        
        isSetTableLoading(true);
        const data = {
            page:page,
            pageSize:pageSize,
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            companyName:companyName,
            email:email,
            phoneNumber:phoneNumber,
            country:country,
        }
        
        dispatch(getAllSuppliers(data)).then(res=>{
            if(!res.error){
                // isSetLoading(false);
            }
            isSetTableLoading(false);
        });
    
    }

    useEffect(() => {
        dispatch(getAllCountries()).then(res => {
            dispatch(setProductSettings(res.payload))
        })
    }, [dispatch]);

    const onEdit = (row) => {
        history.push('supplier/'+row.id+'/edit');
    }

    const onDelete = (row) => {
        SweetAlert.fire({
            title: `Do you want to delete - ${row.companyName}`,
            // text: "New password credentials will be sent to the registered email!",
            icon: 'warning',
            showCancelButton: true,
            animation: false,
            confirmButtonText: 'Proceed',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        })
            .then((result) => {
                if(result.isConfirmed){
                    dispatch(deleteSupplier(row.id)).then( res=>{
                        if (!res.error) {
                            getData();
                        }
                    });
                }
            });
    }
    
    const tableColumns = [
       
        {
            name: 'Company Name',
            selector: row => row.companyName,
            sortable: true,
            center: false,
        }, 
        {
            name: 'Country',
            selector: row => row.country,
            sortable: true,
            center: false,
        },
        {
            name: 'Address',
            selector: row => row.address,
            sortable: true,
            center: false,
        },
        {
            name: 'Apartment',
            selector: row => row.apartment,
            sortable: true,
            center: false,
        },
        {
            name: 'City',
            selector: row => row.city,
            sortable: true,
            center: false,
        },
        {
            name: 'Postcode',
            selector: row => row.postcode,
            sortable: true,
            center: false,
        },
        {
            name: 'Contact Name',
            selector: row => row.contactName,
            sortable: true,
            center: false,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            center: false,
        },
        {
            name: 'Phone Number',
            selector: row => row.phone_number,
            sortable: true,
            center: false,
        },
        {
            name: 'Action',
            selector: row =>
                <div>
                    {
                        userState.user &&
                        (userState.user.permission.indexOf("Update Suppliers")?
                            <a onClick={() => {
                                onEdit(row);
                            }}><i className="fa fa-pencil"
                                  style={{width: 25, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)'}}></i></a>:
                            "")
                    }
                    {
                        userState.user &&
                        (userState.user.permission.indexOf("Delete Suppliers")?
                            <a onClick={() => {
                                onDelete(row);
                            }}><i className="fa fa-trash" style={{width: 25, fontSize: 16, padding: 11, color: '#e4566e'}}></i></a>:
                            "")
                    }

                </div>,
            sortable: false,
            center: true,
        }
        
    ];

    const pageChange = (e) => { 
        // dispatch(setDraftOrderFilter({...sellerState.filter, currentPage:e}))
    }
    const onPageSizeChange = (e) => {
        // dispatch(setDraftOrderFilter({...sellerState.filter, currentPage: 1, pageSize:e}))
    }

    // const handleChange = (state) => {
    //     // idArr = [...itemIdArray]
    //     if (state.selectedCount>0) {
    //         setIsChecked(true);
    //         state.selectedRows.map((item, i) => {
    //             if (idArray.includes(item.id)!==-1) {
    //                 idArray.push(item.id);
    //                 // setItemIdArray(idArray);
    //                 setOrderCheckBoxData(prevState => ({
    //                     idArray:[...prevState.idArray, ...idArray],

    //                 }));
    //             }

    //         });
    //         // if (state.selectedRows.length===1) {

    //         // } else {
    //         //     dispatch(setCurrentDeliveryPartner(null))
    //         // }
    //     }
    //     idArray = Array.from(new Set(idArray));
    //     setItemIdArray(idArray)
    // }

    return (
        <Fragment>

            <Container fluid={true} className="product-wrapper">
                {/* {loading ? <><PreLoader></PreLoader></>:<> */}
                <Breadcrumb data={dataMenu} title="Supplier"/>
                
                <div className="product-grid">
                    <div className="feature-products">
                    
                        <Row>
                            <Col sm="12">
                            {(currentUser.permission).includes('Create Product Types') ?
                            <Card>
                                        <Row>
                                            <Col md={3}>
                                                <div style={{marginTop: 7}} className="dashboard-datepicker ml-2">
                                                    <DatePicker 
                                                        dateRange={dateRange} 
                                                        dateRangeChange={(date)=>{
                                                            setDateRange(date)
                                                        }}
                                                        defaultValue={[TodayDate, sevenDays]}
                                                        disabledDate={afterToday()}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={3}></Col>
                                            <Col md={6}>


                                            <div className='float-sm-right p-3'>
                                            <Link  className="btn btn-sm btn-primary" style={{cursor: "pointer"}} to="/product/add-supplier">
                                                <i className="fa fa-plus"></i> Add Supplier
                                            </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                                :''}
                            </Col>
                        </Row>
                      

                        <Row>
                        <Col md=''>
                            <Form  onSubmit={(e)=>{
                                e.preventDefault();
                            }}>
                                <FormGroup className="m-0">
                                    <Input
                                        type="text"
                                        className="form-control"
                                        // defaultValue={orderState.filter.name}
                                        onChange={(e)=>{
                                            clearTimeout(timer);
                                            
                                            const newTimer = setTimeout(()=>{
                                                setCompanyName(e.target.value);
                                            },500);
                                            setTimer(newTimer)
                                        }}
                                        placeholder="Company Name"
                                    />
                                    <i className="fa fa-search"></i>
                                </FormGroup>
                            </Form>
                        </Col>

                        <Col md=''>
                            <Form  onSubmit={(e)=>{
                                e.preventDefault();
                            }}>
                                <FormGroup className="m-0">
                                    <Input
                                        type="text"
                                        className="form-control"
                                        // defaultValue={orderState.filter.name}
                                        onChange={(e)=>{
                                            clearTimeout(timer);
                                            
                                            const newTimer = setTimeout(()=>{
                                                setEmail(e.target.value);
                                            },500);
                                            setTimer(newTimer)
                                        }}
                                        placeholder="Email"
                                    />
                                    <i className="fa fa-search"></i>
                                </FormGroup>
                            </Form>
                        </Col>

                        <Col md=''>
                            <Form  onSubmit={(e)=>{
                                e.preventDefault();
                            }}>
                                <FormGroup className="m-0">
                                    <Input
                                        type="text"
                                        className="form-control"
                                        // defaultValue={orderState.filter.name}
                                        onChange={(e)=>{
                                            clearTimeout(timer);
                                            
                                            const newTimer = setTimeout(()=>{
                                                setPhoneNumber(e.target.value);
                                            },500);
                                            setTimer(newTimer)
                                        }}
                                        placeholder="Phone Number"
                                    />
                                    <i className="fa fa-search"></i>
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col md=''>
                            <FormGroup>
                                <Select
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    placeholder="Select Country"
                                    className="select2-filter"
                                    isClearable
                                    cacheOptions
                                    styles={customStyles}
                                    options={productState.countries}
                                    onChange={(e) => {
                                        setCountry(e ? e.id : null);
                                        
                                    }}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}

                                />
                                
                            </FormGroup>
                        </Col>
                        </Row>

                        <Row>
                            <Col sm="12" className="mt-3">
                                <Card>
                                    <CardBody>
                                        <div className="table-responsive product-table">
                                        <CommonDataTable
                                            headerColumns={tableColumns}
                                            gridData={suppliers}
                                            noAction
                                            progressPending={tableLoading}
                                            paginationServer
                                            paginationTotalRows={supplierState.supplierTableRow}
                                            paginationPerPage={10}
                                            paginationComponentOptions={{
                                                noRowsPerPage: true
                                            }}
                                            onChangePage={page => setPage(page)}
                                            //    onEdit={(currentUser.permission).includes('Update Suppliers') ? onEdit:''}
                                            //    onDelete={(currentUser.permission).includes('Delete Suppliers') ? onDelete:''}
                                            />
                                    
                                        {/* <BackInStockTable
                                            paginationTotalRows={supplierState.supplierTableRow}
                                            idArray={orderCheckBoxData}
                                            remove={removed}
                                            tableColumns={tableColumns}
                                            backStockData={suppliers}
                                            backInRequestState={supplierState}
                                            pageChange={pageChange}
                                            // toggleRowSelected={toggleRowSelected}
                                            // handleChange={handleChange}
                                            paginationRowsPerPageOptions={[10,25,50,100]}
                                            onPageSizeChange={onPageSizeChange}
                                            // paginationDefaultPage={sellerState.filter.currentPage}
                                            // paginationPerPage={sellerState.filter.pageSize}
                                        /> */}

                                        

                                                        
                                    </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        

                    </div>
                </div> 
               
                {/* </>
                } */}
            
            </Container>
           
        </Fragment>
    );
};

export default withReducer([{color: productReducer}])(Supplier)