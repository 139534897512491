import React, { Fragment, useEffect, useRef, useState } from "react";
import * as ReactDOM from 'react-dom';
import { Badge, Card, CardBody, Col, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Nav, Row, ModalFooter } from "reactstrap";
import { setSearchProductDialog, productSearch, setProducts } from "../store/purchaseOrderSlice";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

function SearchProductModal(props) {
  const purchaseOrderState = useSelector(({ product }) => product.purchaseOrder);
  const { setValue, trigger, getValues, imageSelectOnClick } = props;
  const dispatch = useDispatch();
  let variantRef = useRef([]);
  let infiniteScrollRef = useRef();
  const [productData, setProductData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [checkedIds, setCheckedIds] = useState([]);
  const closDialog = () => {
    setSearchValue("");
    dispatch(setSearchProductDialog({ props: { isOpen: false } }))
  }

  const checkSelectedData = () => {
    let orderProducts = getValues('ordered_products');
    setValue('ordered_products_new', orderProducts);
    setCheckedIds([]);
    orderProducts.map((item, i) => {
      setCheckedIds((prevCheckedIds) => {
        if (prevCheckedIds.includes(item.variant_id)) {
          return prevCheckedIds.filter((checkedId) => checkedId !== item.variant_id);
        } else {
          return [...prevCheckedIds, item.variant_id];
        }
      });
    });
  };

  const productSearchFunction = (isConcat, page) => {
    const parentValue = purchaseOrderState.searchProductDialogProps.data.searchValue;
    if (!searchValue) {
      setSearchValue(purchaseOrderState.searchProductDialogProps.data.searchValue);
    }
    if (searchValue || (!parentValue)) {
      dispatch(productSearch({ name: searchValue, page: page })).then(res => {
        if (!res.error) {
          dispatch(setProducts(res.payload))
          if (isConcat) {
            setProductData(prevArray => [...prevArray, ...res.payload.data])
          } else {
            setProductData(res.payload.data);
          }
        }
        ;
      });
    }
  }

  useEffect(() => {
    if (purchaseOrderState.searchProductDialogProps.props.isOpen) {
      if (infiniteScrollRef.current) {
        infiniteScrollRef.current.actionTriggered = false;
      }

      productSearchFunction(false, 1);
      checkSelectedData();
    }
  }, [purchaseOrderState.searchProductDialogProps.props, searchValue]);

  useEffect(() => {
    if (purchaseOrderState.searchProductDialogProps.props.isOpen) {
      productSearchFunction(true, currentPage);
    }
  }, [currentPage]);


  const fetchProductData = () => {
    setCurrentPage(purchaseOrderState.products.pagination.next_page);
  }

  const productOnChange = (e, product, variants) => {
    let orderProducts = getValues('ordered_products_new');
    variants.map((item, i) => {
      if (!item.is_out_of_stock) {
        variantRef.current[item.id].checked = e.target.checked;
        orderProducts = productObjectUpdate(orderProducts, e.target.checked, product, item);
      }
    });

    setValue('ordered_products_new', orderProducts);
    setCheckedIds((prevCheckedIds) => {
      if (prevCheckedIds.includes(product.id)) {
        return prevCheckedIds.filter((checkedId) => checkedId !== product.id);
      } else {
        return [...prevCheckedIds, product.id];
      }
    });

  }
  const variantOnChange = (e, product, variant) => {
    let orderProducts = getValues('ordered_products_new');
    orderProducts = productObjectUpdate(orderProducts, e.target.checked, product, variant);
    setValue('ordered_products_new', orderProducts);
  }
  const productObjectUpdate = (orderProducts, isCheck, product, variant) => {
    let orderedProductIndex = orderProducts.findIndex(x => x.variant_id === variant.id);
    if (orderedProductIndex != '-1') {
      if (!isCheck) {
        orderProducts.splice(orderedProductIndex, 1);
      }
    } else {
      orderProducts.push({ product_id: product.id, variant_id: variant.id, product_name: product.name, image: product.url, price: variant.price, sku: variant.sku, variant_name: variant.variant_name, quantity: 1, total_price: variant.price, stock: variant.stock })
    }

    setCheckedIds((prevCheckedIds) => {
      if (prevCheckedIds.includes(variant.id)) {
        return prevCheckedIds.filter((checkedId) => checkedId !== variant.id);
      } else {
        return [...prevCheckedIds, variant.id];
      }
    });

    return orderProducts;
  }

  const addOrderedProducts = () => {
    console.log(getValues('ordered_products_new'));
    setValue('ordered_products', getValues('ordered_products_new'));
  }

  return (
    <Fragment>
      <div className="form-row mb-3">
        <Modal size="lg"    {...purchaseOrderState.searchProductDialogProps.props} toggle={() => {
          closDialog()
        }}>
          <ModalHeader toggle={() => {
            closDialog()
          }}>
            All Products
          </ModalHeader>
          <ModalBody>
            <div className="form-row mb-3">
              <Col md="12 input-with-icon pr-1">
                <div>
                  <FormGroup className="m-0" >
                    <i className="fa fa-search"></i>
                    <input name="code" type="text" placeholder="search Products" defaultValue={searchValue}
                      onChange={(e) => {
                        setSearchValue(e.target.value ? e.target.value : "");
                      }}
                      className={`form-control`} />
                  </FormGroup>
                </div>
              </Col>
            </div>

            <div className="form-row mb-3">
              <InfiniteScroll
                ref={(scroll) => {
                  infiniteScrollRef.current = scroll;
                }}
                dataLength={productData.length}
                next={fetchProductData}
                hasMore={purchaseOrderState.products.pagination.has_more}
                refreshFunction={() => {
                  alert("Test")
                }}
                pullDownToRefresh={true}
                pullDownToRefreshContent={
                  <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
                }
                height={600}
                loader={<p style={{ textAlign: 'center' }}>Loading...</p>}
              >
                <table className="table" style={{ width: '100%', height: 400, overflowY: 'scroll' }}>
                  <tbody>
                    {productData.map((item, i) =>
                      <Fragment key={i}>
                        <tr>
                          <td style={{ width: '5%', height: 1 }}><input type={"checkbox"}
                            //  defaultChecked={getValues('ordered_products').findIndex(x => x.product_id === item.id) != -1?true:false}
                            checked={checkedIds.includes(item.id)}
                            onChange={(e) => {
                              productOnChange(e, item, item.variants);
                            }} /></td>
                          <td style={{ width: '15%', textAlign: 'center' }}><img width={20}
                            src={item.url} />
                          </td>
                          <td style={{ textAlign: 'left' }}>{item.name}</td>
                        </tr>
                        <tr>
                          <td colSpan={3} style={{
                            paddingTop: 0,
                            paddingRight: 25,
                            paddingLeft: 25,
                            border: "none"
                          }}>
                            <table className="table" style={{ width: '100%', marginTop: 0 }}>
                              <tbody>
                                {item.variants.map((itemVariant, v) => <tr key={v}>
                                  <td style={{ width: '5%' }}><input ref={ref => {
                                    variantRef.current[itemVariant.id] = ref;

                                  }} disabled={itemVariant.is_out_of_stock}
                                    // defaultChecked={getValues('ordered_products').findIndex(x => x.variant_id === itemVariant.id) != -1?true:false} 
                                    checked={checkedIds.includes(itemVariant.id)}
                                    type={"checkbox"} onChange={(e) => {
                                      variantOnChange(e, item, itemVariant);
                                    }} /></td>

                                  <td style={{
                                    width: '50%',
                                    textAlign: 'left'
                                  }}>{itemVariant.variant_name}</td>
                                  <td style={{ textAlign: 'right' }}>{itemVariant.stock}</td>
                                  <td style={{ textAlign: 'right' }}>£ {itemVariant.price}</td>
                                </tr>)}
                              </tbody>

                            </table>
                          </td>
                        </tr>

                      </Fragment>
                    )}
                  </tbody>
                </table>
              </InfiniteScroll>
            </div>
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col sm="12">
                <div className='float-sm-right '>
                  <button onClick={() => {
                    addOrderedProducts();
                    trigger('ordered_products');
                    closDialog();
                  }} className="btn  btn-primary"> Add
                  </button>
                </div>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </div>
    </Fragment>
  )
}

export default SearchProductModal