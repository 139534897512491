import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {toast} from "react-toastify";
import { getEuOrderApi, reCreateEUOrderApi } from '../../../services/euOrder';

export const getEuOrder = createAsyncThunk('order/euOrder',async(data, {dispatch})=>{
    const response = await getEuOrderApi(data);
    dispatch(setEUOrderTableRowCount(response.response.count))
    return response.response.data;
});

export const reCreateEuOrder = createAsyncThunk('order/reCreateEuOrder',async(data, {dispatch})=>{
    const response = await reCreateEUOrderApi(data);
    return response.response;
});

const euOrderAdapter = createEntityAdapter({});
export const {selectAll: selectEuOrders, selectById: euOrderById} = euOrderAdapter.getSelectors(
    state => state.order.euOrder
);

const euOrderSlice = createSlice({
    name: 'euOrder',
    initialState: euOrderAdapter.getInitialState({
        euOrderTableRow: 0, 
    }),
    reducers: {
        setEUOrderTableRowCount: (state, action) => {
            state.euOrderTableRow = action.payload;
        },
    },
    extraReducers: {
        [getEuOrder.fulfilled]: euOrderAdapter.setAll,
    }
});

export const {setEUOrderTableRowCount} = euOrderSlice.actions;

export default euOrderSlice.reducer;