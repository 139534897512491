import React from 'react';
import { PopoverBody } from 'reactstrap';

import {
    formatter, formatterDecimal
} from "../../../../utils/common";

import PopoverFormula from './PopoverFormula';

const PartnerBreakdown = (props) => {

    const {
        data,
        loading,
        getPercentage
    } = props;

    // Calculate totals
    const totalGrossSales = data.partnerItems.reduce((sum, row) => sum + row.gross_sales, 0);
    const totalDiscounts = data.partnerItems.reduce((sum, row) => sum + row.discount, 0);
    const totalReturns = data.partnerItems.reduce((sum, row) => sum + row.total_return, 0);
    const totalNetSales = data.partnerItems.reduce((sum, row) => sum + row.net_sales, 0);
    const totalShipping = data.partnerItems.reduce((sum, row) => sum + row.shipping_charges, 0);
    const totalShippingRate = data.partnerItems.reduce((sum, row) => sum + row.shipping_rate, 0);
    const totalShippingRateReturn = data.partnerItems.reduce((sum, row) => sum + row.shipping_rate_return, 0);    
    const totalTaxes = data.partnerItems.reduce((sum, row) => sum + row.total_tax, 0);
    const totalTotalSales = data.partnerItems.reduce((sum, row) => sum + row.total_sales, 0);
    const totalCost = data.partnerItems.reduce((sum, row) => sum + row.total_mp_cost, 0);
    const totalCommissions = data.partnerItems.reduce((sum, row) => sum + row.commission_amount, 0);
    const totalAdditionalCommission = data.partnerItems.reduce((sum, row) => sum + row.additional_commission_amount, 0);
    const totalGrossProfit = data.partnerItems.reduce((sum, row) => sum + row.gross_profit, 0);
    const totalAgentCommission = data.partnerItems.reduce((sum, row) => sum + row.agent_commission_amount, 0);

    return (
        <div className="card ribbon-wrapper">
            {loading && (
                <div className="card-loading">
                    <i className="fa fa-spinner fa-lg spinner" />
                </div>
            )}

            <div className="card-body">
                <h6 className="ribbon ribbon-clip h6 ribbon-dark">Breakdown of Partner Summary</h6>

                <div className="table-responsive table-summary">
                    <div className="table-wrapper">
                        <table className="table table-bordered table-hover table-striped">
                            <thead>
                                <tr>
                                    <th className="font-weight-bold">Partner</th>
                                    <th className="font-weight-bold text-center">Gross Sales</th>
                                    <th className="font-weight-bold text-center">Discounts</th>
                                    <th className="font-weight-bold text-center">Returns</th>
                                    <th className="font-weight-bold text-center">
                                        Net Sales

                                        <PopoverFormula className="ml-1" id="partnerNetSalesFormla">
                                            <PopoverBody>
                                                <h6>Net Sales Formula</h6>
                                                <p>Net Sales = Gross Sales - Discounts - Returns</p>
                                            </PopoverBody>
                                        </PopoverFormula>
                                    </th>
                                    <th className="font-weight-bold text-center">Shipping Income</th>
                                    <th className="font-weight-bold text-center">
                                        Taxes

                                        <PopoverFormula className="ml-1" id="partnerTaxFormula">
                                            <PopoverBody>
                                                <h6>Taxes Formula</h6>
                                                <p>Taxes = Tax Percentage * Net Sales / 100</p>
                                            </PopoverBody>
                                        </PopoverFormula>
                                    </th>
                                    <th className="font-weight-bold text-center">
                                        Total Sales

                                        <PopoverFormula className="ml-1" id="partnerTotalSalesFormla">
                                            <PopoverBody>
                                                <h6>Total Sales Formula</h6>
                                                <p>Total Sales = Net Sales + Shipping + Taxes</p>
                                            </PopoverBody>
                                        </PopoverFormula>
                                    </th>
                                    <th className="font-weight-bold text-center">Product Cost</th>
                                    <th className="font-weight-bold text-center">Shipping Cost (Sale)</th>
                                    <th className="font-weight-bold text-center">Shipping Cost (Return)</th>
                                    <th className="font-weight-bold text-center">Commissions</th>
                                    <th className="font-weight-bold text-center">Additional Charges</th>
                                    <th className="font-weight-bold text-center">Agent Commissions</th>
                                    <th className="font-weight-bold text-center">
                                        Gross Profit

                                        <PopoverFormula className="ml-1" id="partnerTotalSalesFormula">
                                            <PopoverBody>
                                                <h6>Gross Profit Formula</h6>
                                                <p>Gross profit = Net Sales + Shipping - Product Cost of goods sold - Shipping Cost (Sale) - Shipping Cost (Return) - Commission - Additional Charges - Agent Commission</p>
                                            </PopoverBody>
                                        </PopoverFormula>
                                    </th>
                                    <th className="font-weight-bold text-center">
                                        Gross Margin

                                        <PopoverFormula className="ml-1" id="partnerGrossMarginFormla">
                                            <PopoverBody>
                                                <h6>Gross Margin Formula</h6>
                                                <p>Gross Margin = 100 * Gross profit / (Net sales + Shipping)</p>
                                            </PopoverBody>
                                        </PopoverFormula>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.partnerItems.map((row, index) => (
                                    <tr key={index}>
                                        <td>{row.partner_name}</td>
                                        <td className='text-right'>{formatter.format(row.gross_sales)}</td>
                                        <td className='text-right'>{formatter.format(row.discount)}</td>
                                        <td className='text-right'>{formatter.format(row.total_return)}</td>
                                        <td className='text-right'>{formatter.format(row.net_sales)}</td>
                                        <td className='text-right'>{formatter.format(row.shipping_charges)}</td>
                                        <td className='text-right'>{formatter.format(row.total_tax)}</td>
                                        <td className='text-right'>{formatter.format(row.total_sales)}</td>
                                        <td className='text-right'>{formatter.format(row.total_mp_cost)}</td>
                                        <td className='text-right'>{formatter.format(row.shipping_rate)}</td>
                                        <td className='text-right'>{formatter.format(row.shipping_rate_return)}</td>
                                        <td>
                                            <span className='commission-amount'>{formatter.format(row.commission_amount)}</span>
                                            <span className='commission-percentage'>{formatterDecimal.format(row.commission_percentage)}%</span>
                                        </td>
                                        <td>
                                            <span className='commission-amount'>{formatter.format(row.additional_commission_amount)}</span>
                                            <span className='commission-percentage'>{formatterDecimal.format(row.additional_commission_percentage)}%</span>
                                        </td>
                                        <td>
                                            <span className='commission-amount'>{formatter.format(row.agent_commission_amount)}</span>
                                            <span className='commission-percentage'>{formatterDecimal.format(row.agent_commission_percentage)}%</span>
                                        </td>
                                        <td className='text-right'>{formatter.format(row.gross_profit)}</td>
                                        <td className='text-right'>
                                            {formatterDecimal.format((row.net_sales + row.shipping_charges) != 0 ? (100 * row.gross_profit / (row.net_sales + row.shipping_charges)) : 0)}%
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr style={{ fontWeight: "bold" }}>
                                    <td>Total</td>
                                    <td className='text-right'>{formatter.format(totalGrossSales)}</td>
                                    <td className='text-right'>{formatter.format(totalDiscounts)}</td>
                                    <td className='text-right'>{formatter.format(totalReturns)}</td>
                                    <td className='text-right'>{formatter.format(totalNetSales)}</td>
                                    <td className='text-right'>{formatter.format(totalShipping)}</td>
                                    <td className='text-right'>{formatter.format(totalTaxes)}</td>
                                    <td className='text-right'>{formatter.format(totalTotalSales)}</td>
                                    <td className='text-right'>{formatter.format(totalCost)}</td>
                                    <td className='text-right'>{formatter.format(totalShippingRate)}</td>
                                    <td className='text-right'>{formatter.format(totalShippingRateReturn)}</td>
                                    <td className='text-right'>{formatter.format(totalCommissions)}</td>
                                    <td className='text-right'>{formatter.format(totalAdditionalCommission)}</td>
                                    <td className='text-right'>{formatter.format(totalAgentCommission)}</td>
                                    <td className='text-right'>{formatter.format(totalGrossProfit)}</td>
                                    <td className='text-right'>
                                        {formatterDecimal.format((totalNetSales + totalShipping) != 0 ? (100 * totalGrossProfit / (totalNetSales + totalShipping)) : 0)}%
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PartnerBreakdown;
