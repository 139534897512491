import React, {Fragment, useEffect, useRef, useState} from 'react';
import ModalFormSubmit from "../../../share-components/form/modalFormSubmit";
import * as yup from "yup";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {Col, FormGroup, Input, Label, Row, Button} from "reactstrap";
import {setCostAdjustmentDialog} from "../store/purchaseOrderSlice";
import { useHistory } from "react-router-dom";
import { watch } from 'fs';



const defaultValues = {
    name: '',
    costAmount: '',
};

export const schema = yup.object().shape({
    costRowData: yup.array().of(
        yup.object().shape({
            name: yup.string().required('Name is required field').typeError('Name is required field'),
            costAmount: yup.number().required('Amount is required field').typeError('Amount is required field').min(1, 'Amount must be greater than zero.'),
        })
    ),
});


function OrderCost(props) {
    const [adjustmentFields, setAdjustmentFields] = useState([]);

    const initialData = () => {
        return {
            costRowData: adjustmentFields
        }
    };
    const dispatch = useDispatch();
    const draftOrderState = useSelector(({order})=> order.draftOrder);
    const {orderSetValue, orderTrigger, orderGetValues, purchaseOrderState} = props;
    const [buttonDisable, setButtonDisable] = useState(false);
    const [isOther, setIsOther] = useState([]);
    const {handleSubmit, formState, reset, control, register, setValue, handleChange, trigger, getValues, watch} = useForm({
        mode: 'onChange',
        defaultValues: initialData(),
        resolver: yupResolver(schema)
    });
    const {errors} = formState;
    const inputRefs = [];
    const { fields, append, remove, update } = useFieldArray({ name: 'costRowData', control });

    const adjustmentType = [
        { id: 1, name: 'Customs duties' },
        { id: 2, name: 'Discount' },
        { id: 3, name: 'Foreign transaction fee' },
        { id: 4, name: 'Freight fee' },
        { id: 5, name: 'Insurance' },
        { id: 6, name: 'Rush fee' },
        { id: 7, name: 'Surcharge' },
        { id: 8, name: 'Other' },
    ];

	const [adjustTypes, setAdjustTypes] = useState(adjustmentType);
    const watchedFormData = watch(); // Watch the entire form

    const onSubmitForm = (dialog, data) => {
		// setIsDisable(true);
        
		const formData = {
			'costAdjustment': fields,
		};
       
		setButtonDisable(true);
        orderSetValue('cost', formData);
        orderTrigger('cost');
        setButtonDisable(false);
        closeDialog();
	};

  
    const closeDialog = () => {
        const obj = {props: {isOpen: false}, type: 'new', data: ""};
        dispatch(setCostAdjustmentDialog(obj));
    }

    const handleOnTypeChange = (binData, index) => {
		const selectedName = binData ? binData.name : '';
		const selectedId = binData ? binData.id : '';
		// const binItems = [...adjustmentFields];
        const updatedFields = [...fields];

        if(selectedName == 'Other'){
            updatedFields[index].isText = true;
            updatedFields[index].name = '';
		    updatedFields[index].typeId = '';
        }else{
            updatedFields[index].name = selectedName;
		    updatedFields[index].typeId = selectedId;

            const result = adjustTypes.filter((item) => item.id !== binData.id);
            setAdjustTypes(result);
            // update(index, {name: selectedName, typeId: selectedId});
            // setValue(`name[${index}]`, selectedName || '');
		    // trigger(`name[${index}]`);  
        }

        // setTimeout(
        //     () => {
                update(index, { ...updatedFields[index] });
            // }, 1000);
		// setAdjustmentFields(binItems);
	}

    const handleOnTypeText = (binData, index) => {
		const selectedName = binData;
		const selectedId = '';
		const binItems = [...fields];
        console.log(binData);
        // update(index, {name: selectedName, typeId: selectedId});
        binItems[index].name = selectedName;
        binItems[index].id = selectedId;
		binItems[index].isText = true;
        // setTimeout(
        //     () => {
                update(index, { ...binItems[index] });
        // }, 5000);
		// setAdjustmentFields(binItems);
		// setValue(`costRowData.[${index}].name`, selectedName);
		// trigger(`costRowData.[${index}].name`);        
	}

	const handleOnCostChange = (value, index) => {
		const binItems = [...fields];
		binItems[index].costAmount = value > 0 ? Number(value) : 0;
		// setAdjustmentFields(binItems);
		// setValue(`costAmount[${index}]`, binItems[index]['costAmount']);
		// trigger(`costAmount[${index}]`);	
        
        // update(index, {costAmount: value > 0 ? Number(value) : 0});
        // setTimeout(
        //     () => {
                update(index, { ...binItems[index] });
            // }, 5000);

	}

    const handleAddRow = () => {
		// setAdjustmentFields([...adjustmentFields, { id: '', name: '', costAmount: '', isNew: true, isText: false}]);
		// setValue(`name[${adjustmentFields.length}]`, '');
  		// setValue(`costAmount[${adjustmentFields.length}]`, '');

        append({ typeId: '', name: '', costAmount: '', isNew: true, isText: false});
	};

	const handleDeleteRow = (indexToDelete) => {
		const updatedData = [...fields];

        const foundElement = updatedData[indexToDelete];
        if(foundElement && foundElement['isText'] != true){
            const orderArr = [...adjustTypes, { id: foundElement['typeId'], name: foundElement['name']}];
            orderArr.sort(function(a, b) { 
                return ( a.id - b.id);
            });
            setAdjustTypes(orderArr);
        }
		// updatedData.splice(indexToDelete, 1);
		// setAdjustmentFields(updatedData);
        // setValue(`name[${indexToDelete}]`, '');
        // setAdjustmentFields(result);
		// updatedData.forEach((item, index) => {
        //     console.log(item);
        //     reset(item);
		// });

        remove(indexToDelete);
	};

    useEffect(() => {
		// const updatedData = {...adjustmentFields};
        const getData = orderGetValues('cost')?orderGetValues('cost.costAdjustment'):'';
		const binItems = [];
        const Ids = [];

        console.log(getData);
		if (getData && getData.length > 0) {
            // adjustmentFields.forEach((bin, index) => {
			// 	reset(bin);
			// });
			getData.map((binData, key) => {
				// binItems.push({typeId:binData.typeId, name: binData.name, costAmount: binData.costAmount, isText: binData.isText });
                // setValue(`name[${key}]`, binData.name);
                // trigger(`name[${key}]`);
                Ids.push(binData.typeId);
                append({typeId:binData.typeId, name: binData.name, costAmount: binData.costAmount, isText: binData.isText });
                
			});


        }
		// if (selectedStockType && selectedStockType.id === 2) {
		// 	setIsDisable(true);
		// 	binItems.push({ id: '', name: '', qty: '', isNew: true});
		// }
		// setAdjustmentFields(binItems);

        const filteredItems = filterByIds(adjustTypes, Ids);
        setAdjustTypes(filteredItems);
	}, [props]);

    const filterByIds = (array, idsToFilter) => {
        return array.filter(item => !idsToFilter.includes(item.id));
      };


    function costValueRow() {
        return fields.map((cost, index) => {
            return(
			<Row className='mb-3' key={cost.id}>
                <Col md="5">
                    {/* {bin.isText ? 
                        <input type="text" value={bin.name} name={`name[${index}]`} 
                        {...register(`name[${index}]`)}
                        onChange={(e) => {
                            handleOnTypeText(e.target.value, index);
                        }}
                            className={`form-control ${errors.name && errors.name[index] ? 'is-invalid' : ''}`} placeholder="Enter type name"/> 
                            :
                        <Select
                        name={`name[${index}]`}
                        {...register(`name[${index}]`)}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        options={adjustTypes}
                        value={bin.name ? {value: bin.id, name: bin.name}: null}
                        // value={adjustmentType.find((option) => option.name === bin.name)} 
                        onChange={(e) => {
                        handleOnTypeChange(e,index);
                        }}
                        className={` ${errors.name && errors.name[index] ? 'is-invalid' : ''}`}
                    />                    
                    } */}

                            <Controller
                                name={`costRowData.[${index}].name`}
                                control={control}
                                defaultValue={cost.name}                                
                                render={({ field }) => (
                                    <>
                                    {cost.isText ? <input type="text"   value={cost.name}   {...field}
                                    onChange={(e) => {
                                        field.onChange(e);
                                        setTimeout(
                                        () => {
                                            handleOnTypeText(e.target.value, index);
                                        }, 3000);
                                        
                                    }}
                                    className={`form-control ${errors.costRowData?.[index]?.name ? 'is-invalid' : ''}`}  placeholder="Enter type name"
                                    /> :
                                    <Select
                                        name={`costRowData.[${index}].name`}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        options={adjustTypes}
                                        value={cost.name ? {value: cost.id, name: cost.name}: null}
                                        onChange={(newValue) => {
                                            handleOnTypeChange(newValue, index);
                                            
                                        }}
                                        className={` ${errors.costRowData?.[index]?.name ? 'is-invalid' : ''}`}
                                    />      }
                                    </>
                                )}
                            />    
                    <div className="invalid-feedback">{errors.costRowData?.[index]?.name?.message}</div>
                </Col>
                <Col md="5">
                    {/* <input
                    value={bin.costAmount}
                    name={`costAmount[${index}]`}
                    placeholder="Enter costAmount"
                    type="number"
                    {...register(`costAmount[${index}]`)}
                    onChange={(e) => {
                        handleOnCostChange(e.target.value, index);
                    }}
                    className={`form-control ${errors.costAmount && errors.costAmount[index] ? 'is-invalid' : ''}`}
                    /> */}

                            <Controller
                                name={`costRowData.[${index}].costAmount`}
                                control={control}
                                defaultValue={cost.costAmount}                                
                                render={({ field }) => (
                                    <>
                                    <input type="number"      
                                     value={cost.costAmount}
                                     {...field}
                                    onChange={(e) => {
                                        field.onChange(e);
                                        setTimeout(
                                            () => {
                                                handleOnCostChange(e.target.value, index);
                                            }, 500);
                                    }}           
                                    className={`form-control ${errors.costRowData?.[index]?.costAmount ? 'is-invalid' : ''}`}  placeholder="Enter type name"
                                    />
                                    </>
                                )}
                            />   
                    <div className="invalid-feedback">{errors.costRowData?.[index]?.costAmount?.message}</div>
                </Col>
                <Col md="1">
                    <i
                    className="fa fa-minus-circle text-danger"
                    style={{ marginTop: '12px' }}
                    onClick={() => handleDeleteRow(index)}
                    ></i>
                </Col>
			</Row>
            )
		});
    }

  return (
    <>
     <ModalFormSubmit
        // defaultValues={defaultValues}
        schema={schema}
        onSubmitForm={onSubmitForm}
        reset={reset}
        formState={formState}
        handleSubmit={handleSubmit}
        title='Cost'
        selector='cost'
        isUseSelector
        dialog={purchaseOrderState.costDialogProps}
        closeDialog={closeDialog}
        buttonDisable={buttonDisable}
    >
        <div className='mt-3'>
            <Row className='mb-3'>
                <Col md="6">
                    <Button className="btn btn-sm btn-primary" onClick={handleAddRow}>Add Adjustment</Button>
                </Col>
            </Row>
            {costValueRow()}
        </div>

    </ModalFormSubmit>
        
    </>
    
  )
}

export default OrderCost