import React, { Fragment, useEffect, useRef, useState } from "react";
import { Badge, Card, CardBody, Row, Col, Label, Media, Alert, Button } from "reactstrap";
import DropZone from "../../../../../share-components/upload/dropzone";
import { useDispatch } from "react-redux";
import dragula from 'react-dragula';
import {
    addProductImage,
    deleteProductImage,
    sortProductImage,
    updateProductImageAlt,
    addPlatformImage,
    removePlatformImage
} from "../../../store/prodcutSlice";
import { getFileExtension } from "../../../../../utils/common";


import ReactImageVideoLightbox from "react-image-video-lightbox";
import Select from "react-select";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import "./productImage.scss"; // Import the SCSS file

const ProductImage = (props) => {
    const { id } = useParams();
    const { productState, setValue, trigger, getValues, onConfirmSendImage, currentUser, errors, register } = props;
    const dispatch = useDispatch();

    let selectAllRef = useRef();
    let imageAltRef = useRef([]);
    
    const [image, setimage] = useState({ pictures: [] })
    const [imagePreloader, setImageLoader] = useState(false);
    const [isDragInit, setIsDragInit] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);

    const [selectedImageIds, setSelectedImageIds] = useState([]);
    const [selectedPlatform, setSelectedPlatform] = useState(null);

    const [viewFile, setViewFile] = useState([]);
    const [dragulaKey, setDragulaKey] = useState(0);    

    const initilindex = { index: 0, isOpen: false }
    const [photoIndex, setPhotoIndex] = useState(initilindex)
    const [selectedColour, setSelectedColour] = useState(0);

    const [singlePFBtnDisable, setSinglePFBtnDisable] = useState([]);
    const [allPFAddBtnDisable, setAllPFAddBtnDisable] = useState(false);
    const [allPFRemoveBtnDisable, setAllPFRemoveBtnDisable] = useState(false);

    const onDrop = (pictureFiles, pictureDataURLs) => {
        setimage({
            ...image, pictureFiles
        });
    }
    const onChangeFile = (e, error) => {

        if(error.length>0){
            toast.error("one or many of the uploaded file size more than 20MB for image and 100MB for video", {
                position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            if (e.length > 0) {
                let fileSizeError = false;
                e.forEach((file) => {
                    if(/^image\//.test(file.type) && file.size > 20971520){
                        fileSizeError = true;
                    }else if(file.type == 'video/mp4' && file.size > 104857600){
                        fileSizeError = true;
                    }
                });
                if(fileSizeError){
                    toast.error("one or many of the uploaded file size more than 20MB for image and 100MB for video", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    return;
                }

                setImageLoader(true);
                dispatch(addProductImage({
                    id: getValues('id'),
                    image: e,
                    shopify_product_id: getValues('shopify_product_id')
                })).then(res => {
                    if (!res.error) {
                        setValue('images', res.payload);
                        trigger('images');
                        setDragulaKey(dragulaKey + 1);
                        setImageLoader(false);
                    }
                    ;
                });
            }
        }
    };

    let dragulaDecoratorRight = (componentBackingInstance) => {
        if (componentBackingInstance) {
            if (!isDragInit) {
                let imageContainer = dragula([componentBackingInstance], {
                    isContainer: function (el) {
                        return false; // only elements in drake.containers will be taken into account
                    },
                    moves: function (el, source, handle, sibling) {
                        return true; // elements are always draggable by default
                    },
                    accepts: function (el, target, source, sibling) {
                        return true; // elements can be dropped in any of the `containers` by default
                    },
                    invalid: function (el, handle) {
                        return false; // don't prevent any drags from initiating by default
                    },
                });
                imageContainer.on('drop', (el, target, source, sibling) => {
                    const cardOrder = source.childNodes;
                    let imageIds = [];
                    cardOrder.forEach(item => {
                        imageIds.push(item.getAttribute('data-id'))
                    });
                    dispatch(sortProductImage({ id: getValues('id'), image_ids: imageIds }));
                });
                setIsDragInit(true);
            }
        }
    };

    const deleteImage = (item) => {
        dispatch(deleteProductImage(item.id)).then(res => {
            if (!res.error) {
                setValue('images', res.payload);
                trigger('images');
                setDragulaKey(dragulaKey + 1);
                setIsDragInit(false);
            }
        });
    }

    // const onMovePrev = () => {
    //     const prev = (photoIndex.index + getValues('images').length - 1) % getValues('images').length
    //     setPhotoIndex({...photoIndex, index: prev})
    //     imageAltRef.current[photoIndex.index].value = getValues('images')[prev].image_alt;
    // }
    const onMoveNext = (index) => {
        imageAltRef.current.value = getValues('images')[index].image_alt;
        setSelectedColour(getValues('images')[index].colour_id);
    }
    const updateImageAltText = (index) => {
        let imageText = imageAltRef.current;
        getValues('images')[photoIndex.index].image_alt = imageText.value;
        setIsUpdated(true);

    }
    const setImageColour = (index, item) => {
        getValues('images')[photoIndex.index].colour_id = item.id;
        getValues('images')[photoIndex.index].colour = item;
        setSelectedColour(item.id);
        setIsUpdated(true);
    }

    const updateAltTagWithColour = () => {
        if (isUpdated) {
            const images = getValues('images').map(elem => (
                {
                    id: elem.id,
                    colour_id: elem.colour_id,
                    image_alt: elem.image_alt
                }
            ));
            dispatch(updateProductImageAlt({ images: images })).then(res => {
                if (!res.error) {
                    setIsUpdated(false);
                }
            });
        }
    }

    useEffect(() => {
        if (photoIndex.isOpen) {
            setSelectedColour(getValues('images')[photoIndex.index].colour_id);
            imageAltRef.current.value = getValues('images')[photoIndex.index].image_alt;
        }
    }, [photoIndex.index, photoIndex.isOpen]);

    useEffect(() => {
        let dataImage = [];
        getValues('images').map((item, i) => {
            dataImage.push({
                url: item.image_path,
                type: getFileExtension(item.image_path) == ".mp4" ? "video" : "photo",
                altTag: "some image",
            });
        });
        setViewFile(dataImage);
    }, [getValues('images')]);

    const handleApplyButtonClick = () => {
        setAllPFAddBtnDisable(true);
        const data = {
            id,
            imageIds: selectedImageIds,
            platformId: selectedPlatform ? selectedPlatform.id : null,
        };

        dispatch(addPlatformImage({ ...data })).then(res => {
            setAllPFAddBtnDisable(false);
            if (!res.error) {
                setValue('images', res.payload);
                trigger('images');
                setDragulaKey(dragulaKey + 1);
                setIsDragInit(false);
            }
        });
    };

    const handleRemoveButtonClick = () => {
        setAllPFRemoveBtnDisable(true);
        const data = {
            id,
            imageIds: selectedImageIds,
            platformId: selectedPlatform ? selectedPlatform.id : null,
        };

        dispatch(removePlatformImage({ ...data })).then(res => {
            setAllPFRemoveBtnDisable(false);
            if (!res.error) {
                setValue('images', res.payload);
                trigger('images');
                setDragulaKey(dragulaKey + 1);
                setIsDragInit(false);
            }
        });
    };

    const selectAllOnChange = (e) => {
        selectImageChange(e.target.checked);
    }

    const selectImageChange = (isChecked) => {
        const _selectedImageIds = [...selectedImageIds];
        getValues('images').map(function (item, key) {
            let imgIndex = _selectedImageIds.findIndex(aItem => aItem === item.id);
            if(isChecked) {
                if (imgIndex == -1) {
                    _selectedImageIds.push(item.id);
                }
            }else {
                if (imgIndex != -1) {
                    _selectedImageIds.splice(imgIndex, 1);
                }
            }

        });
        setSelectedImageIds(_selectedImageIds);
    }

    const singleImageChange = (e, imageId) => {
        const _selectedImageIds = [...selectedImageIds];
        let imgIndex = _selectedImageIds.findIndex(aItem => aItem === imageId);
        if (imgIndex != -1) {
            if (!e.target.checked) {
                _selectedImageIds.splice(imgIndex, 1);
            }
        } else {
            _selectedImageIds.push(imageId);
        }
        setSelectedImageIds(_selectedImageIds);
    }

    const singleImageRemove = (imageId, platformId) => {
        const _key = imageId + "_" + platformId;
        const _singlePFBtnDisable = [...singlePFBtnDisable];
        let imgIndex = _singlePFBtnDisable.findIndex(aItem => aItem === _key);
        if (imgIndex == -1) {            
            _singlePFBtnDisable.push(_key);
        }
        setSinglePFBtnDisable(_singlePFBtnDisable);
        setAllPFRemoveBtnDisable(true);

        const data = {
            id,
            imageIds: [imageId],
            platformId: platformId,
        };        

        dispatch(removePlatformImage({ ...data })).then(res => {
            setAllPFRemoveBtnDisable(false);
            const __singlePFBtnDisable = [...singlePFBtnDisable];
            let imgIndex = __singlePFBtnDisable.findIndex(aItem => aItem === _key);
            if (imgIndex != -1) {
                __singlePFBtnDisable.splice(imgIndex, 1);
            }
            setSinglePFBtnDisable(__singlePFBtnDisable);

            if (!res.error) {
                setValue('images', res.payload);
                trigger('images');
                setDragulaKey(dragulaKey + 1);
                setIsDragInit(false);
            }
        });
    }

    const checkSinglePFBtnDisable = (imageId, platformId) => {
        const _key = imageId + "_" + platformId;
        return singlePFBtnDisable.includes(_key);
    }

    return (
        <Fragment>
            <Card>
                <CardBody>
                    <div className="ribbon ribbon-clip ribbon-warning ribbon-left mb-5">Images</div>

                    <div className="row mb-3 mt-3">
                        <div className="col-md-8">
                            <div className="row align-items-center">
                                <Col md="auto" className="mb-3">
                                    <button
                                        type="button"                                        
                                        className="btn btn-outline-primary text-left btn-square"
                                        onClick={() => {
                                            selectAllRef.current.click()
                                        }}>
                                        <input
                                            className="mr-2"
                                            style={{ pointerEvents: "none" }}
                                            onChange={selectAllOnChange}
                                            ref={(ref) => {
                                                selectAllRef.current = ref
                                            }}
                                            type="checkbox" />
                                        Select All
                                        ({selectedImageIds.length})
                                    </button>
                                </Col>

                                <Col md="3" className="mb-3 pl-md-0">
                                    <Select
                                        getOptionLabel={(option) => option.name}
                                        defaultValue={getValues('platform_id')}
                                        placeholder="Select Platform"
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 100 }) }}
                                        getOptionValue={(option) => option.id}
                                        {...register("platform_id")}
                                        value={selectedPlatform}
                                        onChange={(selectedOption) => setSelectedPlatform(selectedOption)}
                                        className={` ${errors.platform_id ? 'is-invalid' : ''}`}
                                        options={productState.platforms}
                                    />

                                    <div className="invalid-feedback">{errors.platform_id?.message}</div>
                                </Col>

                                <Col md="auto" className="mb-3 pl-md-0">
                                    <Button
                                        onClick={handleApplyButtonClick}
                                        className="mr-3"
                                        color="success"
                                        type="button"
                                        disabled={(selectedImageIds.length == 0 && !allPFAddBtnDisable) || allPFAddBtnDisable || allPFRemoveBtnDisable}>
                                        {allPFAddBtnDisable && <i className="fa fa-spinner fa-spin mr-2"></i>}
                                        Apply
                                    </Button>

                                    <Button
                                        onClick={handleRemoveButtonClick}
                                        color="danger"
                                        type="button"
                                        disabled={(selectedImageIds.length == 0 && !allPFRemoveBtnDisable) || allPFRemoveBtnDisable || allPFAddBtnDisable}>
                                        {(allPFRemoveBtnDisable) && <i className="fa fa-spinner fa-spin mr-2"></i>}
                                        Remove
                                    </Button>
                                </Col>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <Alert color="warning outline">
                                <span>Videos can be uploaded after publish</span>
                            </Alert>
                        </div>
                    </div>

                    <div className="form-row mb-3">
                        <div ref={dragulaDecoratorRight} style={{ display: "contents" }} key={dragulaKey}>
                            {getValues('images').map((item, i) => <Col md={2} index={i} data-id={item.id} key={i}>
                                <Card>
                                    <div className="product-box">
                                        <div className="product-img">
                                            <div>
                                                <Badge
                                                    key={i}
                                                    onClick={() => {
                                                        setImageColour(photoIndex.index, item);
                                                    }}
                                                    className={`image-color badge bg-light text-dark`}>
                                                    {item.colour ? item.colour.name : "None"}
                                                </Badge>
                                            </div>
                                            <div className="image-crop-center" style={{ height: 200 }}>
                                                {getFileExtension(item.image_path) == ".mp4" ? <>
                                                    <video height="200" width={350} autoPlay={true} muted={true}
                                                        loop={true}>
                                                        <source src={item.image_path} type="video/mp4" />
                                                    </video>
                                                </> : <>
                                                    <img className="img-fluid scale-image-6" src={item.image_path} alt="Product Image" />
                                                </>}
                                            </div>
                                            <div className="product-hover">
                                                <ul>
                                                    <li>
                                                        <Button color="default" onClick={() => {
                                                            setPhotoIndex({ ...photoIndex, index: i, isOpen: true })
                                                        }}>
                                                            <i className="icon-eye"></i>
                                                        </Button>
                                                    </li>
                                                    <li>
                                                        <Button color="red" onClick={() => {
                                                            deleteImage(item)
                                                        }}>
                                                            <i className="icon-trash"></i>
                                                        </Button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="product-platform-list">
                                            <input
                                                style={{ marginRight: '10px' }}
                                                onChange={(e) => {
                                                    singleImageChange(e, item.id);
                                                }}
                                                id={item.id}
                                                checked={selectedImageIds.includes(item.id)}
                                                type="checkbox" />

                                            {item.platforms ? item.platforms.map((platform, pi) => <span key={i + '_' + pi} className="badge-platform">
                                                <span className="platform-name">{platform.name}</span>
                                                {checkSinglePFBtnDisable(item.id, platform.id) ? <>
                                                <span className="platform-remove disabled"><i className="fa fa-spinner fa-spin"></i></span>
                                                </> : <>
                                                <span
                                                    className="platform-remove"
                                                    onClick={() => {
                                                        singleImageRemove(item.id, platform.id);
                                                    }}>
                                                    <i className="fa fa-remove"></i>
                                                </span>
                                                </>}
                                            </span>) : ''}
                                        </div>
                                    </div>
                                </Card>
                            </Col>)}
                        </div>
                        <Col md={2}>
                            <Card>
                                <div className="product-box">
                                    <div className="product-img pt-3 pb-3">
                                        {imagePreloader && <div className="loader-box">
                                            <div className="loader-2"></div>
                                        </div>}
                                        {!imagePreloader && <DropZone multiple
                                            // acceptFiles={getValues('shopify_product_id')?"image/*,.mp4":"image/*"}
                                            extension={getValues('shopify_product_id') ? "image/*,.mp4" : "image/*"}
                                            onChangeFile={onChangeFile}></DropZone>}
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </div>

                    {(currentUser.modules).includes('send-image') && <div className="form-row">
                        <div className="col-4 offset-8">
                            <Button onClick={onConfirmSendImage} className="pull-right  font-weight-bold mr-2" color="success"
                                type="button">Send Image
                            </Button>
                        </div>
                    </div>}
                </CardBody>
            </Card>

            {photoIndex.isOpen && (
                <div style={{ height: '100vh' }}>
                    <div className="lightbox-div">
                        <ReactImageVideoLightbox
                            title={"DAd"}
                            data={viewFile}
                            startIndex={photoIndex.index}
                            showResourceCount={true}
                            onCloseCallback={() => {
                                updateAltTagWithColour();
                                setPhotoIndex({ ...photoIndex, isOpen: false })
                            }}
                            onNavigationCallback={(currentIndex) => {
                                setPhotoIndex({ ...photoIndex, index: currentIndex })
                                onMoveNext(currentIndex);
                            }}
                        >

                        </ReactImageVideoLightbox>
                        <div className="p-5" style={{ zIndex: 300, bottom: 0, position: "fixed", right: 0 }}>
                            <div>
                                <input name="altText" type="text"
                                    placeholder="Alt Text"
                                    ref={(ref) => {
                                        imageAltRef.current = ref;
                                    }}
                                    onBlur={(e) => {
                                        updateImageAltText(photoIndex.index);
                                    }}
                                    className={`form-control`} />
                            </div>
                            <div className="pt-2">
                                {getValues('color').map((item, i) => <Badge key={i} onClick={() => {
                                    setImageColour(photoIndex.index, item);
                                }} className={`image-color badge bg-light text-dark ${selectedColour == item.id ? `active-image-color` : ""}`}>{item.name}</Badge>)}
                            </div>
                        </div>
                    </div>

                </div>
            )}

        </Fragment>
    )
};

export default ProductImage;

