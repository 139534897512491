export const paymentCalculation = (getValues) => {

    let subTotalPrice = 0;
    let discountAmount = 0;
    let shippingAmount = 0;
    let taxAmount = 0;
    let taxAmounts = 0;
    let costAllAmount = [];
    let costAllAmountTotal = 0; 
    let TotalPrice = 0;
    const discount = getValues('discount');
    const orders = getValues('ordered_products');
    const shipping = getValues('shipping');
    const cost = getValues('cost');
    const billingAddress = getValues('billingAddress');

    if (orders.length > 0) {
        orders.map(function (item, index) {
            if (typeof item.cost !== 'undefined' && typeof item.quantity !== 'undefined') {
                subTotalPrice += parseFloat(item.cost) * parseInt(item.quantity, 10);
            }
        });
    }

    if (orders.length > 0) {
        orders.map(function (item, index) {
            if (typeof item.tax != 'undefined') {
                const cost = parseFloat(item.cost);
                const quantity = parseFloat(item.quantity);
                const tax = parseFloat(item.tax);
                const itemTax = (cost * quantity * tax) / 100;
                taxAmount += itemTax;
            }
        });
    }

    if (orders.length > 0) {
        orders.map(function (item, index) {
            if (typeof item.total != 'undefined') {
                TotalPrice += parseFloat(item.total);
            }
           
        });
    }

    if (discount && typeof discount != 'undefined') {
        if (discount.discount_type == 1) {
            discountAmount = Number(discount.value);
        } else {
            discountAmount = subTotalPrice * (Number(discount.value) / 100);
        }
    }


    if (shipping) {
        if (shipping.shipping_type == 2) {
            shippingAmount = Number(shipping.price);
        }
    }

    // if (cost && cost.costAdjustment) {
    //     cost.costAdjustment.forEach((item) => {
    //         console.log("item.costAmount",item.costAmount);
    //         costAllAmount.push(Number(item.costAmount));
    //     });
    // }

    if (cost && cost.costAdjustment) {
        cost.costAdjustment.forEach((item) => {
            const costAmount = Number(item.costAmount);
            costAllAmountTotal += costAmount;
        });
    }

    let total = (TotalPrice + shippingAmount + costAllAmountTotal) - discountAmount;             
    return {
        subTotal: subTotalPrice.toFixed(2),
        total: total.toFixed(2),
        discountPrice: discountAmount.toFixed(2),
        shippingPrice: shippingAmount.toFixed(2),
        taxPrice: !isNaN(taxAmount)?taxAmount.toFixed(2):0,
        costAllAmounts: costAllAmountTotal.toFixed(2),

    }

}