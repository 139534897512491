// dashbaord

import Dashboard from "../components/dashboard/";

// starter kits
import Starterkits from "../components/starter-kits";
import User from "../components/manage-user/user/user";
import UserProfile from "../components/manage-user/user-profile/userProfile";
import ActivityLog from "../components/manage-user/activity-log/";
import UpdateSeller from "../components/seller/update-seller/updateSeller";
import AddSeller from "../components/seller/add-seller/addSeller";
import Seller from "../components/seller";
import Product from "../components/manage-product/product";
import Collection from "../components/manage-product/collection";

import ProductType from "../components/manage-product/product-setting/product-type/productType";
import ProductSize from "../components/manage-product/product-setting/product-size/productSize";
import ProductSeason from "../components/manage-product/product-setting/product-season/productSeason";
import ProductColor from "../components/manage-product/product-setting/product-color/productColor";
import ProductTag from "../components/manage-product/product-setting/product-tag/productTag";
import productCategory from "../components/manage-product/product-setting/product-category/productCategory";
import ProductStyle from "../components/manage-product/product-setting/product-style/productStyle";
import CompositionGroup from "../components/manage-product/product-setting/product-composition-group/compositionGroup";
import ProductComposition from "../components/manage-product/product-setting/product-composition/productComposition";
import sizeMatrixConfiguration from "../components/manage-product/product-setting/size-matrix-configuration/sizeMatrixConfiguration";
import Community from "../components/manage-product/product-setting/community/community";
import ProductFitType from "../components/manage-product/product-setting/product-fit-type/productFitType";
import ProductFitSizeCondition from "../components/manage-product/product-setting/product-fit-size-condition/productFitSizeCondition";
import ProductTypeTag from "../components/manage-product/product-setting/product-type-tag/productTypeTag";
import SellerStaff from "../components/seller/seller-staff/sellerStaff";
import AddSellerStaff from "../components/seller/seller-staff/addSellerStaff";

import EditProduct from "../components/manage-product/product/edit";
import Role from "../components/manage-user/role/role";
import Permission from "../components/manage-user/permission/permission";
import Agent from "../components/manage-user/agent/agent";
import ViewSeller from "../components/seller/view-seller/viewSeller";
import Order from "../components/manage-order/order/order";
import ReturnOrder from "../components/manage-order/return/create";
import Return from "../components/manage-order/return/index";
import AddTracking from "../components/manage-order/tracking";
import ViewOrder from "../components/manage-order/view-order/viewOrder";
import RefundOrder from "../components/manage-order/refund/index";
import ViewReturnOrder from "../components/manage-order/return/view";
import DraftOrder from "../components/manage-order/draft-order";
import SellerVat from "../components/seller/seller-vat/sellerVat";
import DraftOrderForm from "../components/manage-order/draft-order/form";
import ShippingZone from "../components/seller/shipping-zone/shippingZone";
import AddShippingZone from "../components/seller/shipping-zone/addShippingZone";
import PendingReturn from "../components/manage-order/pending-return";
import EditPendingReturn from "../components/manage-order/pending-return/edit";
import ViewPendingReturn from "../components/manage-order/pending-return/view";
import AlterationRequest from "../components/manage-order/alteration-request";
import EditAlterationRequest from "../components/manage-order/alteration-request/edit"
import ViewAlterationRequest from "../components/manage-order/alteration-request/view"
import SellerLead from "../components/leads/seller/sellerLead";
import UpdateSellerLead from "../components/leads/seller/updateSellerLead";
import BuyerLead from "../components/leads/buyer/buyerLead";
import UpdateBuyerLead from "../components/leads/buyer/updateBuyerLead";
import Inventory from "../components/manage-product/inventory";
import SaleOffer from "../components/manage-product/sale-offer";
import ViewSaleOffer from "../components/manage-product/sale-offer/view";
import SellerCommission from "../components/seller/commission/seller-commission";
import DetailCommission from "../components/seller/commission/detail-commission";
import Notifications from "../components/notification/notifications";
import PermissionError from "../components/error/permission/permissionError";
import BackInStock from "../components/backInStock/backInStock";
import SalesReport from "../components/report/salesReport";
import InvontoryReport from "../components/report/invontoryReport";
import BuyerCreate from "../components/leads/buyer/buyerCreate";
import LookBookPdf from "../components/manage-product/product/look-book/pdf";
import LookBook from "../components/manage-product/product/look-book";
import Checkout from "../components/manage-order/checkout";
import BackInStockReport from "../components/report/backInStockReport";
import AllNotification from "../components/notification/allNotification";
import ShippingSetting from "../components/manage-order/setting/shipping/shippingSetting";
import ReturnReasonSetting from "../components/manage-order/setting/return-reason/returnReasonSetting";
import Partner from "../components/partner";
import AddPartner from "../components/partner/add-partner/addPartner";
import UpdatePartner from "../components/partner/update-partner/updatePartner";
import ViewPartner from "../components/partner/view-partner/viewPartner";
import DispatchOrder from "../components/manage-order/dispatch-order";
import ExchangeOrder from "../components/report/exchangeOrder";
import VerifyOfferItems from "../components/report/verifyOfferItems";
import GeneralSetting from "../components/setting/generalSetting";
import GroupReturn from "../components/manage-order/group-return";
import GroupReturnCreate from "../components/manage-order/group-return/create/groupReturnCreate";

import ProductList from "../components/seller/product-list";
import SellerProductList from "../components/seller/seller-product-list";
import SellerList from "../components/seller/seller-list";
import PendingOrder from "../components/manage-order/pending-order";
import PendingCancel from "../components/manage-order/cancel-order/pending-cancel";
import PendingCancelModal from "../components/manage-order/cancel-order/pending-cancel/create/content/pendingCancelModal";
import UpdatePrimaryBuyerLead from "../components/leads/buyer/updatePrimaryBuyerLead";
import PickingTicket from "../components/manage-order/picking-ticket";
import NotFoundPage from "../components/error/permission/notFound";
import ReturnRestoke from '../components/manage-order/return-restoke';
import BinLocation from '../components/manage-product/product-setting/bin-location/binLocation';
import BinLocationType from '../components/manage-product/product-setting/bin-location-type/binLocationType';
import Platform from '../components/manage-product/product-setting/product-platform/platform';
import RestockReport from '../components/manage-order/restock-report';
import InventoryAdjustments from '../components/manage-product/stock-count';
import AddInventory from '../components/manage-product/stock-count/addInventory';
import AdjustmentInventory from '../components/manage-product/inventory-adjustments';
import AddAdjustmentInventory from '../components/manage-product/inventory-adjustments/AddAdjustmentInventory';
import Supplier from '../components/manage-product/supplier';
import SupplierForm from '../components/manage-product/supplier/supplierForm';
import UpdateSupplier from '../components/manage-product/supplier/updateSupplier';
import PaymentTerms from '../components/manage-product/payment-terms';
import AddPaymentTerms from '../components/manage-product/payment-terms/addPaymentTerms';
import UpdatePaymentTerms from '../components/manage-product/payment-terms/updatePaymentTerms';
import PurchaseOrder from '../components/manage-product/purchase-order';
import PurchaseOrderForm from '../components/manage-product/purchase-order/purchaseOrderForm';
import UpdatePurchaseOrder from '../components/manage-product/purchase-order/updatePurchaseOrder';
import ShowPurchaseOrder from '../components/manage-product/purchase-order/showPurchaseOrder';
import ReceiveInventory from '../components/manage-product/purchase-order/receiveInventory';
import UsOrder from "../components/manage-order/us-order";
import EuOrder from "../components/manage-order/eu-order";
import collabOrder from "../components/manage-order/collab-order";
import VerifyCollabOrder from "../components/manage-order/collab-order/verify";
import ViewCollabOrder from "../components/manage-order/collab-order/view";

import FinancesSummary from "../components/report/financesSummary";
import FinancesGrossSalesDetails from "../components/report/financesSummary/grossSalesDetailView";
import FinancesDiscountsDetails from "../components/report/financesSummary/discountsDetailView";
import FinancesReturnsDetails from "../components/report/financesSummary/returnsDetailView";
import FinancesProductCostDetails from "../components/report/financesSummary/productCostDetailView";
import FinancesShippingIncomeDetails from "../components/report/financesSummary/shippingIncomeDetailView";
import FinancesShippingCostSaleDetails from "../components/report/financesSummary/shippingCostSaleDetailView";
import FinancesShippingCostReturnDetails from "../components/report/financesSummary/shippingCostReturnDetailView";
import FinancesCommissionsDetails from "../components/report/financesSummary/commissionDetailView";
import FinancesAdditionalChargesDetails from "../components/report/financesSummary/additionalChargesDetailView";
import FinancesAgentCommissionDetails from "../components/report/financesSummary/agentCommissionDetailView";
import FinancesTaxesDetails from "../components/report/financesSummary/taxesDetailView";
import FinancesNetSalesDetails from "../components/report/financesSummary/netSalesDetailView";
import FinancesPaymentsDetails from "../components/report/financesSummary/paymentsDetailView";


export const routes = [
  // { path:`${process.env.PUBLIC_URL}/dashboard/default/:layout`, Component:Default},
  { path: `${process.env.PUBLIC_URL}/dashboard`, Component: Dashboard },
  { path: `${process.env.PUBLIC_URL}/user`, Component: User },
  { path: `${process.env.PUBLIC_URL}/user-profile`, Component: UserProfile },
  { path: `${process.env.PUBLIC_URL}/activity-log`, Component: ActivityLog },

  //Seller
  { path: `${process.env.PUBLIC_URL}/seller`, Component: Seller },
  { path: `${process.env.PUBLIC_URL}/seller/add`, Component: AddSeller },
  {
    path: `${process.env.PUBLIC_URL}/seller/:id/edit/`,
    Component: UpdateSeller,
  },
  { path: `${process.env.PUBLIC_URL}/details`, Component: UpdateSeller },
  { path: `${process.env.PUBLIC_URL}/seller/:id/view/`, Component: ViewSeller },
  { path: `${process.env.PUBLIC_URL}/seller/staff`, Component: SellerStaff },
  {
    path: `${process.env.PUBLIC_URL}/seller/staff/add`,
    Component: AddSellerStaff,
  },
  {
    path: `${process.env.PUBLIC_URL}/seller/staff/:id/edit`,
    Component: AddSellerStaff,
  },
  { path: `${process.env.PUBLIC_URL}/seller/vat`, Component: SellerVat },
  {
    path: `${process.env.PUBLIC_URL}/seller/shipping-zone`,
    Component: ShippingZone,
  },
  {
    path: `${process.env.PUBLIC_URL}/seller/shipping-zone/add`,
    Component: AddShippingZone,
  },
  {
    path: `${process.env.PUBLIC_URL}/seller/shipping-zone/:id/edit`,
    Component: AddShippingZone,
  },
  {
    path: `${process.env.PUBLIC_URL}/product/sync-products`,
    Component: ProductList,
  },
  {
    path: `${process.env.PUBLIC_URL}/seller/seller-product-list/:id`,
    Component: SellerProductList,
  },
  {
    path: `${process.env.PUBLIC_URL}/seller/seller-list`,
    Component: SellerList,
  },

  //leads
  {
    path: `${process.env.PUBLIC_URL}/leads/seller-lead`,
    Component: SellerLead,
  },
  {
    path: `${process.env.PUBLIC_URL}/leads/seller-lead/:id/edit`,
    Component: UpdateSellerLead,
  },

  { path: `${process.env.PUBLIC_URL}/leads/buyer-lead`, Component: BuyerLead },
  // { path:`${process.env.PUBLIC_URL}/leads/buyer-lead/:id/edit`, Component:UpdateBuyerLead},
  {
    path: `${process.env.PUBLIC_URL}/leads/buyer-lead/:id/edit`,
    Component: UpdatePrimaryBuyerLead,
  },
  // { path:`${process.env.PUBLIC_URL}/leads/buyer/:id/edit`, Component:UpdatePrimaryBuyerLead},
  {
    path: `${process.env.PUBLIC_URL}/leads/buyer-lead/create`,
    Component: BuyerCreate,
  },

  //Order
  { path: `${process.env.PUBLIC_URL}/order`, Component: Order },
  { path: `${process.env.PUBLIC_URL}/order/draft`, Component: DraftOrder },
  {
    path: `${process.env.PUBLIC_URL}/order/draft/create`,
    Component: DraftOrderForm,
  },
  {
    path: `${process.env.PUBLIC_URL}/order/draft/:id/edit`,
    Component: DraftOrderForm,
  },
  { path: `${process.env.PUBLIC_URL}/order/:id/view`, Component: ViewOrder },
  { path: `${process.env.PUBLIC_URL}/order/:id/refund`, Component: RefundOrder },
  {
    path: `${process.env.PUBLIC_URL}/order/:id/return-items/`,
    Component: ReturnOrder,
  },
  { path: `${process.env.PUBLIC_URL}/order/return/`, Component: Return },
  {
    path: `${process.env.PUBLIC_URL}/order/return/:id/view`,
    Component: ViewReturnOrder,
  },
  {
    path: `${process.env.PUBLIC_URL}/order/:id/tracking/add`,
    Component: AddTracking,
  },
  {
    path: `${process.env.PUBLIC_URL}/order/pending-return-requests`,
    Component: PendingReturn,
  },
  {
    path: `${process.env.PUBLIC_URL}/order/pending-return-requests/:id/edit`,
    Component: EditPendingReturn,
  },
  {
    path: `${process.env.PUBLIC_URL}/order/pending-return-requests/:id/view`,
    Component: ViewPendingReturn,
  },
  {
    path: `${process.env.PUBLIC_URL}/order/setting/shipping-setting`,
    Component: ShippingSetting,
  },
  {
    path: `${process.env.PUBLIC_URL}/order/setting/return-reason-setting`,
    Component: ReturnReasonSetting,
  },
  {
    path: `${process.env.PUBLIC_URL}/order/dispatch-order`,
    Component: DispatchOrder,
  },
  {
    path: `${process.env.PUBLIC_URL}/order/return-offer`,
    Component: VerifyOfferItems,
  },
  {
    path: `${process.env.PUBLIC_URL}/order/order-return-details`,
    Component: GroupReturn,
  },
  {
    path: `${process.env.PUBLIC_URL}/order/order-return-details/:id`,
    Component: GroupReturnCreate,
  },
  {
    path: `${process.env.PUBLIC_URL}/order/pending-orders`,
    Component: PendingOrder,
  },
  {
    path: `${process.env.PUBLIC_URL}/order/pending-cancel`,
    Component: PendingCancel,
  },
  {
    path: `${process.env.PUBLIC_URL}/order/get-selected-cancel-order-request/:id`,
    Component: PendingCancelModal,
  },
  {
    path: `${process.env.PUBLIC_URL}/order/picking-ticket-details`,
    Component: PickingTicket,
  },
  {
    path: `${process.env.PUBLIC_URL}/order/return-restock`,
    Component: ReturnRestoke,
  },
  {
    path: `${process.env.PUBLIC_URL}/order/restock-report`,
    Component: RestockReport,
  },
  { path: `${process.env.PUBLIC_URL}/order/checkout`, Component: Checkout },
  { path: `${process.env.PUBLIC_URL}/order/us-orders`, Component: UsOrder },
  { path: `${process.env.PUBLIC_URL}/order/eu-orders`, Component: EuOrder },

  { path: `${process.env.PUBLIC_URL}/order/collab-orders`, Component: collabOrder },
  { path: `${process.env.PUBLIC_URL}/order/collab-orders/:id/verify`, Component: VerifyCollabOrder },
  { path: `${process.env.PUBLIC_URL}/order/collab-orders/:id/view`, Component: ViewCollabOrder },

  { path:`${process.env.PUBLIC_URL}/order/alteration-requests`, Component: AlterationRequest},
  { path:`${process.env.PUBLIC_URL}/order/alteration-requests/:id/edit`, Component: EditAlterationRequest},
  { path:`${process.env.PUBLIC_URL}/order/alteration-requests/:id/view`, Component: ViewAlterationRequest},

  { path: `${process.env.PUBLIC_URL}/user/roles`, Component: Role },
  { path: `${process.env.PUBLIC_URL}/user/permission`, Component: Permission },
  { path: `${process.env.PUBLIC_URL}/user/agent`, Component: Agent },

        //Order
        { path:`${process.env.PUBLIC_URL}/order`, Component:Order},
        { path:`${process.env.PUBLIC_URL}/order/draft`, Component:DraftOrder},
        { path:`${process.env.PUBLIC_URL}/order/draft/create`, Component:DraftOrderForm},
        { path:`${process.env.PUBLIC_URL}/order/draft/:id/edit`, Component:DraftOrderForm},
        { path:`${process.env.PUBLIC_URL}/order/:id/view`, Component:ViewOrder},
        { path:`${process.env.PUBLIC_URL}/order/:id/return-items/`, Component:ReturnOrder},
        { path:`${process.env.PUBLIC_URL}/order/return/`, Component:Return},
        { path:`${process.env.PUBLIC_URL}/order/return/:id/view`, Component:ViewReturnOrder},
        { path:`${process.env.PUBLIC_URL}/order/:id/tracking/add`, Component:AddTracking},
        { path:`${process.env.PUBLIC_URL}/order/pending-return-requests`, Component:PendingReturn},
        { path:`${process.env.PUBLIC_URL}/order/pending-return-requests/:id`, Component:EditPendingReturn},
        { path:`${process.env.PUBLIC_URL}/order/setting/shipping-setting`, Component:ShippingSetting},
        { path:`${process.env.PUBLIC_URL}/order/setting/return-reason-setting`, Component:ReturnReasonSetting},
        { path:`${process.env.PUBLIC_URL}/order/dispatch-order`, Component:DispatchOrder},
        { path:`${process.env.PUBLIC_URL}/order/return-offer`, Component:VerifyOfferItems},
        { path:`${process.env.PUBLIC_URL}/order/order-return-details`, Component:GroupReturn },
        { path:`${process.env.PUBLIC_URL}/order/order-return-details/:id`, Component:GroupReturnCreate },
        { path:`${process.env.PUBLIC_URL}/order/pending-orders`, Component:PendingOrder },
        { path:`${process.env.PUBLIC_URL}/order/pending-cancel`, Component:PendingCancel },
        { path:`${process.env.PUBLIC_URL}/order/get-selected-cancel-order-request/:id`, Component:PendingCancelModal },
        { path:`${process.env.PUBLIC_URL}/order/picking-ticket-details`, Component:PickingTicket },
        { path:`${process.env.PUBLIC_URL}/order/return-restock`, Component: ReturnRestoke },
        { path:`${process.env.PUBLIC_URL}/order/restock-report`, Component: RestockReport },

        { path:`${process.env.PUBLIC_URL}/order/checkout`, Component:Checkout},
        { path:`${process.env.PUBLIC_URL}/order/us-orders`, Component:UsOrder },

  //product settings
  {
    path: `${process.env.PUBLIC_URL}/product/setting/product-type`,
    Component: ProductType,
  },
  {
    path: `${process.env.PUBLIC_URL}/product/setting/product-size`,
    Component: ProductSize,
  },
  {
    path: `${process.env.PUBLIC_URL}/product/setting/product-season`,
    Component: ProductSeason,
  },
  {
    path: `${process.env.PUBLIC_URL}/product/setting/product-color`,
    Component: ProductColor,
  },
  {
    path: `${process.env.PUBLIC_URL}/product/setting/product-tag`,
    Component: ProductTag,
  },
  {
    path: `${process.env.PUBLIC_URL}/product/setting/product-category`,
    Component: productCategory,
  },
  {
    path: `${process.env.PUBLIC_URL}/product/setting/product-style`,
    Component: ProductStyle,
  },
  {
    path: `${process.env.PUBLIC_URL}/product/setting/material`,
    Component: CompositionGroup,
  },
  {
    path: `${process.env.PUBLIC_URL}/product/setting/product-composition`,
    Component: ProductComposition,
  },
  {
    path: `${process.env.PUBLIC_URL}/product/setting/size-format`,
    Component: sizeMatrixConfiguration,
  },
  {
    path: `${process.env.PUBLIC_URL}/product/setting/commodity`,
    Component: Community,
  },
  {
    path: `${process.env.PUBLIC_URL}/product/setting/fit-type`,
    Component: ProductFitType,
  },
  {
    path: `${process.env.PUBLIC_URL}/product/setting/fit-size-condition`,
    Component: ProductFitSizeCondition,
  },
  {
    path: `${process.env.PUBLIC_URL}/product/setting/type-tag`,
    Component: ProductTypeTag,
  },
  {
    path: `${process.env.PUBLIC_URL}/product/setting/bin-location`,
    Component: BinLocation,
  },
  {
    path: `${process.env.PUBLIC_URL}/product/setting/bin-location-type`,
    Component: BinLocationType,
  },

  //commission
  {
    path: `${process.env.PUBLIC_URL}/commission/seller-commission`,
    Component: SellerCommission,
  },
  {
    path: `${process.env.PUBLIC_URL}/commission/detail-commission`,
    Component: DetailCommission,
  },

  { path:`${process.env.PUBLIC_URL}/product/:id/edit`, Component:EditProduct},
  { path:`${process.env.PUBLIC_URL}/product`, Component:Product},
  { path:`${process.env.PUBLIC_URL}/product/collection`, Component:Collection},
  { path:`${process.env.PUBLIC_URL}/product/inventory`, Component:Inventory},
  { path:`${process.env.PUBLIC_URL}/product/sale-offers`, Component:SaleOffer},
  { path:`${process.env.PUBLIC_URL}/product/sale-offers/:id/view`, Component:ViewSaleOffer},
  { path:`${process.env.PUBLIC_URL}/product/back-in-stock`, Component:BackInStock},
  { path:`${process.env.PUBLIC_URL}/product/stock-count`, Component:InventoryAdjustments},
  { path:`${process.env.PUBLIC_URL}/product/add-stock-count`, Component:AddInventory},
  { path:`${process.env.PUBLIC_URL}/product/adjustment-Inventory`, Component:AdjustmentInventory},
  { path:`${process.env.PUBLIC_URL}/product/add-adjustment-Inventory`, Component:AddAdjustmentInventory},
  { path:`${process.env.PUBLIC_URL}/product/add-adjustment-Inventory/:sku`, Component:AddAdjustmentInventory},
  { path:`${process.env.PUBLIC_URL}/product/supplier`, Component:Supplier},
  { path:`${process.env.PUBLIC_URL}/product/add-supplier`, Component:SupplierForm},
  { path:`${process.env.PUBLIC_URL}/product/supplier/:id/edit/`, Component:UpdateSupplier},
  
  { path:`${process.env.PUBLIC_URL}/product/payment-terms`, Component:PaymentTerms},
  { path:`${process.env.PUBLIC_URL}/product/add-payment-term`, Component:AddPaymentTerms},
  { path:`${process.env.PUBLIC_URL}/product/payment-terms/:id/edit/`, Component:UpdatePaymentTerms},

  { path:`${process.env.PUBLIC_URL}/product/parchase-order`, Component:PurchaseOrder},
  { path:`${process.env.PUBLIC_URL}/product/add-parchase-order`, Component:PurchaseOrderForm},
  { path:`${process.env.PUBLIC_URL}/product/parchase-order/:id/edit/`, Component:UpdatePurchaseOrder},
  { path:`${process.env.PUBLIC_URL}/product/parchase-order/:id/view/`, Component:ShowPurchaseOrder},
  { path:`${process.env.PUBLIC_URL}/product/:id/receive-inventory/`, Component:ReceiveInventory},

  

  //product settings
  { path:`${process.env.PUBLIC_URL}/product/setting/product-type`, Component:ProductType},
  { path:`${process.env.PUBLIC_URL}/product/setting/product-size`, Component:ProductSize},
  { path:`${process.env.PUBLIC_URL}/product/setting/product-season`, Component:ProductSeason},
  { path:`${process.env.PUBLIC_URL}/product/setting/product-color`, Component:ProductColor},
  { path:`${process.env.PUBLIC_URL}/product/setting/product-tag`, Component:ProductTag},
  { path:`${process.env.PUBLIC_URL}/product/setting/product-category`, Component:productCategory},
  { path:`${process.env.PUBLIC_URL}/product/setting/product-style`, Component:ProductStyle},
  { path:`${process.env.PUBLIC_URL}/product/setting/material`, Component:CompositionGroup},
  { path:`${process.env.PUBLIC_URL}/product/setting/product-composition`, Component:ProductComposition},
  { path:`${process.env.PUBLIC_URL}/product/setting/size-format`, Component:sizeMatrixConfiguration},
  { path:`${process.env.PUBLIC_URL}/product/setting/commodity`, Component:Community},
  { path:`${process.env.PUBLIC_URL}/product/setting/fit-type`, Component:ProductFitType},
  { path:`${process.env.PUBLIC_URL}/product/setting/fit-size-condition`, Component:ProductFitSizeCondition},
  { path:`${process.env.PUBLIC_URL}/product/setting/type-tag`, Component:ProductTypeTag},
  { path:`${process.env.PUBLIC_URL}/product/setting/bin-location`, Component:BinLocation},
  { path:`${process.env.PUBLIC_URL}/product/setting/bin-location-type`, Component:BinLocationType},
  { path:`${process.env.PUBLIC_URL}/product/setting/product-platform`, Component:Platform},
  { path:`${process.env.PUBLIC_URL}/product/setting/bin-location`, Component:BinLocation},
  {
    path: `${process.env.PUBLIC_URL}/report/sales-report`,
    Component: SalesReport,
  },
  {
    path: `${process.env.PUBLIC_URL}/report/back-in-stock-report`,
    Component: BackInStockReport,
  },
  {
    path: `${process.env.PUBLIC_URL}/report/exchange-order-report`,
    Component: ExchangeOrder,
  },
  {
    path: `${process.env.PUBLIC_URL}/report/verify-offer-report`,
    Component: VerifyOfferItems,
  },
  {
    path: `${process.env.PUBLIC_URL}/report/inventories-report`,
    Component: InvontoryReport,
  },
  {
    path: `${process.env.PUBLIC_URL}/report/finances-summary`,
    Component: FinancesSummary,
  },
  {
    path: `${process.env.PUBLIC_URL}/report/finances-summary/details-gross-sales`,
    Component: FinancesGrossSalesDetails,
  },
  {
    path: `${process.env.PUBLIC_URL}/report/finances-summary/details-discounts`,
    Component: FinancesDiscountsDetails,
  },
  {
    path: `${process.env.PUBLIC_URL}/report/finances-summary/details-returns`,
    Component: FinancesReturnsDetails,
  },
  {
    path: `${process.env.PUBLIC_URL}/report/finances-summary/details-product-cost`,
    Component: FinancesProductCostDetails,
  },
  {
    path: `${process.env.PUBLIC_URL}/report/finances-summary/details-shipping-income`,
    Component: FinancesShippingIncomeDetails,
  },
  {
    path: `${process.env.PUBLIC_URL}/report/finances-summary/details-shipping-cost-sales`,
    Component: FinancesShippingCostSaleDetails,
  },
  {
    path: `${process.env.PUBLIC_URL}/report/finances-summary/details-shipping-cost-return`,
    Component: FinancesShippingCostReturnDetails,
  },
  {
    path: `${process.env.PUBLIC_URL}/report/finances-summary/details-commissions`,
    Component: FinancesCommissionsDetails,
  },
  {
    path: `${process.env.PUBLIC_URL}/report/finances-summary/details-additional-charges`,
    Component: FinancesAdditionalChargesDetails,
  },
  {
    path: `${process.env.PUBLIC_URL}/report/finances-summary/details-agent-commissions`,
    Component: FinancesAgentCommissionDetails,
  },
  {
    path: `${process.env.PUBLIC_URL}/report/finances-summary/details-taxes`,
    Component: FinancesTaxesDetails,
  },
  {
    path: `${process.env.PUBLIC_URL}/report/finances-summary/details-net-sales`,
    Component: FinancesNetSalesDetails,
  },
  {
    path: `${process.env.PUBLIC_URL}/report/finances-summary/details-payments`,
    Component: FinancesPaymentsDetails,
  },

  //Look Book
  {
    path: `${process.env.PUBLIC_URL}/product/look-book/product-look-book/:id`,
    Component: LookBookPdf,
  },
  {
    path: `${process.env.PUBLIC_URL}/product/look-book/look-book-view`,
    Component: LookBook,
  },

  //Partners
  { path: `${process.env.PUBLIC_URL}/partner`, Component: Partner },
  { path: `${process.env.PUBLIC_URL}/partner/add`, Component: AddPartner },
  {
    path: `${process.env.PUBLIC_URL}/partner/:id/edit/`,
    Component: UpdatePartner,
  },
  {
    path: `${process.env.PUBLIC_URL}/partner/:id/view/`,
    Component: ViewPartner,
  },

  {
    path: `${process.env.PUBLIC_URL}/settings/settings`,
    Component: GeneralSetting,
  },
];
