import React, { Fragment, useEffect, useRef, useState } from "react";
import * as ReactDOM from 'react-dom';
import { Badge, Card, CardBody, Col, FormGroup, Input, Label, Row, Container, Progress, Button } from "reactstrap";
import Select from "react-select";
import JqxTable from "../../../share-components/table/JqxTable";
import { toast } from "react-toastify";
import * as ReactDOMServer from "react-dom/server";
import Breadcrumb from "../../../layout/breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { receivedPurchaseOrderDetails, setReceivedOrderData, addreceiveInventoryDetails, acceptAllData, rejectAllData } from "../store/purchaseOrderSlice";
import ButtonLoader from "../../../share-components/ui/buttonLoader";
import SweetAlert from "sweetalert2";
import PreLoader from "../../../share-components/ui/preLoader";


const dataMenu = [
    {
        type: 0,
        text: 'Products',
        link: '/product'
    },
    {
        type: 1,
        text: 'Purchase Orders',
        link: '/product/parchase-order'
    },
    {
        type: 0,
        text: 'Received Purchase Order',
        link: ''
    }
];

function ReceiveInventory() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const purchaseOrderState = useSelector(({ product }) => product.purchaseOrder);
    let history = useHistory();
    const receiveOrderData = purchaseOrderState.receiveOrder;
    const [acceptRejectQty, setAcceptRejectQty] = useState({ acceptAll: 0, rejectAll: 0 });
    const [rejectQty, setRejectQty] = useState(0);
    const [rejectAllQty, setRejectAllQty] = useState(0);
    const [acceptAllQty, setAcceptAllQty] = useState(0);
    const [acceptPercentage, setAcceptPercentage] = useState(0);
    const [rejectPercentage, setRejectPercentage] = useState(0);
    const totalStoreQty = receiveOrderData ? (receiveOrderData.total_accepted_quantity + receiveOrderData.total_rejected_quantity) : 0;
    const receivedOrderSatus = receiveOrderData ? receiveOrderData.status : 0;
    const [totalQty, setTotalQty] = useState(0);
    const [editedRows, setEditedRows] = useState({});
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [loading, isSetLoading] = useState(false);

    useEffect(() => {
        isSetLoading(true);

        if (id) {
            dispatch(receivedPurchaseOrderDetails(id)).then(res => {
                if (!res.error) {
                    isSetLoading(false);
                    dispatch(setReceivedOrderData(res.payload));
                }
            });
        }
    }, [dispatch]);

    useEffect(() => {
        setTotalQty(totalStoreQty);
        if(receiveOrderData){
            setAcceptPercentage(receiveOrderData.total_accepted_quantity / purchaseOrderState.receiveOrder.total_ordered_quantity * 100);
            setRejectPercentage(receiveOrderData.total_rejected_quantity / purchaseOrderState.receiveOrder.total_ordered_quantity * 100);
        }
       
        setButtonDisabled(receivedOrderSatus != 2 ? false: true);
    }, [receiveOrderData]);

    useEffect(() => {
        const orderedQty = purchaseOrderState.receiveOrder ? purchaseOrderState.receiveOrder.total_ordered_quantity : 0;
        const total_accepted_quantity = purchaseOrderState.receiveOrder ? purchaseOrderState.receiveOrder.total_accepted_quantity : 0;
        const total_rejected_quantity = purchaseOrderState.receiveOrder ? purchaseOrderState.receiveOrder.total_rejected_quantity : 0;


        let totalAcceptQty = 0;
        let totalRejectQty = 0;
        for (const productId in editedRows) {
            const rowData = editedRows[productId];
            totalAcceptQty = totalAcceptQty + rowData.accepted_quantity;
            totalRejectQty = totalRejectQty + rowData.rejected_quantity;
        }
        setAcceptAllQty(totalAcceptQty + total_accepted_quantity);
        setRejectAllQty(totalRejectQty + total_rejected_quantity);
        setAcceptPercentage(Math.floor((totalAcceptQty + total_accepted_quantity) / orderedQty * 100));
        setRejectPercentage(Math.floor((totalRejectQty + total_rejected_quantity) / orderedQty * 100));

        if (purchaseOrderState.receiveOrder && purchaseOrderState.receiveOrder.status != 0) {
            setTotalQty(total_accepted_quantity + total_rejected_quantity + totalAcceptQty + totalRejectQty);
        } else {
            setTotalQty(totalAcceptQty + totalRejectQty);
        }
    }, [editedRows]);

    let gridRef = useRef(null);

    const dataFields = [
        { name: 'id', type: 'id' },
        { name: 'purchase_order_id', type: 'id' },
        { name: 'product_varriant_id', type: 'id' },
        { name: 'supplier_sku', type: 'string' },
        { name: 'product', type: 'string' },
        { name: 'accepted_quantity', type: 'number' },
        { name: 'accept_btn', type: '' },
        { name: 'rejected_quantity', type: 'number' },
        { name: 'received', type: 'number' },
        { name: 'reject_btn', type: '' },
        { name: 'ordered_qty', type: 'number' },
        { name: 'total_ordered_quantity', type: 'number' },

    ];

    const getJqxRef = jqx => {
        if (jqx) {
            gridRef = jqx;

            setTimeout(
                () => {
                    if (gridRef) {
                        const myEle = document.getElementById(gridRef._id);
                        if (myEle) {
                            gridRef.updatebounddata();
                        }
                    }

                    // myGrid.updatebounddata();
                },
                1000
            );
        }
    };


    let columns = [
        { datafield: 'id', hidden: true },
        { datafield: 'purchase_order_id', hidden: true },
        { datafield: 'product_varriant_id', hidden: true },
        { datafield: 'ordered_qty', hidden: true },
        {
            text: 'Product',
            datafield: 'product',
            editable: true,
            align: 'center',
            cellsalign: 'left',
            cellsrenderer(row, columnfield, value, defaulthtml, columnproperties) {
                const productDetails = purchaseOrderState.dataList[row];
                const html = ReactDOMServer.renderToString(
                    <div style={{ textAlign: 'center', padding: 5 }}>
                        {productDetails && <div className="row">
                            <div className="col-md-3">
                                <img className="img-fluid" src={productDetails.image + '&width=50'} width="40" alt="" />
                            </div>
                            <div className="col-md-9">
                                <div className="row">
                                    <p className={'font-primary'}>{productDetails.product_title}</p>
                                </div>
                                <div className="row">
                                    <p>{productDetails.color_size}</p>
                                </div>
                                <div className="row">
                                    <p>{productDetails.sku}</p>
                                </div>

                            </div>

                        </div>}
                    </div>
                );
                return html;
            },

            cellbeginedit(row, datafield, columntype) { },
        },
        {
            text: 'Supplier SKU',
            datafield: 'supplier_sku',
            editable: false,
            columntype: 'text',
            width: '14%',
            cellsalign: 'center',
            align: 'center',
        },
        {
            text: 'Accept',
            datafield: 'accepted_quantity',
            editable: receivedOrderSatus === 2 ? false : true,
            columntype: 'text',
            width: '20%',
            cellsalign: 'center',
            align: 'left',
            // cellsformat: 'c2',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties) => {
                if (value === undefined || value === null || value === '') {
                    return `<div style="color: grey; display: flex; justify-content: center; align-items: center; height: 100%;">0.00</div>`;
                }
                return `<div style="color: black; display: flex; justify-content: center; align-items: center; height: 100%;">${parseFloat(value)}</div>`;
            },
            validation: (cell, value) => {
                if (value === '' || value === null) {
                    return true;
                }

                const acceptValue = parseFloat(value);
                if (isNaN(acceptValue) || acceptValue < 0) {
                    toast.error("Cannot be a negative number.", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    return { result: false, message: "Cannot be a negative number." };
                }
                return true;
            },


        },
        {
            text: '',
            datafield: 'accept_btn',
            editable: false,
            width: '8%',
            cellsalign: 'center',
            align: 'center',
            columntype: 'button',
            disabled: receivedOrderSatus === 2 ? true : false,
            cellclassname: `custom-button ${receivedOrderSatus === 2 ?'btn-disabled':''}`,
            cellsrenderer: function (row, column, value, rowData) {
                return "All";
            },
            buttonclick: (row: number): void => {
                // get the data and append in to the inputs
                // this.editrow = row;
                console.log('Button clicked for row ID:', row);
                const productDetails = purchaseOrderState.dataList[row];
                gridRef.setcellvalue(row, 'accept_btn', productDetails.ordered_qty);
                gridRef.setcellvalue(row, 'accepted_quantity', productDetails.ordered_qty);
                gridRef.setcellvalue(row, 'reject_btn', 0);
                gridRef.setcellvalue(row, 'rejected_quantity', 0);
                calculateAllQty();

            },
        },
        {
            text: 'Reject',
            datafield: 'rejected_quantity',
            editable: receivedOrderSatus === 2 ? false : true,
            columntype: 'text',
            // width: '14%',
            cellsalign: 'center',
            align: 'left',
            // cellsformat: 'c2',
            validation: (cell, value) => {
                if (value === '' || value === null) {
                    return true;
                }

                const acceptValue = parseFloat(value);
                if (isNaN(acceptValue) || acceptValue < 0) {
                    toast.error("Cannot be a negative number.", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    return { result: false, message: "Cannot be a negative number." };
                }
                return true;
            },


        },
        {
            text: '',
            datafield: 'reject_btn',
            editable: false,
            columntype: 'button',
            width: '8%',
            cellsalign: 'center',
            cellclassname: `custom-button ${receivedOrderSatus === 2 ?'btn-disabled':''}`,
            disabled: receivedOrderSatus === 2 ? true : false,
            align: 'center',
            cellsrenderer: (): string => {
                return 'All';
            },
            buttonclick: (row: number): void => {
                const productDetails = purchaseOrderState.dataList[row];
                gridRef.setcellvalue(row, 'accept_btn', 0);
                gridRef.setcellvalue(row, 'accepted_quantity', 0);
                gridRef.setcellvalue(row, 'reject_btn', productDetails.ordered_qty);
                gridRef.setcellvalue(row, 'rejected_quantity', productDetails.ordered_qty);
                calculateAllQty();

            },
        },
        {
            text: 'Received',
            datafield: 'received',
            editable: false,
            columntype: 'custom',
            width: '14%',
            cellsalign: 'center',
            align: 'center',
            cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowData) {
                const productDetails = purchaseOrderState.dataList[row];
                const accept_all_value = rowData.accept_btn;
                const accept_value = rowData.accepted_quantity;
                const reject_all_value = rowData.reject_btn;
                const reject_value = rowData.rejected_quantity;
                const orderedQty = productDetails ? productDetails.ordered_qty : 0;

                let percentage = Math.floor((((accept_all_value > 0 ? accept_all_value : accept_value) / orderedQty) * 100));
                let percentageReject = Math.floor((((reject_all_value > 0 ? reject_all_value : reject_value) / orderedQty) * 100));

                // setAcceptRejectAllQty(accept_all_value > 0? accept_all_value : reject_all_value > 0? reject_all_value :(accept_value + reject_value));

                const html = ReactDOMServer.renderToString(
                    <>
                        <div>
                            <Progress style={{ marginTop: '30px', marginLeft: '5px', marginRight: '5px' }} multi>
                                <Progress striped bar color="success" value={percentage} />
                                <Progress striped bar color="danger" value={percentageReject} />
                            </Progress>

                        </div>
                        <div style={{ marginTop: '5px' }} className="row justify-content-end">
                            <div className="col-5">
                                {accept_all_value > 0 ? accept_all_value : reject_all_value > 0 ? reject_all_value : (accept_value + reject_value)} 0f {productDetails ? productDetails.ordered_qty : ''}
                            </div>
                        </div>
                    </>
                );
                return html;
            },
        },

    ];


    const calculateAllQty = () => {
        let orderData = [];
        const gridInformation = gridRef.getdatainformation();
        for (let i = 0; i < gridInformation.rowscount; i++) {
            const rowData = gridRef.getrowdata(i);
            orderData.push(rowData);
        }
        setEditedRows(orderData);

    }


    const cellEdit = event => {
        const rowArgs = event.args;
        const { rowindex, value, datafield } = rowArgs;

        const rowId = rowArgs.row.id;
        // if (!editedRows[rowindex]) {
        //     editedRows[rowindex] = { id: rowindex };
        // }
        if (datafield === 'accepted_quantity') {
            // editedRows[rowindex].accept = parseFloat(value) || 0;
        }
        if (datafield === 'rejected_quantity') {
            // editedRows[rowindex].reject = parseFloat(value) || 0;
        }

        gridRef.setcellvalue(rowArgs.rowindex, rowArgs.datafield, rowArgs.value);
        setTimeout(
            () => {
                calculateAllQty();

            },
            500
        );
    };

    window.handleButtonClick = (row) => {
        // alert(`Button clicked for row ID ${row}`);
        const productDetails = purchaseOrderState.dataList[row];
        gridRef.setcellvalue(row, 'accept_btn', productDetails.ordered_qty);
        gridRef.setcellvalue(row, 'accepted_quantity', productDetails.ordered_qty);
        gridRef.setcellvalue(row, 'reject_btn', 0);
        gridRef.setcellvalue(row, 'rejected_quantity', 0);
        // calculateAllQty();
    };

    window.handleRejectClick = (row) => {

        const productDetails = purchaseOrderState.dataList[row];
        gridRef.setcellvalue(row, 'accept_btn', 0);
        gridRef.setcellvalue(row, 'accepted_quantity', 0);
        gridRef.setcellvalue(row, 'reject_btn', productDetails.ordered_qty);
        gridRef.setcellvalue(row, 'rejected_quantity', productDetails.ordered_qty);
        // calculateAllQty();

    };


    const submitData = () => {

        const editedDataArray = [];
        let totalAcceptQty = 0;
        let totalRejectQty = 0;

        const gridInformation = gridRef.getdatainformation();
        for (let i = 0; i < gridInformation.rowscount; i++) {
            const rowData = gridRef.getrowdata(i);
            totalAcceptQty = totalAcceptQty + rowData.accepted_quantity;
            totalRejectQty = totalRejectQty + rowData.rejected_quantity;
            editedDataArray.push(rowData);
        }

        const formData = {
            'id': receiveOrderData.id,
            'total_accepted_quantity': totalAcceptQty,
            'total_rejected_quantity': totalRejectQty,
            'ordered_products': editedDataArray
        };
        dispatch(addreceiveInventoryDetails({ ...formData })).then((res) => {
            if (!res.error) {
                history.push('/product/parchase-order');
            }
        });
    }

    const backtoManin = () =>{
        history.push('/product/parchase-order');
    }

    const acceptAll = (id) => {
        SweetAlert.fire({
            title: `<p>This action will mark all remaining units as accepted, save the purchase order, and make those units available at Goddiva Warehouse.</p>`,
            icon: 'warning',
            showCancelButton: true,
            animation: false,
            confirmButtonText: 'Proceed',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        })
            .then((result) => {
                const productDetails = purchaseOrderState.dataList;
                const selectedProductArray = [];
                
                if (result.isConfirmed) {
                    const formData = {
                        id,
                        item_id: purchaseOrderState.receiveOrder ? purchaseOrderState.receiveOrder.id : '',
                        total_ordered_quantity: purchaseOrderState.receiveOrder ? purchaseOrderState.receiveOrder.total_ordered_quantity : ''
                    };

                    // dispatch(acceptAllData(formData)).then(res => {
                    //     if (!res.error) {
                    //         history.push('/product/parchase-order');
                    //     }
                    // });

                    productDetails.map(function (item, key) {
                        selectedProductArray.push(item.id);
                        gridRef.setcellvalue(key, 'accept_btn', item.ordered_qty);
                        gridRef.setcellvalue(key, 'accepted_quantity', item.ordered_qty);
                        gridRef.setcellvalue(key, 'reject_btn', 0);
                        gridRef.setcellvalue(key, 'rejected_quantity', 0);
                    });
                    calculateAllQty();
                }
            });
    }

    const rejectAll = (id) => {
        SweetAlert.fire({
            title: `<p>This action will mark all remaining units as rejected and save the purchase order.</p>`,
            icon: 'warning',
            showCancelButton: true,
            animation: false,
            confirmButtonText: 'Proceed',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        })
            .then((result) => {
                const productDetails = purchaseOrderState.dataList;
                const selectedProductArray = [];
                
                if (result.isConfirmed) {
                    const formData = {
                        id,
                        item_id: purchaseOrderState.receiveOrder ? purchaseOrderState.receiveOrder.id : '',
                        total_ordered_quantity: purchaseOrderState.receiveOrder ? purchaseOrderState.receiveOrder.total_ordered_quantity : ''
                    };

                    // dispatch(rejectAllData(formData)).then(res => {
                    //     if (!res.error) {
                    //         history.push('/product/parchase-order');
                    //     }
                    // });

                    productDetails.map(function (item, key) {
                        selectedProductArray.push(item.id);
                        gridRef.setcellvalue(key, 'accept_btn', 0);
                        gridRef.setcellvalue(key, 'accepted_quantity', 0);
                        gridRef.setcellvalue(key, 'reject_btn', item.ordered_qty);
                        gridRef.setcellvalue(key, 'rejected_quantity', item.ordered_qty);
                    });
                    calculateAllQty();
                }
            });
    }


    return (
        <Fragment>
            {loading ? <>
                    <PreLoader></PreLoader>
                </>
                : <>
            <Container fluid={true} className="product-wrapper">
                <Breadcrumb data={dataMenu} title="Received Purchase Order" />
                <div className="product-grid">
                    <div className="feature-products">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md="8">
                                        <h6>Products</h6>
                                    </Col>
                                    <Col md="4">

                                        {purchaseOrderState && purchaseOrderState.receiveOrder && purchaseOrderState.receiveOrder.total_accepted_quantity === 0 && purchaseOrderState.receiveOrder.total_rejected_quantity === 0 && (
                                            <>
                                                <button style={{ marginRight: '10px' }} type="button" onClick={() => { acceptAll(id) }} className="btn btn-primary">Accept All Unreceived</button>
                                                <button type="button" onClick={() => { rejectAll(id) }} className="btn btn-primary">Reject All Unreceived</button>
                                            </>
                                        )}


                                    </Col>
                                </Row>

                                <Progress style={{ marginTop: '30px' }} multi>
                                    <Progress bar striped color="success" value={acceptPercentage} />
                                    <Progress bar striped color="danger" value={rejectPercentage} />
                                </Progress>

                                <div style={{ marginBottom: '30px' }} className="row justify-content-end">
                                    <div className="col-2">
                                        Total Received: {totalQty} 0f {purchaseOrderState.receiveOrder ? purchaseOrderState.receiveOrder.total_ordered_quantity : ''}
                                    </div>
                                </div>

                                {purchaseOrderState && purchaseOrderState.dataList.length > 0 &&
                                    <div>
                                        <JqxTable
                                            datafields={dataFields}
                                            rowsheight={75}
                                            columns={columns}
                                            data={editedRows.length > 0 ? editedRows : purchaseOrderState.dataList}
                                            myGrid={gridRef}
                                            isDelete={false}
                                            disableCreateRow
                                            getJqxRef={getJqxRef}
                                            onCellendedit={cellEdit}
                                        />

                                        <div className="mt-2 d-flex justify-content-end">
                                            <div className="p-2">
                                                {!buttonDisabled ?<ButtonLoader disabled={buttonDisabled} onClick={submitData} btntext="Save" className="btn btn-primary btn-block" />:<button className="btn btn-primary d-block flip-back" onClick={backtoManin} >Back</button>}
                                            </div>
                                        </div>
                                    </div>


                                }

                            </CardBody>
                        </Card>

                    </div>
                </div>
            </Container>
            </>}
        </Fragment>
    )
}

export default ReceiveInventory